<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>部门管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="添加时间">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          >
            <i slot="append" class="el-icon-search"></i>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getDepartmentList"
          >查询</el-button
        >
        <el-button size="medium" @click="clearForm">清空</el-button>
      </div>
    </div>
    <div class="boxLayout mg-t-20">
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="$router.push('/addDepartment')"
          >添加部门</el-button
        >
      </div>
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        :row-key="getRowKeys"
        border
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="name" label="部门名称"> </el-table-column>
        <el-table-column prop="person_number" label="人数" sortable>
        </el-table-column>
        <el-table-column prop="admin.name" label="创建人"> </el-table-column>
        <!-- <template slot-scope="scope">
            {{ scope.row.admin.name }}
          </template> -->
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.fid == 0"
              @click="toEdit(scope.row, 'parent')"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button
              v-else
              @click="toEdit(scope.row, 'child')"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button @click="deleted(scope.row)" type="text" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "departmentManagement",
  data() {
    return {
      customerGroupForm: {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
      },
      tableData: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getDepartmentList();
  },
  methods: {
    clearForm() {
      this.customerGroupForm = {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
      };
      this.getDepartmentList();
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    // 部门列表
    getDepartmentList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Department?search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 编辑跳转
    toEdit(row, str) {
      this.$router.push({
        name: "addDepartment",
        query: { ...row, type: str },
      });
    },
    getRowKeys(row) {
      return row.uuid;
    },

    deleted(row) {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.axios({
          method: "delete", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          url: ` https://api.boyalife.net/v1/cms/Department/${row.uuid}`,
        })
          .then((res) => {
            this.$message.success("操作成功！");
            this.getDepartmentList();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
  },
};
</script>
<style></style>
