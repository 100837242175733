<template>
  <div class="realNameAuthentication">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>客户</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
      <el-breadcrumb-item>实名认证</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="客户等级">
          <el-select
            v-model="customerGroupForm.search_level"
            placeholder="请选择"
          >
            <el-option
              v-for="(jtem, i) in levelList"
              :key="i"
              :label="jtem"
              :value="i"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户标签">
          <el-cascader
            :options="labelList"
            v-model="value3"
            :props="props"
            @change="getCheckedNodes"
            :emitPath="false"
            collapse-tags
            filterable
            :show-all-levels="false"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="提交日期">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
            class="input-with-select"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium" @click="exportRealName">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="客户头像/昵称" :align="'center'" width="160">
          <template slot-scope="scope">
            <div class="nickAndAvatar">
              <el-image
                v-if="scope.row.user != null"
                :src="scope.row.user.avatar"
              ></el-image>
              <p>
                <span>{{ scope.row.name }}</span> <br />
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机号码" :align="'center'">
        </el-table-column>
        <el-table-column prop="name" label="姓名" :align="'center'">
        </el-table-column>
        <el-table-column prop="idcard" label="身份证号" :align="'center'">
        </el-table-column>
        <el-table-column prop="money" label="审核状态" :align="'center'">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1" style="color: #2ed477"
              >审核通过</span
            >
            <span v-else>审核不通过</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="提交时间"
          :align="'center'"
          width="160"
        >
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "realNameAuthentication",
  components: {
    pagination,
  },
  data() {
    return {
      photoVisible: false,
      bigImgUrl: "",
      labelList: [],
      levelList: [],
      customerGroupForm: {
        search_level: "",
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
        search_status: "",
        search_label: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      labelOptions: [
        {
          label: "启用",
          value: "ok",
        },
        {
          label: "禁用",
          value: "no",
        },
      ],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      props: {
        label: "name",
        value: "uuid",
        children: "label",
        checkStrictly: true,
      },
      value3: "",
    };
  },
  created() {
    this.getList();
    this.getLabel();
    this.getLevel();
  },
  methods: {
    getCheckedNodes(v) {
      if (v.length > 1) {
        this.customerGroupForm.search_label = v[1];
      } else {
        this.customerGroupForm.search_label = v[0];
      }
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.value3 = "";
      this.getList();
    },
    // 导出
    exportRealName() {
      this.axios({
        method: "get", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/RealNameExport?search_status=${this.customerGroupForm.search_status}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_level=${this.customerGroupForm.search_level}&search_label=${this.customerGroupForm.search_label}`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let currentTime = new Date();
          let year = currentTime.getFullYear();
          let month = currentTime.getMonth() + 1;
          let day = currentTime.getDate();
          let formattedDate = `${year}/${month
            .toString()
            .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;
          link.download = "实名认证客户" + "_" + formattedDate + ".xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getLabel() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/UserLabelGroupTree`,
      })
        .then((res) => {
          this.labelList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },

    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    handleSelectionChange() {},
    getLevel() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/UserLevelSelect`,
      })
        .then((res) => {
          this.levelList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 列表
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/RealName?search_status=${this.customerGroupForm.search_status}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_level=${this.customerGroupForm.search_level}&search_label=${this.customerGroupForm.search_label}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    showBigImage(url) {
      //点击图片函数，点击后，把photoVisible设置成true
      console.log(url);
      if (url != "") {
        this.photoVisible = true;
        this.bigImgUrl = url;
      }
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.realNameAuthentication {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
.realNameAuthentication .el-table .avatar .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
.realNameAuthentication .el-table .avatar {
  width: 60px;
  height: 60px;
  position: relative;
  margin: 0 auto;
}
.realNameAuthentication .el-table .avatar i {
  position: absolute;
  right: 5px;
  bottom: 5px;
  word-wrap: nowrap;
}
</style>
