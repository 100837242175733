<template>
  <div class="addAttribute">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>项目</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/projectAttributeDatabase'"
        >属性库</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ pageType }}属性</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="info boxLayout mg-t-20">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="basicInfoForm"
        :model="basicInfoForm"
        label-width="140px"
        :rules="rules"
      >
        <el-form-item label="属性项名称" prop="name">
          <el-input v-model="basicInfoForm.name"></el-input>
        </el-form-item>
        <el-form-item label="属性状态" placeholder="请输入">
          <el-switch
            v-model="basicInfoForm.status"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </div>
    <div class="boxLayout mg-t-20">
      <h3 class="title-t">属性值列表</h3>
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="addAttri">添加属性值</el-button>
      </div>
      <el-table :data="tableData" stripe border style="width: 100%">
        <el-table-column prop="name" label="属性值" :align="'center'">
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.$index !== 0"
              @click="toTop(scope.$index, scope.row)"
              >置顶</el-button
            >
            <el-button
              type="text"
              v-if="scope.$index !== 0"
              @click="up(scope.$index, scope.row)"
              >上移</el-button
            >
            <el-button
              type="text"
              v-if="scope.$index !== tableData.length - 1"
              @click="down(scope.$index, scope.row)"
              >下移</el-button
            >
            <el-button type="text" @click="editAttri(scope.row, scope.$index)"
              >编辑</el-button
            >
            <el-button type="text" @click="deleted(scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="operateBtn">
      <el-button type="primary" size="mini" @click="submitForm('basicInfoForm')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog :title="title" :visible.sync="dialogFormVisible">
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        class="mg-t-20"
        :rules="rules"
      >
        <el-form-item label="属性值" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "addAttribute",
  data() {
    return {
      pageType: "",
      dialogFormVisible: false,
      title: "添加属性值",
      currentIndex: 0,
      form: {
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入属性值" }],
      },
      basicInfoForm: {
        status: 1,
        name: "",
        values: "",
      },
      routeData: [],
      tableData: [],
    };
  },
  created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      let params = this.$route.query;
      for (let i in params) {
        this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
      }
      this.basicInfoForm.status = Number(this.basicInfoForm.status);
      let arr = this.basicInfoForm.values.split(",");
      let obj = {};
      this.tableData = [];
      arr.map((item) => {
        obj = {
          name: item,
        };
        this.tableData.push(obj);
      });
    } else {
      this.pageType = "新增";
    }
    console.log(this.basicInfoForm);
  },
  methods: {
    addAttri() {
      this.dialogFormVisible = true;
      this.title = "添加属性值";
      this.form.name = "";
    },
    editAttri(row, index) {
      this.dialogFormVisible = true;
      this.title = "编辑属性值";
      this.form.name = row.name;
      this.currentIndex = index;
    },
    // 提交表单
    submitForm(form) {
      // this.basicInfoForm.values
      let str = this.tableData.map((item) => item.name);
      this.basicInfoForm.values = str.join(",");
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.basicInfoForm.shelf_type == 1) {
            this.basicInfoForm.shelf_time = "";
          }
          if (Object.keys(this.$route.query).length !== 0) {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://api.boyalife.net/v1/cms/ItemAttribute/${this.basicInfoForm.uuid}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://api.boyalife.net/v1/cms/ItemAttribute`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
    deleted(index) {
      this.tableData.splice(index, 1);
    },
    toTop(index) {
      this.routeData = [].concat(this.tableData);
      let i = index,
        j = 0,
        temp = this.routeData[i];
      this.routeData[i] = this.routeData[j];
      this.routeData[j] = temp;
      this.tableData = [].concat(this.routeData);
    },
    up(index) {
      const currentRow = this.tableData.splice(index, 1)[0];
      this.tableData.splice(index - 1, 0, currentRow);
    },
    down(index) {
      const currentRow = this.tableData.splice(index, 1)[0];
      this.tableData.splice(index + 1, 0, currentRow);
    },
    confirm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let op = {
            name: this.form.name,
          };
          if (this.title == "添加属性值") {
            this.tableData.push(op);
            this.$message.success("添加成功！");
          } else {
            this.tableData[this.currentIndex].name = op.name;
            this.$message.success("编辑成功！");
          }
          this.form.name = "";
          this.dialogFormVisible = false;
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.addAttribute {
  margin-bottom: 80px;
}
.addAttribute .el-form {
  width: 800px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
>>> .el-input {
  width: auto;
}
</style>
