<template>
  <div class="projectOrder">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>全部订单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="订单类型">
          <el-select v-model="customerGroupForm.search_type" placeholder="请选择">
            <el-option label="技术服务" value="1"></el-option>
            <el-option label="细胞存储" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期搜索">
          <!-- <el-select v-model="customerGroupForm.region" placeholder="请选择">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select> -->
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <!-- <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in labelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select> -->
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="tabs flex">
        <p
          v-for="(item, index) in tabs"
          :key="item"
          @click="addClass(index)"
          :class="{ on: currentClass === index }"
        >
          {{ item }}
        </p>
      </div>
      <div class="text-right mg-b-20">
        <el-button
          type="primary"
          @click="addRemark('batch')"
          :disabled="uuids.length == 0"
          >批量备注</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        tooltip-effect="dark"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="订单信息" :align="'center'" width="500">
          <template slot-scope="scope">
            <div class="product flex space-between">
              <div class="flex" style="width: 350px">
                <el-image
                  v-if="scope.row.itemorder != null"
                  :src="scope.row.itemorder.item.cover"
                ></el-image>
                <div
                  style="width: 80%"
                  class="text-left"
                  v-if="scope.row.itemorder != null"
                >
                  <p class="font-12 ellipsis">
                    {{ scope.row.itemorder.item_name }}
                  </p>
                  <div
                    v-if="
                      Object.keys(scope.row.itemorder.item_specs).length > 0 &&
                      scope.row.itemorder != null
                    "
                  >
                    <p
                      class="font-12 color-999"
                      v-for="(item, i) in scope.row.itemorder.item_specs"
                      :key="i"
                    >
                      {{ i }}：&nbsp;{{ item }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="font-12 mg-l-20"
                style="width: 150px"
                v-if="scope.row.itemorder != null"
              >
                <p>{{ scope.row.itemorder.item_price }}</p>
                <p>× {{ scope.row.itemorder.item_number }}</p>
              </div>
            </div>
            <div class="orderInfo" v-if="scope.row.itemorder != null">
              <div class="flex space-between align-center">
                <div class="flex">
                  <p class="mg-r-10 font-12">
                    下单时间: {{ scope.row.itemorder.create_time }}
                  </p>
                  <p class="font-12">
                    订单编号：{{ scope.row.itemorder.order_sn }}
                  </p>
                  <p style="margin-left: 20px;cursor: pointer;" @click="setStar(scope.row.itemorder.uuid,scope.row.itemorder.is_star)">
                    <i class="el-icon-star-off" title="设置星标" v-if="scope.row.itemorder.is_star == 2"></i>
                    <i class="el-icon-star-on" title="取消星标" v-else style="color: rgb(255, 145, 0);font-size: 16px;"></i>
                  </p>
                </div>
                <div>
                  <p class="font-12">
                    共{{ scope.row.itemorder.item_number }}件商品，合计:{{
                      scope.row.itemorder.item_price
                    }}（含运费: ¥0.00 ）
                  </p>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="预约人信息">
          <template slot-scope="scope">
            <p>买家：{{ scope.row.name }}</p>
            <p>手机号：{{ scope.row.mobile }}</p>
          </template>
        </el-table-column>
        <el-table-column label="状态" :align="'center'">
          <template slot-scope="scope">
            <p v-if="scope.row.status == 1">待处理</p>
            <p v-if="scope.row.status == 2">已处理</p>
            <p v-if="scope.row.status == 3">已关闭</p>
          </template>
        </el-table-column>
        <el-table-column
          label="项目类型"
          prop="category.name"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column label="操作" :align="'center'">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="$router.push(`/projectOrderInfo?uuid=${scope.row.uuid}`)"
              >预约详情</el-button
            >
            <el-button
              size="mini"
              v-if="scope.row.status != 3 && scope.row.status != 2"
              type="text"
              @click="showDialog(scope.row)"
              >创建档案</el-button
            >
            <el-button
              size="mini"
              v-if="scope.row.status != 3 && scope.row.status != 2"
              type="text"
              @click="closeAppoint(scope.row)"
              >关闭预约</el-button
            >
            <el-button
              size="mini"
              v-if="scope.row.status != 3 && scope.row.status != 2"
              type="text"
              @click="addRemark('single', scope.row)"
              >备注</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>

    <el-dialog title="关闭预约" :visible.sync="closeDialog" width="30%">
      <el-form ref="iform" :model="form" label-width="100px" :rules="rules2">
        <el-form-item label="关闭原因" prop="close_cause">
          <el-select v-model="form.close_cause" placeholder="请选择">
            <el-option label="客户取消" value="客户取消"></el-option>
            <el-option label="联系不上" value="联系不上"></el-option>
            <el-option label="价格过高" value="价格过高"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理人" prop="close_user">
          <el-input v-model="form.close_user"></el-input>
        </el-form-item>
        <el-form-item label="补充说明">
          <el-input
            type="textarea"
            resize="none"
            rows="5"
            v-model="form.close_ext"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="closeDialog = false"
          >关闭</el-button
        >
        <el-button size="mini" type="primary" @click="closeItemAppoint('iform')"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";

export default {
  name: "projectOrder",
  components: { pagination },
  data() {
    return {
      tabs: ["全部", "待处理", " 已处理", " 已关闭"],
      customerGroupForm: {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
        search_status: "",
        search_type: ''
      },
      form: {
        close_cause: "",
        close_user: "",
        close_ext: "",
      },
      rules2: {
        close_cause: { required: true, message: "请选择原因", blur: "change" },
        close_user: { required: true, message: "请填写", blur: "blur" },
      },
      closeDialog: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      uuid: "",
      uuids: [],
      dialogVisible: false,
      value: 5,
      currentClass: 0,
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    if (this.$route.query.search_status) {
      this.currentClass = Number(this.$route.query.search_status);
      this.customerGroupForm.search_status = Number(
        this.$route.query.search_status
      );
    }
    this.getList();
  },
  methods: {
    setStar (uuid, isStar) {
      if(isStar == 1 ) {
        isStar = 2
      } else {
        isStar = 1
      }
      this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`
          },
          data: {
            uuid: uuid,
            is_star: isStar
          },
          url: ` https://api.boyalife.net/v1/cms/ItemAppoint/setStar`
        }).then(res => {
            this.$message({
              type: "success",
              message: "操作成功！"
            });
            this.getList();
          }).catch(err => {
            this.$message.erroe(err);
          });
    },
    showDialog(row) {
      this.$prompt("合同编号:", "创建档案信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPlaceholder: "请输入合同编号",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入合同编号",
      }).then(({ value }) => {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            contract_code: value,
            item_order_uuid: row.order_uuid,
          },
          url: ` https://api.boyalife.net/v1/cms/ItemOrder/sync`,
        })
          .then(() => {
            this.$message.success("操作成功!");
          })
          .catch((err) => {
            this.$message.error("该合同编号没有数据!");
          });
      });
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    //勾选
    handleSelectionChange(val) {
      // this.selectTable = val;
      this.uuids = val.map((item) => item.itemorder.uuid);
    },
    addClass(index) {
      this.currentClass = index;
      if (index !== 0) {
        this.customerGroupForm.search_status = index;
      } else {
        this.customerGroupForm.search_status = "";
      }
      this.getList();
    },
    onCopy() {
      this.$copyText("3333").then(
        (e) => {
          this.$message.success("复制成功!");
        },
        (e) => {
          this.$message.success("复制失败!");
        }
      );
    },
    closeAppoint(row) {
      this.closeDialog = true;
      this.uuid = row.uuid;
    },
    // 关闭预约
    closeItemAppoint(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.form,
            url: ` https://api.boyalife.net/v1/cms/ItemAppoint/close/${this.uuid}`,
          })
            .then((res) => {
              this.$message.success("操作成功");
              this.getList();
              this.closeDialog = false;
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
    //  添加备注
    addRemark(str, row) {
      let html = "";
      let uids = "";
      if (str == "single") {
        html = "添加备注";
        uids = row.itemorder.uuid;
      } else {
        html = "批量备注";
        uids = this.uuids.join(",");
      }
      this.$prompt("备注信息", html, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPlaceholder: "请输入",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入备注",
      })
        .then(({ value }) => {
          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: {
              remark: value,
              uuid: uids,
            },
            url: ` https://api.boyalife.net/v1/cms/ItemAppoint/remark`,
          })
            .then((res) => {
              this.$message.success("操作成功");
              this.getList();
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/ItemAppoint?search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_status=${this.customerGroupForm.search_status}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_type=${this.customerGroupForm.search_type}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.projectOrder .el-table .product .el-image {
  width: 100px;
  height: auto;
  border-radius: 0 !important;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.img img {
  width: 104px;
  height: 86px;
  border-radius: 8px;
}
>>> .el-table tr {
  position: relative;
  width: 100%;
}
>>> .el-table .el-table__cell {
  position: static;
}
>>> .el-table td .cell {
  padding-top: 40px;
}
.orderInfo {
  width: 100%;
  background: #f6f9ff;
  padding: 5px 20px;
  box-sizing: content-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
.copy {
  display: inline-block;
  padding: 2px 5px;
  margin-left: 20px;
  border-radius: 4px;
  border: 1px solid #666;
  color: #666;
  font-size: 12px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
</style>
