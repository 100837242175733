<template>
  <div class="afterSaleManage">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>全部订单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="售后类型">
          <el-select
            v-model="customerGroupForm.search_type"
            placeholder="请选择"
          >
            <el-option label="仅退款" :value="1"></el-option>
            <el-option label="退货" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发货状态">
          <el-select
            v-model="customerGroupForm.delivery_status"
            placeholder="请选择"
          >
            <el-option label="等待卖家发货" :value="2"></el-option>
            <el-option label="已发货" :value="3"></el-option>
            <el-option label="已完成" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select
            v-model="customerGroupForm.search_status"
            placeholder="请选择"
          >
            <el-option label="申请中" :value="1"></el-option>
            <el-option label="审核通过" :value="2"></el-option>
            <el-option label="审核不通过" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退货状态">
          <el-select
            v-model="customerGroupForm.after_status"
            placeholder="请选择"
          >
            <el-option label="待退货" :value="1"></el-option>
            <el-option label="退货中" :value="2"></el-option>
            <el-option label="待退款" :value="3"></el-option>
            <el-option label="已退款" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期搜索">
          <el-date-picker
            class="mg-l-10"
            v-model="customerGroupForm.dataTime"
            :clearable="false"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          >
            <i slot="append" class="el-icon-search"></i>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clearForm">清空</el-button>
        <el-button size="medium">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="tabs flex">
        <p
          v-for="(item, index) in tabs"
          :key="item"
          @click="addClass(index)"
          :class="{ on: currentClass === index }"
        >
          {{ item }}
        </p>
      </div>
      <div class="text-right mg-b-20">
        <el-button
          type="primary"
          :disabled="uuids.length == 0"
          @click="addRemark('batch')"
          >批量备注</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        tooltip-effect="dark"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" fixed="left">
        </el-table-column>
        <el-table-column label="商品信息" :align="'center'" width="300">
          <template slot-scope="scope" v-if="scope.row.goods.length > 0">
            <div class="nickAndAvatar">
              <el-image
                v-if="scope.row.goods.length > 0"
                :src="scope.row.goods[0].image"
              ></el-image>
              <div style="width: 200px">
                <el-tooltip :content="scope.row.goods[0].name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.goods[0].name }}
                  </p>
                </el-tooltip>
                <p>
                  <span
                    class="font-12"
                    style="color: #999"
                    v-for="(item, index) in JSON.parse(
                      scope.row.goods[0].specs
                    )"
                    :key="index"
                    >{{ item }}</span
                  >
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status_name" label="发货状态" :align="'center'">
        </el-table-column>
        <el-table-column prop="money" label="订单金额" :align="'center'">
        </el-table-column>
        <el-table-column prop="money" label="退款金额" :align="'center'">
        </el-table-column>
        <el-table-column label="售后状态" :align="'center'">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">申请中</span>
            <span v-else-if="scope.row.status == 2" style="color: green"
              >审核通过</span
            >
            <span v-else-if="scope.row.status == 3" style="color: red"
              >审核不通过</span
            >
            <span v-else>已关闭</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="申请时间"
          :align="'center'"
          width="160"
        >
        </el-table-column>
        <el-table-column label="操作" :align="'center'" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="
                $router.push('/afterSaleManageInfo?uuid=' + scope.row.uuid)
              "
              >查看详情</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="addRemark('single', scope.row)"
              >备注</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";

export default {
  name: "afterSaleManage",
  components: {
    pagination,
  },
  data() {
    return {
      // , '处理中', ' 售后完成', ' 售后关闭'
      tabs: ["全部"],
      customerGroupForm: {
        search_start_time: "",
        search_end_time: "",
        search_keyword: "",
        after_status: "",
        search_type: "",
        search_status: "",
        delivery_status: "",
      },
      uuids: [],
      dialogVisible: false,
      value: 5,
      currentClass: 0,
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    clearForm() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    addClass(index) {
      this.currentClass = index;
    },
    //勾选
    handleSelectionChange(val) {
      this.uuids = val.map((item) => item.uuid);
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/After?search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&search_status=${this.customerGroupForm.search_status}&search_type=${this.customerGroupForm.search_type}&after_status=${this.customerGroupForm.after_status}&delivery_status=${this.customerGroupForm.delivery_status}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    onCopy() {
      this.$copyText("3333").then(
        (e) => {
          this.$message.success("复制成功!");
        },
        (e) => {
          this.$message.success("复制失败!");
        }
      );
    },
    //  添加备注
    addRemark(type, row) {
      console.log(this.uuids);
      let uuid = "";
      if (type == "single") {
        uuid = row.uuid;
      } else {
        uuid = this.uuids.join(",");
      }
      this.$prompt("备注信息", "添加备注", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPlaceholder: "请输入",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入备注",
      }).then(({ value }) => {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            uuids: uuid,
            remark: value,
          },
          url: ` https://api.boyalife.net/v1/cms/After/remark`,
        })
          .then((res) => {
            this.$message({
              type: "success",
              message: "操作成功！",
            });
            this.getList();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.afterSaleManage .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.img img {
  width: 104px;
  height: 86px;
  border-radius: 8px;
}
>>> .el-table tr {
  position: relative;
  width: 100%;
}
>>> .el-table .el-table__cell {
  position: static;
}
>>> .el-table td .cell {
  padding-top: 40px;
}
.orderInfo {
  width: 100%;
  background: #f6f9ff;
  padding: 5px 20px;
  box-sizing: content-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
.copy {
  display: inline-block;
  padding: 2px 5px;
  margin-left: 20px;
  border-radius: 4px;
  border: 1px solid #666;
  color: #666;
  font-size: 12px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
</style>
