import { render, staticRenderFns } from "./addInformation.vue?vue&type=template&id=3aaf9d7e&scoped=true&"
import script from "./addInformation.vue?vue&type=script&lang=js&"
export * from "./addInformation.vue?vue&type=script&lang=js&"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./addInformation.vue?vue&type=style&index=1&id=3aaf9d7e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aaf9d7e",
  null
  
)

export default component.exports