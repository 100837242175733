<template>
  <div class="insuranceRecords" style="padding-bottom: 80px">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>细胞</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/cellList'">细胞列表</el-breadcrumb-item>
      <el-breadcrumb-item>保险记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="日期搜索">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium">查询</el-button>
        <el-button size="medium">清空</el-button>
        <el-button size="medium">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="dins_amt" label="保单金额" :align="'center'">
        </el-table-column>
        <el-table-column
          prop="baby_contract"
          label="宝宝保单"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column label="受保人信息" :align="'center'">
          <template slot-scope="scope">
            <!-- <router-link :to="'/customerInfo'"> -->
            <div class="nickAndAvatar">
              <p>
                <span>姓名：{{ scope.row.baby_name }}</span> <br />
                <span>性别：{{ scope.row.baby_gender }}</span>
              </p>
            </div>
            <!-- </router-link> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="deparent_contract"
          label="父母保单"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column
          prop="dinsurance_company"
          label="保单公司"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column
          prop="deffective_date"
          label="保单时间"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          width="220"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="$router.push('/claimRecords?uuid=' + scope.row.uuid)"
              >理赔记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="operateBtn">
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "insuranceRecords",
  components: {
    pagination,
  },
  data() {
    return {
      customerGroupForm: {
        search_start_time: "",
        search_end_time: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/ItemInsurance/${this.$route.query.uuid}?search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}`,
      })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
</style>
