<template>
  <div class="information">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容</el-breadcrumb-item>
      <el-breadcrumb-item>资讯</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/informationList'">资讯列表</el-breadcrumb-item>
      <el-breadcrumb-item>资讯详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content" v-if="Object.keys(info).length > 0">
      <h3 class="title-t">资讯内容</h3>
      <div class="box mg-t-20">
        <div class="flex">
          <p class="mg-r-20 label">标题</p>
          <div>
            <p>{{ info.information_title }}</p>
          </div>
        </div>
        <div class="flex mg-t-20 align-top">
          <p class="mg-r-20 label">内容</p>
          <div class="desc" v-html="info.desc"></div>
        </div>
        <div class="flex mg-t-20">
          <p class="mg-r-20 label">标签</p>
          <div>
            <el-tag v-for="item in info.label" :key="item.uuid"
              >#{{ item.name }}</el-tag
            >
          </div>
        </div>
        <div class="flex mg-t-20">
          <p class="mg-r-20 label">来源渠道</p>
          <div class="flex align-center">
            <el-image
              style="width: 50px; height: 50px; border-radius: 50%"
              :src="info.source_image"
            ></el-image>
            <p class="mg-l-20">{{ info.source_text }}</p>
          </div>
        </div>
        <div class="flex mg-t-20">
          <p class="mg-r-20 label">发布时间</p>
          <div>
            <p>
              {{ info.shelf_type == 1 ? info.create_time : info.shelf_time }}
            </p>
          </div>
        </div>
        <div class="flex mg-t-20">
          <p class="mg-r-20 label">状态</p>
          <div>
            <p>{{ info.status == 1 ? "显示" : "隐藏" }}</p>
          </div>
        </div>
        <div class="flex mg-t-20">
          <p class="mg-r-20 label">是否置顶</p>
          <div>
            <p>{{ info.is_top == 1 ? "是" : "否" }}</p>
          </div>
        </div>
        <div class="flex mg-t-20">
          <p class="mg-r-20 label">是否热门</p>
          <div>
            <p>{{ info.is_hot == 1 ? "是" : "否" }}</p>
          </div>
        </div>
        <el-divider></el-divider>
        <div>
          <!-- &nbsp;&nbsp; 评论&nbsp; {{info.comment_num}} -->
          <p>
            阅读&nbsp; {{ info.read_num }} &nbsp;&nbsp;收藏&nbsp;
            {{ info.collect_num }} &nbsp;&nbsp; 分享&nbsp;
            {{ info.forwarding_num }}
          </p>
        </div>
      </div>
    </div>
    <div class="recommondGoods mg-t-20" v-if="info.items.length > 0">
      <h3 class="title-t mg-b-20">推广项目</h3>
      <el-table
        ref="multipleTable"
        :data="info.items"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="项目信息" :align="'center'" width="260">
          <template slot-scope="scope">
            <div class="nickAndAvatar">
              <el-image
                :src="scope.row.cover"
              ></el-image>
              <div style="width: 200px">
                <el-tooltip :content="scope.row.name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="price" label="价格" :align="'center'" sortable>
          <template slot-scope="scope"> ￥{{ scope.row.price }} </template>
        </el-table-column>
        <!-- <el-table-column
          prop="extend_commission"
          label="佣金比例"
          :align="'center'"
          sortable
          >
          <template slot-scope="scope">
            {{ scope.row.extend_commission }}%
          </template>
        </el-table-column> -->

        <el-table-column prop="status" label="状态" :align="'center'">
          <template slot-scope="scope">
            <span style="color: #2ed477"
              >推广中</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="添加推广时间"
          sortable
          :align="'center'"
        >
        <template>
          <div>
            {{ info.shelf_type == 1 ? info.create_time : info.shelf_time }}
          </div>
        </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
            @click="cancelExtend('item',scope.row.pivot.id)"
              type="text"
              size="small"
              >取消推广</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="recommondGoods mg-t-20" v-if="info.goods.length > 0">
      <h3 class="title-t mg-b-20">推广商品</h3>
      <el-table
        ref="multipleTable"
        :data="info.goods"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="商品标题" :align="'center'" width="260">
          <template slot-scope="scope">
            <div class="nickAndAvatar">
              <el-image
                :src="scope.row.image"
              ></el-image>
              <div style="width: 200px">
                <el-tooltip :content="scope.row.name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="价格" :align="'center'" sortable>
          <template slot-scope="scope"> ￥{{ scope.row.price }} </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" :align="'center'">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.status == 1"
              >在售中</span
            >
            <span v-if="scope.row.status == 2">未开售</span>
            <span style="color: red" v-if="scope.row.status == 3">已售完</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="添加推广时间"
          sortable
          :align="'center'"
        >
        <template>
          <div>
            {{ info.shelf_type == 1 ? info.create_time : info.shelf_time }}
          </div>
        </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              @click="cancelExtend('goods',scope.row.pivot.id)"
              type="text"
              size="small"
              >取消推广</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="operateBtn">
      <el-button
        type="primary"
        v-if="info.is_hot == 2"
        size="mini"
        @click="setHot"
        >设为热门</el-button
      >
      <el-button
        type="danger"
        v-if="info.is_hot == 1"
        size="mini"
        @click="setHot"
        >取消热门</el-button
      >

      <el-button size="mini">数据</el-button>
      <el-button size="mini" v-if="info.is_top == 2" @click="setTop"
        >置顶</el-button
      >
      <el-button size="mini" v-if="info.is_top == 1" @click="setTop"
        >取消置顶</el-button
      >
      <el-button size="mini" v-if="info.status == 2" @click="isShow"
        >显示</el-button
      >
      <el-button size="mini" v-if="info.status == 1" @click="isShow"
        >隐藏</el-button
      >
      <el-button
        size="mini"
        @click="$router.push(`/addInformation?uuid=${$route.query.uuid}`)"
        >编辑</el-button
      >

      <el-button size="mini" @click="delInfomation">删除</el-button>
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "information",
  data() {
    return {
      uuid: "",
      info: {},
      tableData: [],
    };
  },
  created() {
    this.uuid = this.$route.query.uuid;
    this.getInfo();
  },
  methods: {
    // 取消推广
    cancelExtend(type,id) {
      let methods = ''
      if (type == 'item') {
        methods = 'removeitem'
      } else {
        methods = 'removegoods'

      }
      this.$confirm("确认取消推广吗？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.axios({
          method: "get", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          url: ` https://api.boyalife.net/v1/cms/Information/${methods}?id=${id}`,
        })
          .then((res) => {
            this.$message.success("操作成功！");
            this.getInfo()
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    // 热门
    setHot() {
      let isHot = this.info.is_hot == 1 ? 2 : 1;
      this.info.is_hot = isHot;
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          is_hot: isHot,
        },
        url: ` https://api.boyalife.net/v1/cms/Information/hot/${this.uuid}`,
      })
        .then((res) => {
          this.$message.success("操作成功！");
          this.getInfo();
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 置顶
    setTop() {
      let isTop = this.info.is_top == 1 ? 2 : 1;
      this.info.is_top = isTop;
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          is_top: isTop,
        },
        url: ` https://api.boyalife.net/v1/cms/Information/top/${this.uuid}`,
      })
        .then((res) => {
          this.$message.success("操作成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 显示隐藏
    isShow() {
      let status = this.info.status == 1 ? 2 : 1;
      this.info.status = status;
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          status: status,
        },
        url: ` https://api.boyalife.net/v1/cms/Information/status/${this.uuid}`,
      })
        .then((res) => {
          this.$message.success("操作成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取详情
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Information/${this.uuid}`,
      })
        .then((res) => {
          this.info = res.data.result;
          console.log(this.info.goods);
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 删除
    delInfomation() {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              // "Content-Type": "application/json;charset=utf-8",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://api.boyalife.net/v1/cms/Information/${this.uuid}`,
          })
            .then(() => {
              this.$message.success("删除成功！");
              this.$router.go(-1);
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
  },
};
</script>
<style scoped>
.information {
  margin-bottom: 80px;
}
.information .content,
.recommondGoods {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.information .content .box {
  margin-left: 20px;
}
.information .content .flex {
  align-items: center;
}
.information .content .label {
  width: 58px;
  text-align: right;
  color: #666;
}
.information .content .el-image {
  max-width: 200px;
  margin: 20px 0;
}
.information .content .box .el-image.avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.recommondGoods .el-table .el-image {
  width: 100px;
  height: auto;
  border-radius: 0;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.desc {
  width: 80%;
}
.desc >>> img {
  max-width: 50%;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>
