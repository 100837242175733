<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>编辑消息</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <h3 class="title-t mg-b-30">编辑信息</h3>
      <el-form :model="form" label-width="95px" ref="form" :rules="rules">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>小程序消息配置</span>
          </div>
          <el-form-item label="配置模版ID" prop="minigram_template_id">
            <el-input v-model="form.minigram_template_id"></el-input>
          </el-form-item>
        </el-card>
        <el-card class="box-card mg-t-20" shadow="never">
          <div slot="header" class="clearfix">
            <span>短信消息配置</span>
          </div>
          <el-form-item label="配置模版ID" prop="sms_template_id">
            <el-input v-model="form.sms_template_id"></el-input>
          </el-form-item>
        </el-card>
        <el-card class="box-card mg-t-20 mg-b-30" shadow="never">
          <div slot="header" class="clearfix">
            <span>站内信消息配置</span>
          </div>
          <el-form-item label="消息文本" prop="station_title">
            <el-input v-model="form.station_title"></el-input>
          </el-form-item>
          <el-form-item label="跳转链接" prop="station_type">
            <el-radio-group v-model="form.station_type">
              <el-radio label="1" value="1">链接</el-radio>
              <el-radio label="2" value="2">富文本</el-radio>
              <el-radio label="3" value="3">小程序页面</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="链接"
            v-if="form.station_type == '1'"
            :prop="form.station_type == '1' ? 'station_link' : ''"
          >
            <el-radio-group>
              <el-input v-model="form.station_link"></el-input>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="富文本内容"
            v-if="form.station_type == 2"
            :prop="form.station_type == 2 ? 'station_text' : ''"
          >
            <wangEditorTool ref="editors" :content="form.station_text" />
          </el-form-item>
          <el-form-item
            label="页面地址"
            v-if="form.station_type == '3'"
            :prop="form.station_type == 2 ? 'station_minigram' : ''"
          >
            <el-input v-model="form.station_minigram"></el-input>
          </el-form-item>
        </el-card>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submitForm('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
import wangEditorTool from "../../../components/wangEditorTool.vue";
export default {
  name: "addMessageConfig",
  components: {
    wangEditorTool,
  },
  data() {
    return {
      form: {
        minigram_template_id: "",
        sms_template_id: "",
        station_title: "",
        station_type: 1,
        station_link: "",
        content: "",
        station_text: "",
        station_minigram: "",
      },
      rules: {
        minigram_template_id: {
          required: true,
          message: "请输入",
          trigger: "blur",
        },
        sms_template_id: { required: true, message: "请输入", trigger: "blur" },
        station_title: { required: true, message: "请输入", trigger: "blur" },
        station_type: { required: true, message: "请输入", trigger: "change" },
        station_text: { required: true, message: "请输入", trigger: "input" },
        station_minigram: {
          required: true,
          message: "请输入",
          trigger: "input",
        },
      },
    };
  },
  created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      let params = this.$route.query;
      for (let i in params) {
        this.form[i] = JSON.parse(JSON.stringify(params[i]));
      }
    } else {
      this.pageType = "新增";
    }
  },
  methods: {
    // 提交表单
    submitForm(form) {
      if (this.form.station_type !== 1) {
        this.form.station_link = "";
      }
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (Object.keys(this.$route.query).length !== 0) {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.form,
              url: ` https://api.boyalife.net/v1/cms/MessageSetting/${this.form.uuid}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.form,
              url: ` https://api.boyalife.net/v1/cms/Agreement`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>
<style scoped>
>>> .el-form-item {
  width: 600px;
}
</style>
