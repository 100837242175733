<template>
  <div class="addProject">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>虚拟商品管理</el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{ path: `/virtualInventoryManage?uuid=${$route.query.uuid}` }"
        >虚拟库存管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ editType }}卡券</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="info mg-t-20">
      <el-form ref="basicInfoForm" :model="basicInfoForm" label-width="100px">
        <div class="boxLayout">
          <h3 class="title-t mg-b-30">基础信息</h3>
          <el-form-item label="卡券信息">
            <el-button type="primary" @click="add">添加卡券</el-button>
            <el-button type="primary" class="mg-l-20">导入卡券</el-button>
            <el-table
              :data="cardList"
              border
              v-if="cardList.length > 0"
              style="width: 800px"
              class="mg-t-20"
            >
              <el-table-column type="index" label="序号" width="55">
              </el-table-column>
              <el-table-column prop="number" label="卡券编码">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.code"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="deleted(scope.$index)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button type="primary" size="mini" @click="submitForm('basicInfoForm')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "addCard",
  data() {
    return {
      editType: "",
      cardList: [],
      basicInfoForm: {},
    };
  },
  created() {},
  methods: {
    add() {
      let obj = {
        code: "",
      };
      this.cardList.push(obj);
    },
    deleted(i) {
      this.cardList.splice(i, 1);
    },
    submitForm() {
      let list = this.cardList.filter((item, i) => {
        return item.code != "";
      });
      if (list.length < 1) {
        this.$message.warning("请至少添加一条卡券");
        return false;
      }

      let codes = list.map((item) => item.code);
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          goods_uuid: this.$route.query.good_uuid,
          codes: codes,
        },
        url: ` https://api.boyalife.net/v1/cms/VirtualStock`,
      })
        .then((res) => {
          this.$message.success("保存成功！");
          this.$router.go(-1);
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
  },
};
</script>
<style scoped>
.info {
  margin-bottom: 80px;
}
</style>
