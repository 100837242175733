<template>
  <div class="commodityOrder">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>全部订单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="订单来源">
          <el-select v-model="customerGroupForm.from_type" placeholder="请选择">
            <el-option label="商城" :value="1"></el-option>
            <el-option label="资讯推荐" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期搜索">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium" @click="exportCommidyOrder">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="tabs flex">
        <p
          v-for="(item, index) in tabs"
          :key="item"
          @click="addClass(index)"
          :class="{ on: currentClass === index }"
        >
          {{ item }}
        </p>
      </div>
      <div class="text-right mg-b-20">
        <el-button
          type="primary"
          @click="addRemark('batch')"
          :disabled="uuids.length == 0"
          >批量备注</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          label="订单信息"
          :align="'center'"
          width="500"
          prop="goods.name"
        >
          <template slot-scope="scope">
            <div class="product flex space-between">
              <div class="flex space-around">
                <el-image :src="scope.row.goods[0].image"></el-image>
                <div style="width: 80%" class="text-left">
                  <p class="ellipsis">
                    {{ scope.row.goods[0].name }}
                  </p>
                  <p class="font-12">
                    <span
                      v-for="(item, index) in JSON.parse(
                        scope.row.goods[0].specs
                      )"
                      :key="index"
                      >{{ item }}</span
                    >
                  </p>
                </div>
              </div>
              <div class="font-12 mg-l-20" style="width: 150px">
                <p>{{ scope.row.goods.retail_price }}</p>
                <p>{{ scope.row.goods.number }}</p>
              </div>
            </div>
            <div class="orderInfo">
              <div class="flex space-between align-center">
                <div class="flex">
                  <p class="mg-r-10 font-12">
                    下单时间: {{ scope.row.create_time }}
                  </p>
                  <p class="font-12">订单编号： {{ scope.row.order_sn }}</p>
                  <p style="margin-left: 20px;cursor: pointer;" @click="setStar(scope.row.uuid,scope.row.is_star)">
                    <i class="el-icon-star-off" title="设置星标" v-if="scope.row.is_star == 2"></i>
                    <i class="el-icon-star-on" title="取消星标" v-else style="color: rgb(255, 145, 0);font-size: 16px;"></i>
                  </p>
                </div>
                <div>
                  <p class="font-12">
                    共{{ scope.row.goods.length }}件商品，合计:
                    {{ scope.row.price }}（含运费: ¥0.00 ）
                  </p>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="收货信息">
          <template slot-scope="scope">
            <p>
              <span>买家：{{ scope.row.name }}</span>
            </p>
            <p>
              <span>收货地址：{{ scope.row.address }}</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">等待买家付款</span>
            <span v-if="scope.row.status == 2">等待卖家发货</span>
            <span
              v-if="
                scope.row.status == 3 &&
                  scope.row.goods.length > 1 &&
                  scope.row.is_all_send == 0
              "
              >部分商品已发货</span
            >
            <span
              v-if="
                scope.row.status == 3 &&
                  scope.row.goods.length > 1 &&
                  scope.row.is_all_send == 1
              "
              >已发货</span
            >
            <span v-if="scope.row.status == 4" style="color: red"
              >退款售后</span
            >
            <span v-if="scope.row.status == 5" style="color: green"
              >已完成</span
            >
            <span v-if="scope.row.status == 6">已关闭</span>
          </template>
        </el-table-column>
        <el-table-column label="配送方式">
          <template slot-scope="scope"> 快递配送 </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="$router.push(`/orderInfo?uuid=${scope.row.uuid}`)"
              >订单详情</el-button
            >
            <el-button size="mini" type="text" v-if="scope.row.status == 1 ||scope.row.status == 5 " @click="closeOrder(scope.row.uuid)"
              >关闭订单</el-button
            >
            <el-button
              size="mini"
              type="text"
              v-if="
                scope.row.status == 2 ||
                  (scope.row.status == 3 &&
                    scope.row.goods.length > 1 &&
                    scope.row.is_all_send == 0)
              "
              @click="send(scope.row, 'send')"
              >发货</el-button
            >
            <el-button
              size="mini"
              type="text"
              v-if="scope.row.is_all_send == 1 && scope.row.status == 3"
              @click="send(scope.row, 'change')"
              >修改物流</el-button
            >
            <el-button size="mini" type="text" v-if="scope.row.status == 4"
              >售后信息</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="addRemark('single', scope.row)"
              >备注</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog
      title="发货"
      :visible.sync="sendVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="send mg-t-20">
        <p>共{{ gridData.length }}件商品</p>
        <el-table
          ref="sendTables"
          border
          :data="gridData"
          class="mg-b-20 sendTable"
          row-key="getRowKeys"
          type="selection"
          @selection-change="sendHandleSelectionChange"
        >
          <el-table-column
            type="selection"
            border
            width="55"
            :selectable="selectTableUuids"
          >
          </el-table-column>
          <el-table-column
            label="商品信息"
            :align="'center'"
            show-overflow-tooltip
            width="260"
          >
            <template slot-scope="scope">
              <p>{{ scope.row.name }}</p>
              <!-- <p v-for="(item,index) in JSON.parse(scope.row.specs)" :key="index">{{ item}}</p> -->
            </template>
          </el-table-column>
          <el-table-column property="number" label="数量" align="center">
          </el-table-column>
          <el-table-column property="status" align="center" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">未发货</span>
              <span v-else>已发货</span>
            </template>
          </el-table-column>
          <el-table-column
            property="delivery.name"
            align="center"
            label="物流公司"
          >
          </el-table-column>
          <el-table-column
            property="delivery.code"
            label="物流单号"
            align="center"
            width="200"
          >
          </el-table-column>
        </el-table>
        <el-form
          ref="sendInfoForm"
          v-if="sendInfoForm.goods_uuid.length > 0"
          :model="sendInfoForm"
          :rules="sendInfoRules"
          label-width="80px"
          style="width: 400px"
        >
          <el-form-item label="物流公司" prop="delivery_id">
            <el-select
              v-model="sendInfoForm.delivery_id"
              placeholder="请选择"
              @change="change"
            >
              <el-option
                v-for="(item, i) in deliveryList"
                :key="i"
                :label="item.name"
                :value="item.uuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="运单号" prop="delivery_code">
            <el-input v-model="sendInfoForm.delivery_code"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="sendInfoForm.delivery_remark"
              type="textarea"
              resize="none"
              rows="5"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeSendDialog">关闭</el-button>
        <el-button
          size="mini"
          v-if="sendInfoForm.goods_uuid.length > 0"
          type="primary"
          @click="sumbitDelivery('sendInfoForm')"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "commodityOrder",
  components: {
    pagination
  },
  data() {
    return {
      selectTable: [],
      uuid: "",
      uuids: [],
      sendType: "",
      tabs: [
        "全部",
        "待付款",
        "待发货",
        "已发货",
        "退款售后",
        " 已完成",
        " 已关闭"
      ],
      sendVisible: false,
      selectableData: [],
      customerGroupForm: {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
        from_type: "",
        search_status: ""
      },
      dialogVisible: false,
      value: 5,
      currentClass: 0,
      sendInfoForm: {
        delivery_id: "",
        delivery_code: "",
        delivery_remark: "",
        goods_uuid: []
      },
      sendInfoRules: {
        delivery_name: {
          required: true,
          message: "请选择物流公司",
          trigger: "change"
        },
        delivery_code: {
          required: true,
          message: "请输入物流单号",
          trigger: "input"
        }
      },
      deliveryList: [],
      gridData: [],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  created() {
    if (this.$route.query.search_status) {
      console.log(this.$route.query.search_status);
      this.currentClass = Number(this.$route.query.search_status);
      this.customerGroupForm.search_status = Number(
        this.$route.query.search_status
      );
    }
    this.getList();
  },
  methods: {
    closeOrder (uuid) {
      this.$confirm("确定删除该订单吗?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://api.boyalife.net/v1/cms/GoodsOrder/close/${uuid}`,
          })
            .then((res) => {
              this.$message.success("操作成功！");
              this.getList();
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
    },
    setStar (uuid, isStar) {
      if(isStar == 1 ) {
        isStar = 2
      } else {
        isStar = 1
      }
      this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`
          },
          data: {
            uuid: uuid,
            is_star: isStar
          },
          url: ` https://api.boyalife.net/v1/cms/GoodsOrder/setStar`
        }).then(res => {
            this.$message({
              type: "success",
              message: "操作成功！"
            });
            this.getList();
          }).catch(err => {
            this.$message.erroe(err);
          });
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    addClass(index) {
      this.currentClass = index;
      if (index == 0) {
        this.customerGroupForm.search_status = "";
      } else {
        this.customerGroupForm.search_status = index;
      }
      this.getList();
    },
    // 导出
    exportCommidyOrder() {
      this.axios({
        method: "get", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://api.boyalife.net/v1/cms/GoodsOrder/export?search_type=1&search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&from_type=${this.customerGroupForm.from_type}&search_status=${this.customerGroupForm.search_status}`
      })
        .then(response => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let currentTime = new Date();
          let year = currentTime.getFullYear();
          let month = currentTime.getMonth() + 1;
          let day = currentTime.getDate();
          let formattedDate = `${year}/${month
            .toString()
            .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

          link.download = `实物商品订单 - ${formattedDate}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 批量操作的id
    getRowKey(row) {
      console.log(row);
      return row.goods_uuid;
    },
    change(b) {
      console.log(b);
    },
    //勾选
    handleSelectionChange(val) {
      this.selectTable = val;
      this.uuids = val.map(item => item.uuid);
    },
    sendHandleSelectionChange(val) {
      this.sendInfoForm.goods_uuid = [];

      val.map(item => {
        if (item.status != 2 && this.sendType == "send") {
          this.sendInfoForm.goods_uuid.push(item.goods_uuid);
        }
        if (item.status != 2 && this.sendType != "send") {
          this.sendInfoForm.goods_uuid.push(item.delivery_uuid);
        }
      });
    },
    closeSendDialog() {
      this.sendVisible = false;
      for (let i in this.sendInfoForm) {
        this.sendInfoForm[i] = "";
      }
    },
    send(row, type) {
      console.log(row);
      console.log(type);

      this.uuid = row.uuid;
      this.sendType = type;
      this.sendVisible = true;
      this.getDelivery();
      if (type == "send") {
        let uuids = row.goods.map(i => {
          if (i.status == 2) {
            return i.goods_uuid;
          }
        });
        let that = this;
        row.goods.forEach(item => {
          if (uuids.includes(item.goods_uuid)) {
            that.$nextTick(() => {
              that.$refs.sendTables.toggleRowSelection(item, true);
            });
          }
        });
      }

      this.gridData = row.goods;
      this.selectableData = JSON.parse(JSON.stringify(row.goods));
    },
    // 表格勾选置灰
    selectTableUuids(row) {
      console.log(row);
      if (this.gridData.length > 0 && this.sendType == "send") {
        //判断容器表里是否有数据
        //有数据的话，取容器表里的id和勾选过来的id判断是否有相等

        if (
          this.gridData.some(
            item => item.goods_uuid == row.goods_uuid && item.status == 2
          )
        ) {
          return false; //有相等的返回false ,返回false当前行的checkbox就可以置灰
        } else {
          return true; //没有相等的返回true,当前行checkbox不置灰
        }
      } else {
        return true;
      }
    },
    sumbitDelivery(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          let data = {};
          let updateData = {
            delivery_uuid: "",
            code: "",
            remark: "",
            uuids: []
          };
          if (this.sendType == "send") {
            data = this.sendInfoForm;
          } else {
            updateData.code = this.sendInfoForm.delivery_code;
            updateData.delivery_uuid = this.sendInfoForm.delivery_id;
            updateData.remark = this.sendInfoForm.delivery_remark;
            updateData.uuids = this.sendInfoForm.goods_uuid;
          }
          if (this.sendType == "send") {
            this.getData(
              "PUT",
              data,
              this.uuid,
              "/",
              null,
              "操作成功！",
              "GoodsOrder/send"
            );
          } else {
            this.getData(
              "PUT",
              updateData,
              this.uuid,
              "/",
              null,
              "操作成功！",
              "GoodsOrderDelivery/update"
            );
          }
          this.sendVisible = false;
          for (let i in this.sendInfoForm) {
            this.sendInfoForm[i] = "";
          }
        } else {
          return false;
        }
      });
    },
    onCopy() {
      this.$copyText("3333").then(
        e => {
          this.$message.success("复制成功!");
        },
        e => {
          this.$message.success("复制失败!");
        }
      );
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    //  添加备注
    addRemark(type, row) {
      let uuids = "";
      if (type == "single") {
        uuids = row.uuid;
      } else {
        uuids = this.uuids;
      }
      this.$prompt("备注信息", "添加备注", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPlaceholder: "请输入",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入备注"
      }).then(({ value }) => {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`
          },
          data: {
            uuids: uuids,
            remark: value
          },
          url: ` https://api.boyalife.net/v1/cms/GoodsOrder/remark`
        }).then(res => {
            this.$message({
              type: "success",
              message: "操作成功！"
            });
            this.getList();
          }).catch(err => {
            this.$message.erroe(err);
          });
      });
    },
    getList(status) {
      let queryStry = `search_type=1&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&from_type=${this.customerGroupForm.from_type}&search_status=${this.customerGroupForm.search_status}`;
      this.getData("get", {}, queryStry, "?", this.tableData, "", "GoodsOrder");
    },
    // 获取快递
    getDelivery() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: `https://api.boyalife.net/v1/app/ExpressList`
      })
        .then(res => {
          this.deliveryList = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getData(methods, data, queryStry, mark, tableData, msg, api) {
      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        data,
        url: ` https://api.boyalife.net/v1/cms/${api}${mark}${queryStry}`
      })
        .then(res => {
          if (msg !== "") {
            this.$message.success(msg);
            this.getList();
          }
          if (tableData !== null) {
            this.tableData = res.data.result;
          }
          if (methods === "delete" || methods === "post" || methods === "put") {
            this.getList();
          }
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    handleSizeChange(v) {
      console.log(v);
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    }
  }
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.commodityOrder .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.img img {
  width: 104px;
  height: 86px;
  border-radius: 8px;
}
>>> .el-table tr {
  position: relative;
  width: 100%;
}
>>> .el-table .el-table__cell {
  position: static;
}
>>> .el-table td .cell {
  padding-top: 40px;
}
>>> .sendTable.el-table td .cell {
  padding-top: 0;
}
.orderInfo {
  width: 100%;
  background: #f6f9ff;
  padding: 5px 20px;
  box-sizing: content-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
.copy {
  display: inline-block;
  padding: 2px 5px;
  margin-left: 20px;
  border-radius: 4px;
  border: 1px solid #666;
  color: #666;
  font-size: 12px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 22px;
  max-height: 22px;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}
>>> .el-dialog {
  width: 800px !important;
}
</style>
