<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item
        >{{ pageType }}{{ $route.query.name }}</el-breadcrumb-item
      >
    </el-breadcrumb>
    <div class="boxLayout">
      <h3 class="title-t mg-b-30">{{ $route.query.name }}</h3>
      <wangEditorTool ref="editors" :content="basicInfoForm.content" />
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submitForm()"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
import wangEditorTool from "../../../components/wangEditorTool.vue";
export default {
  name: "editProtocol",
  components: { wangEditorTool },
  data() {
    return {
      basicInfoForm: {
        content: "",
      },
      pageType: "",
      editor: null,
      html: "<p>hello</p>",
      toolbarConfig: {},
      editorConfig: { placeholder: "请输入内容..." },
      mode: "default",
    };
  },
  created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      let params = this.$route.query;
      for (let i in params) {
        this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
      }
    } else {
      this.pageType = "新增";
    }
  },
  methods: {
    getInfo() {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.basicInfoForm,
        url: ` https://api.boyalife.net/v1/cms/Agreement/${this.$route.query.uuid}`,
      })
        .then((res) => {
          this.$message.success("编辑成功！");
          this.$router.go(-1);
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },

    // 提交表单
    submitForm() {
      this.basicInfoForm.content = this.$refs.editors.html;
      if (Object.keys(this.$route.query).length !== 0) {
        delete this.basicInfoForm.create_time;
        delete this.basicInfoForm.delete_time;
        delete this.basicInfoForm.update_time;
        delete this.basicInfoForm.uuid;

        this.axios({
          method: "put", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: this.basicInfoForm,
          url: ` https://api.boyalife.net/v1/cms/Agreement/${this.$route.query.uuid}`,
        })
          .then((res) => {
            this.$message.success("编辑成功！");
            this.$router.go(-1);
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      } else {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: this.basicInfoForm,
          url: ` https://api.boyalife.net/v1/cms/Agreement`,
        })
          .then((res) => {
            this.$message.success("新增成功！");
            this.$router.go(-1);
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      }
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>
<style></style>
