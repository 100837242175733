<template>
  <div class="accountManagement">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>账号管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="角色">
          <el-select
            multiple
            collapse-tags
            v-model="customerGroupForm.search_role_uuid"
            placeholder="请选择"
          >
            <el-option
              v-for="(jtem, i) in roleGroupList"
              :key="i"
              :label="jtem"
              :value="i"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="添加时间">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium">导出</el-button>
      </div>
    </div>
    <div class="boxLayout mg-t-20">
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="$router.push('/addAccount')"
          >添加员工</el-button
        >
      </div>
      <el-table
        class="mg-t-20"
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="selection" :align="'center'"> </el-table-column>
        <el-table-column
          label="员工ID"
          prop="employee_number"
          :align="'center'"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="avatar"
          label="员工头像/昵称"
          :align="'center'"
          width="170"
        >
          <template slot-scope="scope">
            <div style="margin: 0 auto; width: 150px">
              <div class="flex align-center">
                <el-image fit="cover" :src="scope.row.avatar"></el-image>
                <div class="text-left">
                  <p class="ellipsis">{{ scope.row.name }}</p>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" :align="'center'" prop="status">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.status == 1"
              >启用</span
            >
            <span v-else>停用</span>
          </template>
        </el-table-column>
        <el-table-column
          label="账号"
          :align="'center'"
          prop="mobile"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="group_title"
          label="角色"
          :align="'center'"
          width="150"
        >
        </el-table-column>
        <!-- <el-table-column
          prop="is_order_notice"
          label="订单提醒"
          :align="'center'"
          width="120"
          >
          <template slot-scope="scope">
            <span style="color: #2ED477;" v-if="scope.row.is_order_notice == 1">是</span>
            <span v-else>否</span>

          </template>
        </el-table-column> -->
        <el-table-column
          prop="create_time"
          label="添加时间"
          :align="'center'"
          sortable
          width="200"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          width="180"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              @click="$router.push('/addAccount?uuid=' + scope.row.uuid)"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button
              v-if="scope.row.status == 1"
              @click="changeStatus(scope.row)"
              type="text"
              size="small"
              >禁用</el-button
            >
            <el-button
              v-else
              @click="changeStatus(scope.row)"
              type="text"
              size="small"
              >启用</el-button
            >

            <el-button @click="deleted(scope.row)" type="text" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "accountManagement",
  components: {
    pagination,
  },
  data() {
    return {
      roleGroupList: [],
      customerGroupForm: {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
        search_role_uuid: "",
      },
      labelOptions: [
        {
          label: "启用",
          value: "ok",
        },
        {
          label: "禁用",
          value: "no",
        },
      ],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
      },
    };
  },
  created() {
    this.getList();
    this.getRoleList();
  },
  methods: {
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },

    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    changeStatus(row) {
      this.status = row.status == 1 ? 2 : 1;
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          status: this.status,
        },
        url: ` https://api.boyalife.net/v1/cms/AdminUser/status/${row.uuid}`,
      })
        .then((res) => {
          this.$message.success("操作成功！");

          this.getList();
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 列表
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/AdminUser?search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_role_uuid=${this.customerGroupForm.search_role_uuid}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getRoleList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/AdminAuthGroupSelect`,
      })
        .then((res) => {
          this.roleGroupList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    deleted(row) {
      this.$confirm("删除后无法恢复，确定删除当前员工吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.axios({
          method: "delete", //请求方法
          headers: {
            "Content-Type": "text/html;charset=UTF-8",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          url: ` https://api.boyalife.net/v1/cms/AdminUser/${row.uuid}`,
        })
          .then((res) => {
            this.$message.success("删除成功！");
            this.getList();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
>>> .el-table .el-button {
  width: 40px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 22px;
  max-height: 22px;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}
</style>
