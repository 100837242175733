<template>
  <div class="morningPaperList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/activityList' }"
        >活动列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>活动分类管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container boxLayout">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          ></el-input>
        </el-form-item>
        <el-form-item label="发布时间">
          <el-date-picker
            v-model="dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getActivityCategoryList"
          >查询</el-button
        >
        <el-button size="medium" @click="clearForm">清空</el-button>
      </div>
    </div>
    <div class="boxLayout mg-t-20">
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="showDialog('add')"
          >添加活动分类</el-button
        >
      </div>
      <el-table
        :data="tableData.data"
        style="width: 100%; margin-bottom: 20px"
        row-key="uuid"
        border
        @sort-change="sortChange"
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          prop="category_name"
          label="分类名称"
          :align="'center'"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="number"
          label="活动数量"
          sortable
          :align="'center'"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="status" :align="'center'" label="是否显示">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.status == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          sortable
          :align="'center'"
          label="创建时间"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          fixed="right"
          width="400"
        >
          <template slot-scope="scope">
            <template>
              <el-button type="text" @click="showDialog('edit', scope.row)"
                >编辑</el-button
              >
            </template>
            <el-button type="text" @click="toTop(scope.row.uuid)"
              >置顶</el-button
            >
            <el-button type="text" @click="moveUp(scope.row.uuid)"
              >上移</el-button
            >
            <el-button type="text" @click="moveDown(scope.row.uuid)"
              >下移</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status == 1"
              @click="showOrHide(scope.row)"
              >隐藏</el-button
            >
            <el-button type="text" v-else @click="showOrHide(scope.row)"
              >显示</el-button
            >

            <el-button type="text" @click="deleted(scope.row.uuid)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="类别"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="80px"
        class="mg-t-20"
        :rules="formRules"
      >
        <el-form-item label="类别名称" prop="category_name">
          <el-input v-model="form.category_name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitLowerLevel('form')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "activityCategory",
  data() {
    return {
      dataTime: "",
      editType: "",
      uuid: "",
      customerGroupForm: {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
        search_order: "",
        order_type: "",
      },
      form: {
        category_name: "",
      },
      dialogFormVisible: false,
      tableData: [],
      formRules: {
        category_name: [
          { required: true, message: "分类名称必填", trigger: "blur" },
        ],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getActivityCategoryList();
  },
  methods: {
    sortChange(column) {
      console.log(column);
      if (column.order == "descending") {
        this.customerGroupForm.search_order = column.prop;
        this.customerGroupForm.order_type = "desc";
      } else {
        this.customerGroupForm.search_order = column.prop;
        this.customerGroupForm.order_type = "asc";
      }
      // this.customerGroupForm.search_key = column.prop
      this.getActivityCategoryList();
    },
    showDialog(str, row) {
      this.dialogFormVisible = true;
      if (str == "edit") {
        this.editType = "edit";
        this.uuid = row.uuid;
        this.form = {
          category_name: row.category_name,
          fid: row.fid,
          status: row.status,
          is_hot: row.status,
        };
      } else {
        this.editType = "add";
        this.form.category_name = "";
      }
    },
    clearForm() {
      this.dataTime = "";
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getActivityCategoryList();
    },
    changeCategory(v) {
      this.form.fid = v;
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    getActivityCategoryList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/ActivityCategory?search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_order=${this.customerGroupForm.search_order}&order_type=${this.customerGroupForm.order_type}`,
      })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    // 编辑跳转
    toEdit(row) {
      this.$router.push({
        name: "addInformationClassification",
        query: { ...row },
      });
    },

    // 置顶
    toTop(uuid) {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/ActivityCategory/top/${uuid}`,
      }).then((res) => {
        this.$message.success("置顶成功！");
        this.getActivityCategoryList();
      });
    },
    // 上移
    moveUp(uuid) {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/ActivityCategory/moveup/${uuid}`,
      }).then(() => {
        this.$message.success("上移成功！");
        this.getActivityCategoryList();
      });
    },
    // 下移
    moveDown(uuid) {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/ActivityCategory/movedown/${uuid}`,
      }).then(() => {
        this.$message.success("下移成功！");
        this.getActivityCategoryList();
      });
    },
    // 显示隐藏
    showOrHide(row) {
      let status = "";
      if (row.status == 1) {
        status = 2;
      } else {
        status = 1;
      }
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          status,
        },
        url: ` https://api.boyalife.net/v1/cms/ActivityCategory/status/${row.uuid}`,
      }).then(() => {
        this.$message.success("操作成功！");
        this.getActivityCategoryList();
      });
    },
    submitLowerLevel(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.editType === "add") {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: { ...this.form },
              url: ` https://api.boyalife.net/v1/cms/ActivityCategory`,
            }).then((res) => {
              this.$message.success("新增成功！");
              this.getActivityCategoryList();
              this.dialogFormVisible = false;
            });
          } else {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.form,
              url: ` https://api.boyalife.net/v1/cms/ActivityCategory/${this.uuid}`,
            }).then(() => {
              this.$message.success("编辑成功！");
              this.getActivityCategoryList();
              this.dialogFormVisible = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    deleted(uuid) {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://api.boyalife.net/v1/cms/ActivityCategory/${uuid}`,
          })
            .then(() => {
              this.$message.success("删除成功！");
              this.getActivityCategoryList();
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
  },
};
</script>
<style scoped>
>>> .el-table .el-button {
  width: auto;
  margin-right: 10px;
}

>>> .el-dialog .el-form {
  width: 400px;
}
</style>
