<template>
  <div class="labelGroup">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>客户</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/customerLabel' }"
        >客户标签</el-breadcrumb-item
      >
      <el-breadcrumb-item>客户标签组</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="创建日期">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入"
            v-model="customerGroupForm.search_keyword"
            class="input-with-select"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getLabelGroupList"
          >查询</el-button
        >
        <el-button size="medium" @click="clearForm">清空</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="addLabelGroup('')"
          >新增标签组</el-button
        >
      </div>
      <el-table :data="tableData.data" border style="width: 100%">
        <el-table-column fixed prop="name" label="标签组名称" align="center">
        </el-table-column>

        <el-table-column prop="number" label="标签数量" align="center">
        </el-table-column>
        <el-table-column
          prop="operation_number"
          label="运营标签数量"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="auto_number" label="规则标签数量" align="center">
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center" fixed="right" width="180">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="addLabelGroup(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="deleted(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "labelGroup",
  components: {
    pagination,
  },
  data() {
    return {
      dataTime: "",
      customerGroupForm: {
        search_start_time: "",
        search_end_time: "",
        search_keyword: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getLabelGroupList();
  },
  methods: {
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    clearForm() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getLabelGroupList();
    },
    addLabelGroup(v) {
      console.log(v);
      let methods = v == "" ? "post" : "put";
      let str = v == "" ? "" : "/" + v.uuid;
      this.$prompt("", "请输入分组名", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: v == "" ? "" : v.name,
        inputType: "text",
        inputPlaceholder: "请输入",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入",
      }).then(({ value }) => {
        this.axios({
          method: methods, //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            name: value,
          },
          url: ` https://api.boyalife.net/v1/cms/UserLabelGroup${str}`,
        })
          .then((res) => {
            this.$message.success("操作成功！");
            this.getLabelGroupList();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    getLabelGroupList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/UserLabelGroup?search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    deleted(row) {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.axios({
          method: "delete", //请求方法
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          url: ` https://api.boyalife.net/v1/cms/UserLabelGroup/${row.uuid}`,
        })
          .then(() => {
            this.$message.success("删除成功！");
            this.getLabelGroupList();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getLabelGroupList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getLabelGroupList();
    },
  },
};
</script>
<style scoped>
.labelGroup {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
.el-table .el-button {
  width: 50px !important;
}
</style>
