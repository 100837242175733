<template>
  <div class="userFeedbackList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容</el-breadcrumb-item>
      <el-breadcrumb-item>反馈列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="分类">
          <el-select
            v-model="customerGroupForm.category_uuid"
            placeholder="请选择"
          >
            <el-option
              v-for="(jtem, i) in options"
              :key="i"
              :label="jtem"
              :value="i"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getFeedbackList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="tabs flex">
        <p
          v-for="(item, index) in tabs"
          :key="index"
          @click="addClass(index)"
          :class="{ on: currentClass === index }"
        >
          {{ item }}
        </p>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        @sort-change="sortChange"
        style="width: 100%"
      >
        <el-table-column
          prop="user.nick_name"
          label="用户名称"
          :align="'center'"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="user_uuid"
          label="用户ID"
          :align="'center'"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="content"
          label="内容"
          :align="'center'"
          width="260"
          show-overflow-tooltip=""
        >
        </el-table-column>
        <el-table-column label="图片" :align="'center'">
          <template slot-scope="scope">
            <div class="product" v-if="scope.row.image.length > 0">
              <el-image
                v-for="(item, index) in scope.row.image"
                :key="index"
                :src="item.image"
                @click.self="showBigImage(item.image)"
              ></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="contact" label="联系方式" :align="'center'">
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="反馈时间"
          :align="'center'"
          sortable
          width="180 "
        >
        </el-table-column>
        <el-table-column prop="category.name" label="分类" :align="'center'">
        </el-table-column>
        <el-table-column
          v-if="currentClass == 0"
          label="操作"
          :align="'center'"
          width="100"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 2"
              @click="pass(scope.row)"
              type="text"
              size="small"
              >标记已处理</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <BigImg
      :visible="photoVisible"
      :url="bigImgUrl"
      @closeClick="
        () => {
          photoVisible = false;
        }
      "
    ></BigImg>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
import BigImg from "@/components/previwerImg.vue";
export default {
  name: "userFeedbackList",
  components: {
    pagination,
    BigImg,
  },
  data() {
    return {
      tabs: ["未处理", "已处理"],
      status: 2,
      currentClass: 0,
      photoVisible: false,
      bigImgUrl: "",
      // 免责声明
      disclaimers: "",

      customerGroupForm: {
        search_keyword: "",
        search_order: "create_time desc",
        category_uuid: "",
      },
      options: [],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
      },
    };
  },
  created() {
    this.getFeedbackList(this.status);
    this.getCategory();
  },
  methods: {
    sortChange(column) {
      if (column.order == "descending") {
        this.customerGroupForm.search_order = "create_time DESC";
      } else {
        this.customerGroupForm.search_order = "create_time ASC";
      }
      this.getFeedbackList();
    },
    showBigImage(url) {
      //点击图片函数，点击后，把photoVisible设置成true
      if (url != "") {
        this.photoVisible = true;
        this.bigImgUrl = url;
      }
    },
    addClass(index) {
      this.currentClass = index;
      if (index === 0) {
        this.status = 2;
      } else {
        this.status = 1;
      }
      this.getFeedbackList(this.status);
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getFeedbackList();
    },
    // 列表
    getFeedbackList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Feedback?status=${this.status}&search_order=${this.customerGroupForm.search_order}&category_uuid=${this.customerGroupForm.category_uuid}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.customerGroupForm.search_keyword}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getCategory() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/FeedbackCategorySelect`,
      })
        .then((res) => {
          this.options = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    pass(row) {
      this.$confirm("确定已经处理完毕吗?", "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: {
              status: 1,
            },
            url: ` https://api.boyalife.net/v1/cms/Feedback/status/${row.uuid}`,
          })
            .then((res) => {
              this.$message.success("操作成功！");
              this.getFeedbackList(1);
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消标记",
          });
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getFeedbackList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getFeedbackList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.el-table .el-image {
  width: auto;
  height: auto;
}
.userFeedbackList .el-table .product .el-image {
  border-radius: 0 !important;
  width: 80px;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
>>> .el-table .el-button {
  width: 40px;
}
.dataTable {
  position: relative;
}
.dataTable .tabs {
  position: static;
}
.dataTable .tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.dataTable .tabs p:after {
  content: "";
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.dataTable .tabs p.on:after {
  background: #409eff;
}
.el-table .el-button {
  width: auto;
}
</style>
