<template>
  <div class="storedValuePurchaseRecords">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/storedValuePurchaseRecords' }"
        >储值订单记录</el-breadcrumb-item
      >
      <el-breadcrumb-item>储值明细</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="收支类型">
          <el-select
            v-model="customerGroupForm.search_type"
            placeholder="请选择"
          >
            <el-option label="收入" :value="1"></el-option>
            <el-option label="支出" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期搜索">
          <el-date-picker
            class="mg-l-10"
            v-model="customerGroupForm.dataTime"
            :clearable="false"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单搜索" class="keyword">
          <el-input
            style="width: 200px; margin-left: 10px"
            placeholder="请输入内容"
            v-model="customerGroupForm.content"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        @sort-change="visitorSortChange"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <!-- <el-table-column
          type="selection"
          width="55">
        </el-table-column> -->
        <el-table-column label="内容" :align="'center'" prop="nickName">
          <template slot-scope="scope">
            <p>消费{{ scope.row.money }}元</p>
            <p>关联订单号：{{ scope.row.order_sn }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="card.user"
          label="用户信息"
          :align="'center'"
          width="200"
        >
          <template slot-scope="scope">
            <router-link
              :to="'/customerInfo?uuid=' + scope.row.card.user.uuid"
              v-if="scope.row.card.user !== null"
            >
              <div style="margin: 0 auto; width: 150px">
                <div class="flex align-center">
                  <el-image
                    fit="cover"
                    :src="scope.row.card.user.avatar"
                  ></el-image>
                  <div class="text-left">
                    <p>{{ scope.row.card.user.nick_name }}</p>
                    <p>{{ scope.row.card.user.mobile }}</p>
                  </div>
                </div>
              </div>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="balance_money"
          label="储值金额"
          :align="'center'"
        >
          <template slot-scope="scope">
            ￥{{ scope.row.balance_money }}
          </template>
        </el-table-column>
        <el-table-column prop="money" label="储值变动" :align="'center'">
          <template slot-scope="scope"> ￥{{ scope.row.money }} </template>
        </el-table-column>
        <el-table-column prop="status" label="收支类型" :align="'center'">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.type == 1">收入</span>
            <span style="color: red" v-else>支出</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="记录时间"
          :align="'center'"
          width="160"
          sortable
        >
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "storedValuePurchaseRecords",
  components: { pagination },
  data() {
    return {
      customerGroupForm: {
        search_type: "",
        search_start_time: "",
        search_end_time: "",
        search_keyword: "",
        search_order: "",
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    visitorSortChange(column) {
      console.log(column);
      if (column.order == "descending") {
        this.customerGroupForm.search_order = "create_time desc";
      } else {
        this.customerGroupForm.search_order = "create_time Asc";
      }
      // this.customerGroupForm.search_key = column.prop
      this.getList();
    },
    addClass(index) {
      this.currentClass = index;
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/StoredCardUse?card_uuid=${this.$route.query.uuid}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&search_type=${this.customerGroupForm.search_type}&search_order=${this.customerGroupForm.search_order}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 28px;
  max-height: 28px;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}
.storedValuePurchaseRecords .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
.dataTable {
  position: relative;
  padding-top: 80px;
}
.nickAndAvatar .el-image {
  border-radius: 0;
}

>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
</style>
