<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>数据</el-breadcrumb-item>
      <el-breadcrumb-item>推广员分析</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="overview boxLayout promoterAnalysis">
      <div class="title flex space-between align-center">
        <h3 class="title-t">推广员概览</h3>
        <div class="flex">
          <el-form
            :inline="true"
            :model="customerGroupForm"
            class="demo-form-inline"
          >
            <el-form-item label="选择时间">
              <el-date-picker
                class="mg-l-20"
                v-model="date1"
                type="daterange"
                align="right"
                unlink-panels
                @change="getTimes2"
                :clearable="false"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="flex mg-t-20">
        <div
          class="box pad-20 mg-r-10"
          v-if="Object.keys(projectOverview).length > 0"
        >
          <h4 class="color-999 mg-b-10">
            今日新增推广员
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">{{ projectOverview.add_promote || 0 }}</p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ projectOverview.add_promote_scale || 0 }}%</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            今日新增绑定客户数
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">
            {{ projectOverview.promote_add_user || 0 }}
          </p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ projectOverview.promote_add_user_scale || 0 }}%</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            今日开单推广员数
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">
            {{ projectOverview.promote_order || 0 }}
          </p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ projectOverview.promote_order_scale || 0 }}%</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            今日成交客户数
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">
            {{ projectOverview.promote_order_user_scale || 0 }}
          </p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ projectOverview.promote_commission || 0 }}%</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            今日成交订单数
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">
            {{ projectOverview.promote_order_number || 0 }}
          </p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ projectOverview.promote_order_number_scale || 0 }}%</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10 mg-t-20">
          <h4 class="color-999 mg-b-10">
            今日推广员销售额
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">
            {{ projectOverview.promote_order_amount || 0 }}
          </p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ projectOverview.promote_order_amount_scale || 0 }}%</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10 mg-t-20">
          <h4 class="color-999 mg-b-10">
            今日预计发放佣金
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">
            {{ projectOverview.promote_commission || 0 }}
          </p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ projectOverview.promote_commission_scale || 0 }}%</p>
          </div>
        </div>
      </div>
      <div class="mg-t-20">
        <div class="title flex space-between align-center">
          <h3 class="title-t">推广员排行榜</h3>
          <div class="flex">
            <el-form
              :inline="true"
              :model="customerGroupForm"
              class="demo-form-inline"
            >
              <el-form-item label="选择时间">
                <el-date-picker
                  class="mg-l-20"
                  v-model="date2"
                  type="daterange"
                  align="right"
                  unlink-panels
                  @change="getTimes2"
                  :clearable="false"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy/MM/dd"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table
          class="mg-t-20"
          ref="multipleTable"
          :data="tableData"
          border
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            label="排名"
            type="index"
            :align="'center'"
            width="55"
          >
          </el-table-column>
          <el-table-column
            label="推广员名称"
            :align="'center'"
            prop="name"
            width="160"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="order_amount"
            label="新增销售额"
            :align="'center'"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="order_number"
            label="新增订单数"
            :align="'center'"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="add_user"
            label="新增客户数"
            :align="'center'"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="share_number"
            label="商品分享次数"
            :align="'center'"
            sortable
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="share_visitor_number"
            label="商品分享浏览次数"
            :align="'center'"
            sortable
            width="150"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
let currentTime = new Date();
let year = currentTime.getFullYear();
let month = currentTime.getMonth() + 1;
let day = currentTime.getDate();
let formattedDate = `${year}/${month
  .toString()
  .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

const start = new Date();
start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
let timed = start.toLocaleDateString();
export default {
  name: "promoterAnalysis",
  data() {
    return {
      customerGroupForm: {
        start_time: timed,
        end_time: formattedDate
      },
      customerGroupForm2: {
        start_time: timed,
        end_time: formattedDate
      },
      date1: [timed, formattedDate],
      date2: [timed, formattedDate],

      projectOverview: {},
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      tableData: []
    };
  },
  created() {
    this.getProjectData();
    this.getRank();
  },
  methods: {
    getTimes(v) {
      this.customerGroupForm.start_time = v[0];
      this.customerGroupForm.end_time = v[1];
      this.getProjectData();
    },
    getTimes2(v) {
      this.customerGroupForm2.start_time = v[0];
      this.customerGroupForm2.end_time = v[1];
      this.getRank();
    },
    // 商品概览
    getProjectData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://api.boyalife.net/v1/cms/Data/extend?start_time=${this.customerGroupForm.start_time}&end_time=${this.customerGroupForm.end_time}`
      })
        .then(res => {
          this.projectOverview = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 排行榜
    getRank() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://api.boyalife.net/v1/cms/Data/extendRank?start_time=${this.customerGroupForm2.start_time}&end_time=${this.customerGroupForm2.end_time}`
      })
        .then(res => {
          this.tableData = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    }
  }
};
</script>
<style scoped>
.overview .flex {
  flex-wrap: wrap;
}
.overview .box {
  padding: 20px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  min-width: 175px;
}
::v-deep .el-form-item {
  margin-bottom: 0;
}
</style>
