<template>
  <div>
    <div class="flex space-between">
      <div class="tabs flex">
        <p v-for="(item,index) in tabs" :key="index" @click="addClass(index)" :class="{'on': currentClass === index}">{{ item }}</p>
      </div>
    </div>
    <div class="libraryBox">
      <imageLibrary v-if="currentClass === 0" v-bind="$attrs"/>
      <videoLibrary v-else-if="currentClass === 1"/>
      <fileLibrary  v-else/>
    </div>
  </div>
</template>
<script>
import imageLibrary from './imageLibrary.vue';
import videoLibrary from './videoLibrary.vue';
import fileLibrary from './fileLibrary.vue';
export default {
  name: 'materialLibrary',
  components: {
    imageLibrary,
    videoLibrary,
    fileLibrary
  },
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      currentClass: 0,
      tabs: ['图片', '视频', '文件'],
      uuid: ''
    }
  },
  created () {
    if (this.type === 'chooseImage') {
      this.tabs = this.tabs.filter(item => item === '图片')
    }
  },
  mounted () {
  },
  methods: {

    addClass (index) {
      this.currentClass = index
    },
    
  }
}
</script>
<style scoped>
.tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.tabs p:after {
  content: '';
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.tabs p.on {
  color: #2561ef;
}
.tabs p.on:after {
  background: #2561ef;
}
.libraryBox {
  padding: 20px 0;
  border-top: 1px solid rgb(196, 196, 196);
}
</style>