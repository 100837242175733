import { render, staticRenderFns } from "./addCoupon.vue?vue&type=template&id=4d8896da&scoped=true&"
import script from "./addCoupon.vue?vue&type=script&lang=js&"
export * from "./addCoupon.vue?vue&type=script&lang=js&"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./addCoupon.vue?vue&type=style&index=1&id=4d8896da&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d8896da",
  null
  
)

export default component.exports