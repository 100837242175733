<template>
  <!-- 添加客户 -->
  <div class="addMemberLevel">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>客户</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/customerList' }"
        >全部客户</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ pageType }}会员等级</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicInfo">
      <h3 class="title-t mg-b-30">基础信息</h3>

      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="等级名称" prop="name">
          <el-input v-model="basicInfoForm.name" class="first"></el-input>
        </el-form-item>
        <el-form-item label="等级背景" class="level-bj" prop="background">
          <div>
            <div class="flex">
              <p
                class="colorPicker"
                @click="showColorPicker = !showColorPicker"
              >
                <span
                  :style="{ backgroundColor: `${basicInfoForm.background}` }"
                ></span>
              </p>
              <Chrome
                class="picker"
                v-model="basicInfoForm.background"
                v-if="showColorPicker"
                @input="updateValue"
              />
            </div>
            <div
              class="img"
              :style="{ backgroundColor: `${basicInfoForm.background}` }"
            >
              <p class="mg-t-20 mg-l-20 color-white" style="margin-top: 30px">
                黄金会员
              </p>
              <p class="mg-t-20 mg-l-20 color-white">
                7 <span class="font-10">成长值</span>
              </p>
              <el-progress
                class="mg-t-20"
                :percentage="70"
                :show-text="false"
              ></el-progress>
              <p class="font-12 color-white mg-l-20 mg-t-10">
                再获得 0 成长值+0积分成为 白金会员
              </p>
            </div>
          </div>
          <span class="font-12 color-999"
            >仅为预览效果，实际以用户查看时为准</span
          >
        </el-form-item>
        <el-form-item label="等级顺序">
          <el-select v-model="basicInfoForm.level" placeholder="请选择">
            <el-option label="1" value="1"></el-option>
            <el-option label="2" value="2"></el-option>
            <el-option label="3" value="3"></el-option>
            <el-option label="4" value="4"></el-option>
            <el-option label="5" value="5"></el-option>
            <el-option label="6" value="6"></el-option>
            <el-option label="7" value="7"></el-option>
            <el-option label="8" value="8"></el-option>
            <el-option label="9" value="9"></el-option>
            <el-option label="10" value="10"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-switch
            :active-value="1"
            :inactive-value="2"
            v-model="basicInfoForm.status"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="升级条件">
          <span slot="label"><i style="color: red">*</i> 升级条件</span>
          <div class="">
            <div class="flex align-center mg-r-20">
              <el-checkbox
                v-model="basicInfoForm.is_consumption_upgrade"
                :true-label="1"
                :false-label="2"
                >累计消费金额</el-checkbox
              >
              <el-input
                v-model="basicInfoForm.consumption"
                class="mg-l-10"
                style="width: 200px"
              >
                <template slot="prepend">元</template>
              </el-input>
            </div>
            <div class="flex align-center mg-t-10">
              <el-checkbox
                v-model="basicInfoForm.is_growth_upgrade"
                disabled
                :true-label="1"
                :false-label="2"
                >成长值</el-checkbox
              >
              <el-input
                v-model="basicInfoForm.growth"
                class="mg-l-10"
                style="width: 200px"
              ></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="等级图标">
          <span slot="label"><i style="color: red">*</i> 等级图标(一)</span>
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="acceptType"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest"
            v-loading="loading"
          >
          <img
              v-if="basicInfoForm.image !== ''"
              :src="basicInfoForm.image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseBanner">从素材库选择</el-button>
          <p class="font-12 color-999">建议尺寸：800*800像素，限制一张</p>
        </el-form-item>
        <el-form-item label="等级图标">
          <span slot="label"><i style="color: red">*</i> 等级图标(二)</span>
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="acceptType"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest2"
            v-loading="loading"
          >
          <img
              v-if="basicInfoForm.ext_image !== ''"
              :src="basicInfoForm.ext_image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseBanner2">从素材库选择</el-button>
          <p class="font-12 color-999">建议尺寸：800*800像素，限制一张</p>
        </el-form-item>
        <el-form-item label="权益勾选(多选)">
          <el-checkbox-group v-model="basicInfoForm.right_uuid">
            <el-checkbox
              v-for="item in benefitArr"
              :label="item.uuid"
              :key="item.uuid"
            >
              {{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="等级说明">
          <wangEditorTool ref="editors" :content="basicInfoForm.desc" />
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submitForm('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog
        :isSingle="true"
        @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog"
      />
    </el-dialog>
  </div>
</template>
<script>
import { Chrome } from "vue-color";
import wangEditorTool from "../../../components/wangEditorTool.vue";
import imageDialog from "../../../components/imageDialog.vue";

export default {
  name: "addMemberLevel",
  components: {
    Chrome,
    imageDialog,
    wangEditorTool,
  },
  data() {
    return {
      imgType: "",
      showColorPicker: false,
      dialogVisible: false,
      benefitArr: [],
      imageUrl: "",
      isShow: false,
      basicInfoForm: {
        name: "",
        ext_image: "",
        background: "orange",
        status: 1,
        is_growth_upgrade: 1,
        is_consumption_upgrade: 1,
        consumption: "",
        growth: "",
        image: "",
        desc: "",
        level: 1,
        right_uuid: [],
      },
      formData: '',
      formData2: '',
      customerGroupForm: {
        search_status: "",
        search_start_time: "",
        search_end_time: "",
        search_keyword: "",
        per_page: 9999,
        current_page: 1,
      },
      rules: {
        name: { required: true, message: "请输入昵称", trigger: "blur" },
        consumption: {
          required: true,
          message: "请输入消费金额",
          trigger: "blur",
        },
        growth: { required: true, message: "请输入成长值", trigger: "blur" },
      },
      selectType: "",
    };
  },
  created() {
    this.getBenefit();
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      this.getInfo();
    } else {
      this.pageType = "新增";
    }
  },
  methods: {
    closeImageDialog () {
      this.dialogVisible = false
    },
     // 上传图片
     httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.image =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    httpRequest2() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.ext_image =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/UserLevel/${this.$route.query.uuid}`,
      })
        .then((res) => {
          let params = res.data.result;
          for (let i in params) {
            this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
          }
          this.basicInfoForm.is_consumption_upgrade = Number(
            this.basicInfoForm.is_consumption_upgrade
          );
          this.basicInfoForm.is_growth_upgrade = Number(
            this.basicInfoForm.is_growth_upgrade
          );
          this.basicInfoForm.status = Number(this.basicInfoForm.status);
          this.basicInfoForm.right_uuid = this.basicInfoForm.right.map(
            (item) => item.uuid
          );
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    getBenefit() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/UserRight?search_status=1&page_size=${this.customerGroupForm.per_page}&page_index=${this.customerGroupForm.current_page}&search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}`,
      })
        .then((res) => {
          this.benefitArr = res.data.result.data;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    updateValue(v) {
      this.basicInfoForm.background = v.hex;
      this.showColorPicker = false;
    },
    // 选择banner
    chooseBanner() {
      this.imgType = "chooseThumbnail_image";
      this.selectType = "img1";
      this.dialogVisible = true;
    },
    chooseBanner2() {
      this.imgType = "chooseThumbnail_image";
      this.selectType = "img2";
      this.dialogVisible = true;
    },
    // 选择封面
    confirmImageDialog(item) {
      console.log(item)
      if (this.selectType == "img1") {
        this.basicInfoForm.image = item[0].url;
      } else {
        this.basicInfoForm.ext_image = item[0].url;
      }
      this.dialogVisible = false;
    },
    // 提交表单
    submitForm(form) {
      this.basicInfoForm.desc = this.$refs.editors.html;
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.basicInfoForm.image == "") {
            this.$message.error("请上传等级图标");
            return false;
          }
          if (this.basicInfoForm.consumption == "") {
            this.$message.error("请填写累计消费金额");
            return false;
          }
          if (this.basicInfoForm.growth == "") {
            this.$message.error("请填写成长值");
            return false;
          }

          if (Object.keys(this.$route.query).length !== 0) {
            delete this.basicInfoForm.create_time;
            delete this.basicInfoForm.delete_time;
            delete this.basicInfoForm.right;
            delete this.basicInfoForm.update_time;
            delete this.basicInfoForm.uuid;

            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://api.boyalife.net/v1/cms/UserLevel/${this.$route.query.uuid}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.success(err);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://api.boyalife.net/v1/cms/UserLevel`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.success(err);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.addMemberLevel {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  position: relative;
}
.level-bj {
  position: absolute;
  right: 30px;
  top: 40px;
  z-index: 1;
}
>>> .el-progress-bar__outer {
  margin: 0 20px;
}
.level-bj .picker {
  position: absolute;
  left: 0;
  top: 40px;
}
.level-bj .img {
  width: 347px;
  height: 184px;
  border: 1px solid rgb(163, 163, 163);
  border-radius: 8px;
  margin-top: 20px;
}
.first {
  width: 200px;
}
.colorPicker {
  width: 50px;
  height: 25px;
  padding: 5px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.colorPicker span {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.colorPicker span.bg {
  background: #fe9200;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 16px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form {
}
.el-form .el-input-group > .el-input__inner {
  width: 60px;
}
.el-form .el-checkbox-group {
  width: 600px;
}
.el-form .flex {
  flex-wrap: wrap;
}
.el-form .flex .flex {
  width: 40%;
  flex-wrap: nowrap;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
.upload-demo{
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
</style>
