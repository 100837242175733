<template>
  <div style="margin-bottom: 80px">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/smsSignature' }"
        >短信签名</el-breadcrumb-item
      >
      <el-breadcrumb-item>添加签名</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <div class="tips mg-b-20">
        <i class="el-icon-warning" style="color: #5b8fd9"></i>
        短信签名用于向阿里云申请，审核通过后即可新建短信模块
      </div>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="150px"
        :rules="rules"
      >
        <el-form-item label="签名名称" prop="SignName">
          <el-input
            v-model="basicInfoForm.SignName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="签名来源" prop="SignSource">
          <el-radio-group v-model="basicInfoForm.SignSource">
            <el-radio :label="0">企事业单位的全称或简称</el-radio>
            <el-radio :label="1">工信部备案网站的全称或简称</el-radio>
            <el-radio :label="2">APP应用的全称或简称</el-radio>
            <el-radio :label="3">公众号或小程序的全称或简称</el-radio>
            <el-radio :label="4">电商平台店铺的全称或简称</el-radio>
            <el-radio :label="5">商标名的全称或简称</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="签名类型" prop="SignType">
          <el-radio-group v-model="basicInfoForm.SignType">
            <el-radio :label="0">验证码</el-radio>
            <el-radio :label="1">通用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="证明文件" prop="fileList">
          <span slot="label"><span style="color: red">*</span>项目图片</span>
          <el-button
            v-if="fileList.length < 15"
            class="avatar-uploader"
            @click="chooseBanner('projectImg')"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-button>
          <div class="cover_image_list flex" v-if="fileList.length > 0">
            <draggable v-model="fileList" group="SignFileList">
              <div class="img" v-for="(item, index) in fileList" :key="index">
                <i class="el-icon-delete" @click.stop="deleteImg(index)"></i>
                <el-image :src="item.url" fit="cover"></el-image>
              </div>
            </draggable>
          </div>
          <p class="font-12 color-999">
            可上传多张，支持JPG或PNG格式,文件大小不超过2M
          </p>
        </el-form-item>
        <el-form-item label="申请说明" prop="Remark">
          <el-input
            type="textarea"
            rows="6"
            resize="none"
            v-model="basicInfoForm.Remark"
            placeholder="请简述短信签名使用场景，以便快速通过审核"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary :type="imgType" @selectImg="selectImg" />
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import MaterialLibrary from "../../../components/materialLibraryDialog/index";

export default {
  name: "addSignature",
  components: {
    MaterialLibrary,
  },
  data() {
    return {
      imgType: "",
      insertImgType: "",
      dialogVisible: false,
      fileList: [],
      basicInfoForm: {
        SignFileList: [],
        Remark: "",
        SignType: 0,
        SignSource: 0,
        SignName: "",
      },
      rules: {
        SignName: { required: true, message: "请输入", trigger: "blur" },
        SignSource: { required: true, message: "请选择", trigger: "change" },
        SignType: { required: true, message: "请选择", trigger: "change" },
        Remark: { required: true, message: "请输入", trigger: "blur" },
        SignFileList: { required: true, message: "请上传", trigger: "blur" },
      },
    };
  },
  methods: {
    // 选择banner
    chooseBanner(str) {
      this.insertImgType = str;
      this.title = "选择图片";
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
    },
    selectImg(item) {
      console.log(item);
      this.dialogVisible = false;
      if (this.insertImgType == "projectImg") {
        this.fileList.push(item);
      }
    },
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.fileList.length < 1) {
            this.$message.error("请上传证明文件");
            return false;
          }
          let obj = {};
          this.fileList.map((item) => {
            obj.FileContents = item.url;
            obj.FileSuffix = item.suffix;
            this.basicInfoForm.SignFileList.push(obj);
          });
          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.basicInfoForm,
            url: ` https://api.boyalife.net/v1/cms/SmsSign`,
          })
            .then((res) => {
              this.$message.success("报存成功！");
              this.$router.go(-1);
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
>>> .el-form .el-input,
>>> .el-form .el-textarea {
  width: 400px;
}
.el-radio {
  margin-bottom: 20px;
}
.tips {
  padding: 5px 10px;
  background: #e7f0ff;
  border: 1px solid #7db3f2;
  line-height: 35px;
  font-size: 12px;
}
.tips >>> .el-button span {
  font-size: 12px;
  margin-left: 10px;
  display: inline-block;
}
.cover_image_list .img {
  position: relative;
  border: 1px solid #dcdcdc;
  width: 220px;
  height: 220px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
}
.cover_image_list .img .el-icon-delete {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  z-index: 2;
  cursor: pointer;
}
.cover_image_list .img .el-image {
  width: 100%;
  height: 100%;
}
.avatar-uploader {
  position: relative;
}
.avatar-uploader .el-icon-delete,
.video .el-icon-delete {
  font-size: 20px;
  position: absolute;
  right: -40px;
  bottom: 0px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
