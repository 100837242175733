<template>
  <div
    class="cellList"
    v-loading="isLoad"
    element-loading-text="请稍后.."
    element-loading-spinner="el-icon-loading"
  >
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>细胞</el-breadcrumb-item>
      <el-breadcrumb-item>细胞管理</el-breadcrumb-item>
      <el-breadcrumb-item>全部细胞</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="项目类型">
          <el-select
            v-model="customerGroupForm.search_type"
            placeholder="请选择"
          >
            <el-option label="存储项目" :value="1"></el-option>
            <el-option label="技术服务" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期搜索">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            class="mg-l-20"
            style="width: 300px"
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="tabs flex">
        <p
          v-for="(item, index) in tabs"
          :key="index"
          @click="addClass(index)"
          :class="{ on: currentClass === index }"
        >
          {{ item }}
        </p>
      </div>
      <div class="text-right mg-b-20">
        <el-button
          type="primary"
          @click="addRemark('batch')"
          :disabled="uuids.length == 0"
          >批量备注</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="项目信息" :align="'center'" width="500">
          <template slot-scope="scope">
            <div class="product flex space-between">
              <div class="flex" style="width: 350px">
                <el-image
                  v-if="scope.row.item != null"
                  :src="scope.row.item.cover"
                ></el-image>
                <div style="width: 80%" class="text-left">
                  <p class="font-12">
                    {{ scope.row.item_name }}
                  </p>
                </div>
              </div>
              <div class="font-12 mg-l-20" style="width: 150px">
                <p>{{ scope.row.item_price }}</p>
                <p>× {{ scope.row.item_number }}</p>
              </div>
            </div>
            <div class="orderInfo">
              <div class="flex space-between align-center">
                <div class="flex">
                  <p class="mg-r-10 font-12">
                    下单时间: {{ scope.row.create_time }}
                  </p>
                  <p class="font-12">订单编号：{{ scope.row.order_sn }}</p>
                </div>
                <div v-if="currentClass === 0">
                  <p v-if="scope.row.contract != null" class="font-12">
                    合同编号：{{ scope.row.contract.code }}
                    <span class="copy" @click="onCopy(scope.row)">复制</span>
                  </p>
                </div>
                <div v-else>
                  <p class="font-12">
                    共1件商品，合计:
                    {{ scope.row.item_price * scope.row.item_number }}（含运费:
                    ¥0.00 ）
                  </p>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="预约人信息"
          v-if="currentClass === 0"
          width="170px"
        >
          <template slot-scope="scope">
            <p>买家：{{ scope.row.appoint.name }}</p>
            <p>手机号：{{ scope.row.appoint.mobile }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="appoint"
          label="收货信息"
          :align="'center'"
          v-if="currentClass === 1"
        >
          <template slot-scope="scope">
            <p>买家：{{ scope.row.appoint.name }}</p>
            <p>手机号：{{ scope.row.appoint.mobile }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" :align="'center'">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">预约中</span>
            <span v-else-if="scope.row.status == 2">待签约</span>
            <span v-else-if="scope.row.status == 3">已签约</span>
            <span v-else-if="scope.row.status == 4">待缴费</span>
            <span v-else-if="scope.row.status == 5">已完成</span>
            <span v-else-if="scope.row.status == 6">已终止</span>
            <span v-else>已关闭</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="item.category.name"
          label="项目类型"
          :align="'center'"
        >
        </el-table-column>

        <el-table-column
          label="操作"
          :align="'center'"
          width="220"
          fixed="right"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                @click="$router.push('/cellInfo?uuid=' + scope.row.uuid)"
                type="text"
                size="small"
                >细胞详情</el-button
              >
              <el-button
                v-if="
                  scope.row.contract != null &&
                  scope.row.contract.all_pdf_url != ''
                "
                @click="getContract(scope.row.uuid)"
                type="text"
                size="small"
                >合同档案</el-button
              >

              <!-- <el-button type="text" >合同档案</el-button> -->
              <el-button
                type="text"
                v-if="
                  scope.row.contract != null &&
                  scope.row.status != 7 &&
                  scope.row.contract.all_pdf_url != ''
                "
                @click="
                  $router.push('/paymentRecords?uuid=' + scope.row.fee.uuid)
                "
                >缴费记录</el-button
              >
              <el-button
                type="text"
                v-if="
                  scope.row.contract != null &&
                  scope.row.status != 7 &&
                  scope.row.contract.all_pdf_url != ''
                "
                @click="
                  $router.push('/insuranceRecords?uuid=' + scope.row.order_sn)
                "
                >保险记录</el-button
              >
              <!-- <el-button type="text" @click="$router.push('/addProjectProcess?uuid=' + scope.row.uuid)">样本进度</el-button> -->
              <el-button
                type="text"
                v-if="scope.row.has_report != 0"
                @click="
                  $router.push(
                    '/reportList?uuid=' +
                      scope.row.order_sn +
                      '&order_uuid=' +
                      scope.row.uuid
                  )
                "
                >报告列表</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.status != 7"
                @click="addRemark('single', scope.row)"
                >备注</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.status != 7"
                @click="closeAppoint(scope.row)"
                >关闭细胞</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog title="关闭细胞" :visible.sync="closeDialog" width="30%">
      <el-form ref="iform" :model="form" label-width="100px" :rules="rules2">
        <el-form-item label="关闭原因" prop="close_cause">
          <el-select v-model="form.close_cause" placeholder="请选择">
            <el-option label="客户取消" value="客户取消"></el-option>
            <el-option label="联系不上" value="联系不上"></el-option>
            <el-option label="价格过高" value="价格过高"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理人" prop="close_user">
          <el-input v-model="form.close_user"></el-input>
        </el-form-item>
        <el-form-item label="补充说明">
          <el-input
            type="textarea"
            resize="none"
            rows="5"
            v-model="form.close_ext"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="closeDialog = false"
          >关闭</el-button
        >
        <el-button size="mini" type="primary" @click="closeItemAppoint('iform')"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "cellList",
  components: {
    pagination,
  },
  data() {
    return {
      uuid: "",
      tabs: ["全部", "待签约", "待缴费", "已完成", "已终止", " 已关闭"],
      closeDialog: false,
      customerGroupForm: {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
        search_status: "",
        search_type: "",
      },
      isLoad: false,
      uuids: [],
      dialogVisible: false,
      value: 5,
      currentClass: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      form: {
        close_cause: "",
        close_user: "",
        close_ext: "",
      },
      rules2: {
        close_cause: { required: true, message: "请选择原因", blur: "change" },
        close_user: { required: true, message: "请填写", blur: "blur" },
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    if (this.$route.query.search_status) {
      let n = this.$route.query.search_status;
      if (n == 2) {
        this.currentClass = Number(this.$route.query.search_status - 1);
      } else if (n == 4) {
        this.currentClass = Number(this.$route.query.search_status - 2);
      }
      this.customerGroupForm.search_status = Number(
        this.$route.query.search_status
      );
    }
    this.getList();
  },
  methods: {
    closeAppoint(row) {
      this.closeDialog = true;
      this.uuid = row.appoint.uuid;
    },
    // 关闭预约
    closeItemAppoint(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.form,
            url: ` https://api.boyalife.net/v1/cms/ItemAppoint/close/${this.uuid}`,
          })
            .then((res) => {
              this.$message.success("操作成功");
              this.getList();
              this.closeDialog = false;
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
    //  获取合同
    getContract(uuid) {
      this.isLoad = true;
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/ItemOrder/contract/${uuid}`,
      })
        .then((res) => {
          this.all_pdf_url = res.data.result.allPdfUrl;
          console.log(this.all_pdf_url);
          // <a v-if="scope.row.status != 7 && scope.row.contract.all_pdf_url != ''" style="display: inline-block" class="font-12 mg-r-10" :href="scope.row.contract.all_pdf_url" target="_blank">
          //           合同档案
          //         </a>
          const link = document.createElement("a");
          link.href = this.all_pdf_url;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.isLoad = false;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    //勾选
    handleSelectionChange(val) {
      // this.selectTable = val;
      this.uuids = val.map((item) => item.uuid);
    },

    //  添加备注
    addRemark(str, row) {
      let html = "";
      let uids = "";
      if (str == "single") {
        html = "添加备注";
        uids = row.uuid;
      } else {
        html = "批量备注";
        uids = this.uuids.join(",");
      }
      this.$prompt("备注信息", html, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPlaceholder: "请输入",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入备注",
      })
        .then(({ value }) => {
          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: {
              remark: value,
              uuid: uids,
            },
            url: ` https://api.boyalife.net/v1/cms/ItemAppoint/remark`,
          })
            .then((res) => {
              this.$message.success("操作成功");
              this.getList();
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    addClass(index) {
      this.currentClass = index;
      if (index == 0) {
        this.customerGroupForm.search_status = "";
      } else if (index == 1) {
        this.customerGroupForm.search_status = 2;
      } else if (index == 2) {
        this.customerGroupForm.search_status = 4;
      } else if (index == 3) {
        this.customerGroupForm.search_status = 5;
      } else if (index == 4) {
        this.customerGroupForm.search_status = 6;
      } else if (index == 5) {
        this.customerGroupForm.search_status = 7;
      }
      this.getList();
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    onCopy(row) {
      this.$copyText(row.contract.code).then(
        (e) => {
          this.$message.success("复制成功!");
        },
        (e) => {
          this.$message.success("复制失败!");
        }
      );
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/ItemOrder?search_status=${this.customerGroupForm.search_status}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_type=${this.customerGroupForm.search_type}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    deleteEvaluate() {
      this.$confirm("确定删除该评价吗?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "成功删除!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.cellList .el-table .product .el-image {
  width: 100px;
  height: auto;
  border-radius: 0 !important;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.img img {
  width: 104px;
  height: 86px;
  border-radius: 8px;
}
>>> .el-table tr {
  position: relative;
  width: 100%;
}
>>> .el-table .el-table__cell {
  position: static;
}
>>> .el-table td .cell {
  padding-top: 40px;
}
.el-table .el-button + .el-button {
  margin-left: 0;
}
.el-table .el-button {
  margin-right: 10px;
}
.orderInfo {
  width: 100%;
  background: #f6f9ff;
  padding: 5px 20px;
  box-sizing: content-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
.copy {
  display: inline-block;
  padding: 2px 5px;
  margin-left: 20px;
  border-radius: 4px;
  border: 1px solid #666;
  color: #666;
  font-size: 12px;
}
</style>
