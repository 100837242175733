<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>推广</el-breadcrumb-item>
      <el-breadcrumb-item>推广管理</el-breadcrumb-item>
      <el-breadcrumb-item>推广审核</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询 -->
    <div class="search-container">
      <div class="search-box">
        <el-form :inline="true" :model="customerForm" class="demo-form-inline">
          <el-form-item label="审核状态">
            <el-select
              v-model="customerForm.search_status"
              placeholder="请选择"
            >
              <el-option label="通过" :value="2"></el-option>
              <el-option label="不通过" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="申请日期">
            <el-date-picker
              v-model="customerForm.dataTime"
              type="daterange"
              align="right"
              unlink-panels
              @change="getTimes"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="关键字搜索" class="search_keyword">
            <el-input
              v-model="customerForm.search_keyword"
              placeholder="请输入"
              class="input-with-select"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium" @click="exportExcel">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="create_time"
          label="申请时间"
          :align="'center'"
          width="160"
        >
        </el-table-column>
        <el-table-column label="申请人信息" :align="'center'" width="170">
          <template slot-scope="scope">
            <router-link :to="'/customerInfo?uuid=' + scope.row.user.uuid">
              <div class="nickAndAvatar" v-if="scope.row.user != null">
                <el-image :src="scope.row.user.avatar"></el-image>
                <div>
                  <p>
                    <span>{{ scope.row.user.nick_name }}</span> <br />
                  </p>
                  <p>
                    <span>{{ scope.row.mobile }}</span> <br />
                  </p>
                </div>
              </div>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="style"
          label="收款方式"
          :align="'center'"
          width="110"
        >
          <template slot-scope="scope">
            <p>微信钱包</p>
            <!-- <p v-if="scope.row.card_number.length > 6 && scope.row.card_number">({{ scope.row.card_number.splice(-1,4) }})</p> -->
          </template>
        </el-table-column>
        <el-table-column prop="payment_bank" label="收款银行" :align="'center'">
        </el-table-column>
        <el-table-column prop="mobile" label="预留手机" :align="'center'">
        </el-table-column>
        <el-table-column
          prop="label"
          label="审核状态"
          :align="'center'"
          width="110"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 1">待审核</el-tag>
            <el-tag type="success" v-if="scope.row.status == 2"
              >审核通过</el-tag
            >
            <el-tag type="error" v-if="scope.row.status == 3"
              >审核不通过</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="card_number"
          label="银行卡号"
          :align="'center'"
          width="180"
        >
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="160px">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.status == 1"
              size="small"
              @click="pass(scope.row)"
              >通过</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status == 1"
              size="small"
              @click="refuse(scope.row)"
              >拒绝</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "promoterExamine",
  components: {
    pagination,
  },
  data() {
    return {
      customerForm: {
        search_status: "",
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
      },
      tem: {},
      props: { multiple: true },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    clear() {
      for (let i in this.customerForm) {
        this.customerForm[i] = "";
      }
      this.getList();
    },
    // 选择时间
    getTimes(v) {
      this.customerForm.search_start_time = v[0] + " 00:00:00";
      this.customerForm.search_end_time = v[1] + " 23:59:59";
    },
    // 导出
    exportExcel() {
      this.axios({
        method: "post", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/ExtendApply/export?page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}&search_status=${this.customerForm.search_status}&search_keyword=${this.customerForm.search_keyword}&search_start_time=${this.customerForm.search_start_time}&search_end_time=${this.customerForm.search_end_time}`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let currentTime = new Date();
          let year = currentTime.getFullYear();
          let month = currentTime.getMonth() + 1;
          let day = currentTime.getDate();
          let formattedDate = `${year}/${month
            .toString()
            .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

          link.download = `推广员审核列表 - ${formattedDate}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 列表
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/ExtendApply?page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}&search_status=${this.customerForm.search_status}&search_keyword=${this.customerForm.search_keyword}&search_start_time=${this.customerForm.search_start_time}&search_end_time=${this.customerForm.search_end_time}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    pass(row) {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/ExtendApply/read?uuid=${row.uuid}`,
      })
        .then((res) => {
          this.tem = res.data.result;
          let html = `
          <h5 class="mg-l-30 mg-b-10">姓名: ${this.tem.name}</h5>
          <h5 class="mg-l-30 mg-b-10">银行卡号：${this.tem.card_number}</h5>
          <h5 class="mg-l-30 mg-b-10">所属银行：${this.tem.payment_bank}</h5>
          <h5 class="mg-l-30 mg-b-10">预留手机号: ${this.tem.mobile}</h5>
          <h5 class="mg-l-30 mg-b-10">提现方式：微信钱包</h5>
        `;
          this.$confirm(html, "确认通过", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            dangerouslyUseHTMLString: true,
          }).then(() => {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "text/html;charset=UTF-8",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              url: ` https://api.boyalife.net/v1/cms/ExtendApply/confirm/${row.uuid}`,
            })
              .then((res) => {
                this.$message.success("操作成功!");
                this.getList();
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          });
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    refuse(row) {
      this.$prompt("请输入拒绝的理由", "拒绝审核", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        inputPlaceholder: "请输入拒绝的理由",
        inputType: "textarea",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "理由不能为空",
      })
        .then(({ value }) => {
          this.$message({
            type: "success",
            message: "你拒绝通过的理由是: " + value,
          });
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "text/html;charset=UTF-8",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://api.boyalife.net/v1/cms/ExtendApply/refuse/${row.uuid}`,
          })
            .then((res) => {
              this.$message.success("操作成功!");
              this.getList();
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    rangeNumber(val) {
      console.log(val);
    },
    orderNumber(val) {
      console.log(val);
    },
    // 表格颜色设置
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      } else {
        return "";
      }
    },
    closeDialog(v) {
      this.dialogTableVisible = v;
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
.search-container .el-input-group__append {
  background: none;
  cursor: pointer;
}
.search-container .keyword .el-input__inner {
  border-right: none !important;
}
.search-container .el-form--inline .el-form-item__label {
  color: #999;
}
.el-table .warning-row {
  background: #f4fcff;
}
.el-table .el-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 5px;
}
.el-table .nickAndAvatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
}
</style>
