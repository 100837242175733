<template>
  <div class="customerAnalysis" style="background: #f0f2f5">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>推广</el-breadcrumb-item>
      <el-breadcrumb-item>推广管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/promoterList' }"
        >推广员列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>推广数据</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 商城订单 项目订单 快捷操作 会员数据概况 -->
    <div class="topSquare boxLayout" v-if="Object.keys(info).length > 0">
      <h3 class="title-t mg-b-30">推广数据</h3>
      <div class="analysis flex space-around">
        <div class="text-center">
          <h6 class="font-12 color-999">客户总数量</h6>
          <p class="font-30">{{ info.user_num }}</p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">下级总数量</h6>
          <p class="font-30">{{ info.extend_num }}</p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">推广佣金</h6>
          <p class="font-30">{{ info.tgyj }}</p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">下级佣金</h6>
          <p class="font-30"><span class="font-12">￥</span> {{ info.xjyj }}</p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">提现金额</h6>
          <p class="font-30"><span class="font-12">￥</span>{{ info.txje }}</p>
        </div>
      </div>
      <el-table
        :data="tableData.data"
        class="mg-t-20"
        style="width: 100%"
        border=""
      >
        <el-table-column
          prop="create_date"
          label="日期"
          sortable
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="fx1_num"
          label="推广订单数"
          sortable
          align="center"
        >
        </el-table-column>
        <el-table-column prop="fxje1" label="推广佣金" align="center">
        </el-table-column>
        <el-table-column prop="fx2_num" label="下级订单" align="center">
        </el-table-column>
        <el-table-column prop="fxje2" label="下级订单佣金数" align="center">
        </el-table-column>
        <el-table-column prop="txje" label="提现金额" align="center">
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <div class="operateBtn">
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";

export default {
  name: "",
  components: {
    pagination,
  },
  data() {
    return {
      info: {},
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getTotalData();
    this.getData();
  },
  methods: {
    getTotalData() {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          extend_uuid: this.$route.query.uuid,
        },
        url: ` https://api.boyalife.net/v1/cms//Extend/getGeneralizationTotal`,
      })
        .then((res) => {
          this.info = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getData() {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          extend_uuid: this.$route.query.uuid,
        },
        url: ` https://api.boyalife.net/v1/cms//Extend/getGeneralizationDetail?page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
