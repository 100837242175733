<template>
  <div class="wordList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/sensitiveWordsList' }"
        >敏感词库</el-breadcrumb-item
      >
      <el-breadcrumb-item>敏感词列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="dataTable">
      <div class="flex space-between">
        <el-form
          :inline="true"
          :model="customerGroupForm"
          class="demo-form-inline"
        >
          <el-form-item class="keyword">
            <el-input
              placeholder="请输入要搜索的内容"
              v-model="customerGroupForm.search_keyword"
              @input="getSensitiveWord"
            >
              <i
                slot="append"
                class="el-icon-search"
                style="cursor: pointer"
                @click="getSensitiveWord"
              ></i>
            </el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="primary" @click="add">新建</el-button>
          <el-button type="primary" :disabled="isSelected" @click="batchDel"
            >批量删除</el-button
          >
          <el-upload
            class="upload-demo mg-l-10"
            action="#"
            accept=".xlsx"
            :show-file-list="false"
            :http-request="httpRequest"
            :before-upload="beforeUpload"
          >
            <el-button type="primary">批量导入敏感词</el-button>
          </el-upload>
          <a
            href="https://api.boyalife.net/excel/%E6%95%8F%E6%84%9F%E8%AF%8D%E5%AF%BC%E5%85%A5.xlsx"
            style="color: #409eff; font-size: 12px"
            class="mg-l-20"
            >下载导入模板</a
          >
        </div>
      </div>

      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%; height: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          label="序号"
          :align="'center'"
          width="55px"
          type="index"
        >
        </el-table-column>
        <el-table-column
          label="敏感词"
          :align="'center'"
          prop="name"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          prop="triggers_number"
          label="触发次数"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column
          prop="triggers_post_number"
          label="触发贴子数"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column prop="admin.name" label="创建人" :align="'center'">
        </el-table-column>
        <el-table-column
          prop="update_time"
          label="最后修改时间"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          fixed="right"
          width="250"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" @click="delSensitiveWord(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog
      title="添加敏感词"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-input
        v-model.trim="name"
        type="textarea"
        resize="none"
        rows="8"
        placeholder="请输⼊敏感词，不同敏感词之间⽤逗号间隔"
      ></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancalAdd">取 消</el-button>
        <el-button type="primary" @click="addSensitiveWord">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
import checkInfoDialog from "../../../components/checkInfoDialog.vue";
export default {
  name: "wordList",
  components: {
    checkInfoDialog,
    pagination,
  },
  data() {
    return {
      isSelected: true,
      uuids: [],
      name: "",
      uuid: "",
      editType: "",
      customerGroupForm: {
        search_keyword: "",
      },
      dialogFormVisible: false,
      labelOptions: [
        {
          label: "启用",
          value: "ok",
        },
        {
          label: "禁用",
          value: "no",
        },
      ],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
      },
    };
  },
  created() {
    this.customerGroupForm.group_uuid = this.$route.query.group_uuid;
    this.getSensitiveWord();
  },
  methods: {
    beforeUpload(file) {
      console.log(file);
      this.load = true;
      this.$message.info("稍等片刻，正在上传...");
      if (file.name.split(".")[1].toLowerCase() != "xlsx") {
        this.$message.error("文件格式错误~");
        return false; // 终止上传
      }
      this.formData = new FormData();
      this.formData.append("file", file);
    },
    httpRequest(data) {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.formData,
        url: ` https://api.boyalife.net/v1/cms/SensitiveWord/import`,
      })
        .then((res) => {
          this.$message.success("保存成功！");
          this.getSensitiveWord();
          this.load = false;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    handleSelectionChange(row) {
      if (row.length > 0) {
        this.isSelected = false;
        this.uuids = row.map((item) => item.uuid);
      }
    },
    closeDialog(v) {
      this.dialogFormVisible = v;
    },
    add() {
      this.editType = "add";
      this.dialogFormVisible = true;
      this.name = "";
    },
    batchDel() {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: {
              uuids: this.uuids,
            },
            url: ` https://api.boyalife.net/v1/cms/SensitiveWord/delete_branch`,
          })
            .then((res) => {
              this.$message.success("删除成功！");
              this.getSensitiveWord();
            })
            .catch((err) => {
              this.$message.success(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    cancalAdd() {
      this.dialogFormVisible = false;
      this.name = "";
    },
    edit(row) {
      this.editType = "";
      this.dialogFormVisible = true;
      this.name = row.name;
      this.uuid = row.uuid;
    },
    // 列表
    getSensitiveWord() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/SensitiveWord?page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.customerGroupForm.search_keyword}&group_uuid=${this.$route.query.uuid}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 新建
    addSensitiveWord() {
      if (this.name == "") {
        this.$message.warning("敏感词不能为空！");
        return false;
      }
      if (this.editType === "add") {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            name: this.name,
            group_uuid: this.$route.query.uuid,
          },
          url: ` https://api.boyalife.net/v1/cms/SensitiveWord`,
        })
          .then((res) => {
            this.$message.success("新建成功！");
            this.dialogFormVisible = false;

            this.getSensitiveWord();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      } else {
        this.axios({
          method: "put", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            name: this.name,
            group_uuid: this.$route.query.uuid,
          },
          url: ` https://api.boyalife.net/v1/cms/SensitiveWord/${this.uuid}`,
        })
          .then((res) => {
            this.$message.success("编辑成功！");
            this.dialogFormVisible = false;
            this.getSensitiveWord();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      }
    },
    // 删除
    delSensitiveWord(row) {
      this.uuid = row.uuid;
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://api.boyalife.net/v1/cms/SensitiveWord/${this.uuid}`,
          })
            .then((res) => {
              this.$message.success("删除成功！");
              this.getSensitiveWord();
            })
            .catch((err) => {
              this.$message.success(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getSensitiveWord();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getSensitiveWord();
    },
  },
};
</script>
<style scoped>
.wordList {
  background: #f2f2f2;
}
.dataTable {
  margin-top: 0;
}

>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
.upload-demo {
  display: inline-block;
}
</style>
