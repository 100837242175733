<template>
  <div class="laboratoryList">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>实验室</el-breadcrumb-item>
      <el-breadcrumb-item>实验室管理</el-breadcrumb-item>
      <el-breadcrumb-item>实验室列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form :inline="true" :model="listForm" class="demo-form-inline">
        <el-form-item label="创建日期">
          <el-date-picker
            v-model="dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="实验室区域">
          <el-cascader
            v-model="areaValue"
            :options="cityList"
            @change="getCityValue"
            :props="props"
            collapse-tags
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="实验室状态">
          <el-select v-model="listForm.search_status" placeholder="请选择">
            <el-option
              v-for="(jtem, i) in selectList"
              :key="i"
              :label="jtem.val"
              :value="jtem.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字" class="search_keyword">
          <el-input
            placeholder="请输入实验室名称"
            v-model="listForm.search_keyword"
            class="input-with-select"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="text-right mg-b-20">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="$router.push('/addLaboratory')"
          >添加实验室</el-button
        >
      </div>
      <el-table
        class="mg-t-20"
        :data="tableData.data"
        border
        style="width: 100%"
      >
        <el-table-column prop="name" label="实验室名称" align="center">
        </el-table-column>
        <el-table-column prop="address" label="所在区域" align="center">
        </el-table-column>
        <el-table-column prop="type" label="实验室类型" align="center">
          <template slot-scope="scope">
            {{ scope.row.type == "1" ? "样品处理间" : "样品储藏间" }}
          </template>
        </el-table-column>
        <el-table-column prop="temperature" label="温度" align="center">
        </el-table-column>
        <el-table-column prop="humidity" label="湿度" align="center">
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template slot-scope="scope">
            <!-- <span :class="{ 'yes-color': scope.row.status == '1', 'no-color': scope.row.status == '2' }">{{
              scope.row.status ==
              '1' ? '开启' : '关闭' }}</span> -->
            <el-link
              type="success"
              v-if="scope.row.status == '1'"
              @click="setStatus(scope.row.id, '2')"
              >开启</el-link
            >
            <el-link type="danger" v-else @click="setStatus(scope.row.id, '1')"
              >关闭</el-link
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          align="center"
          sortable
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="180">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="$router.push('/addLaboratory?uuid=' + scope.row.id)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="$router.push('/authentication?uuid=' + scope.row.id)"
              >认证</el-button
            >
            <el-button type="text" size="small" @click="deleted(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../components/pagination.vue";

export default {
  name: "laboratoryList",
  data() {
    return {
      dataTime: "",
      listForm: {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
        search_province: "",
        search_city: "",
        search_area: "",
        search_status: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: "",
      },
      //状态
      selectList: [
        { id: "1", val: "开启" },
        { id: "2", val: "关闭" },
      ],
      //地区
      cityList: [],
      props: {
        label: "area_name",
        value: "area_id",
      },
      areaValue: "",
    };
  },
  components: {
    pagination,
  },
  created() {
    this.getCity();
    this.getList();
  },
  methods: {
    clear() {
      this.dataTime = "";
      this.areaValue = "";
      this.listForm.search_keyword = "";
      this.listForm.search_start_time = "";
      this.listForm.search_end_time = "";
      this.listForm.search_province = "";
      this.listForm.search_city = "";
      this.listForm.search_area = "";
      this.listForm.search_status = "";
      this.getList();
    },
    // 选择时间
    getTimes(v) {
      this.listForm.search_start_time = v[0] + " 00:00:00";
      this.listForm.search_end_time = v[1] + " 23:59:59";
    },
    //选择地区
    getCityValue(v) {
      this.listForm.search_province = v[0];
      this.listForm.search_city = v[1];
      this.listForm.search_area = v[2];
    },
    // 获取地区
    getCity() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Region`,
      })
        .then((res) => {
          this.cityList = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Lab?page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.listForm.search_keyword}&search_start_time=${this.listForm.search_start_time}&search_end_time=${this.listForm.search_end_time}&search_status=${this.listForm.search_status}&search_province=${this.listForm.search_province}&search_city=${this.listForm.search_city}&search_area=${this.listForm.search_area}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    setStatus(id, status) {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: { status: status },
        url: ` https://api.boyalife.net/v1/cms/Lab/status/${id}`,
      })
        .then((res) => {
          this.$message.success("修改成功！");
          this.getList();
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    deleted(uuid) {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://api.boyalife.net/v1/cms/Lab/${uuid}`,
          })
            .then(() => {
              this.$message.success("删除成功！");
              this.getList();
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.laboratoryList {
  background-color: #f2f2f2;
}

.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}

.yes-color {
  color: #67c23a;
}

.no-color {
  color: #f56c6c;
}
</style>
