<template>
  <div class="productReport">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>数据</el-breadcrumb-item>
      <el-breadcrumb-item>商品分析</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="商品分类">
          <el-select
            class="mg-l-10 mg-r-10"
            v-model="customerGroupForm.category_uuid"
            placeholder="请选择"
            clearable
          >
            <el-option-group
              v-for="group in categoryOptions"
              :key="group.uuid"
              :label="group.name"
              :value="group.uuid"
            >
              <el-option
                v-for="item in group.children"
                :key="item.uuid"
                :label="item.name"
                :value="item.uuid"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="价格区间">
          <rangeInput
            @input="rangeNumber"
            :placeholderMin="'最低金额'"
            :placeholderMax="'最高金额'"
          />
        </el-form-item>
        <el-form-item label="日期筛选">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入商品分类名称"
            v-model="customerGroupForm.keywords"
          >
            <i slot="append" class="el-icon-search"></i>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clearForm">清空</el-button>
        <el-button size="medium" @click="goodsStatementExport">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
        @sort-change="visitorSortChange"
      >
        <el-table-column label="排名" type="index" :align="'center'" width="55">
        </el-table-column>
        <el-table-column
          label="商品描述"
          :align="'center'"
          prop="nickName"
          width="300"
        >
          <template slot-scope="scope">
            <div class="nickAndAvatar">
              <el-image
                v-if="scope.row.avatar"
                :src="scope.row.avatar"
              ></el-image>
              <div style="width: 200px">
                <el-tooltip :content="scope.row.name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="visitor_number"
          label="访问量"
          :align="'center'"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="goods_cart_number"
          label="加购量"
          :align="'center'"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="pay_user"
          label="支付人数"
          :align="'center'"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="pay_number"
          label="支付订单数"
          :align="'center'"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="pay_amount"
          label="支付金额"
          :align="'center'"
          sortable
        >
          <template slot-scope="scope"> ￥{{ scope.row.pay_amount }} </template>
        </el-table-column>
        <el-table-column
          prop="goods_sales_number"
          label="销量"
          :align="'center'"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="avg_visitor_pay"
          label="访问-支付转化率"
          :align="'center'"
          sortable
          width="150"
        >
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
import rangeInput from "../../../components/rangeInput.vue";
export default {
  name: "productReport",
  components: { rangeInput, pagination },
  data() {
    return {
      customerGroupForm: {
        start_time: "",
        end_time: "",
        category_uuid: "",
        min_price: "",
        max_price: "",
        keywords: "",
        sort_key: "",
        sort_value: "",
      },
      categoryOptions: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
    this.getCategoryData();
  },
  methods: {
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.start_time = v[0] + " 00:00:00";
      this.customerGroupForm.end_time = v[1] + " 23:59:59";
    },
    clearForm() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    visitorSortChange(column) {
      if (column.order == "descending") {
        this.customerGroupForm.sort_value = "desc";
      } else {
        this.customerGroupForm.sort_value = "asc";
      }
      this.customerGroupForm.sort_key = column.prop;
      this.getList();
    },
    // 获取商品分类
    getCategoryData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/GoodsCategoryTree`,
      })
        .then((res) => {
          this.categoryOptions = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Data/goodsStatement?start_time=${this.customerGroupForm.start_time}&end_time=${this.customerGroupForm.end_time}&category_uuid=${this.customerGroupForm.category_uuid}&min_price=${this.customerGroupForm.min_price}&max_price=${this.customerGroupForm.max_price}&keywords=${this.customerGroupForm.keywords}&sort_key=${this.customerGroupForm.sort_key}&sort_value=${this.customerGroupForm.sort_value}&current_page=${this.tableData.current_page}&per_page=${this.tableData.per_page}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },

    rangeNumber(val) {
      this.customerGroupForm.min_price = Number(val.min);
      this.customerGroupForm.max_price = Number(val.max);
    },

    handleSizeChange(v) {
      this.tableData.per_page = Number(v);
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = Number(v);
      this.getList();
    },
    // 商品报表导出
    goodsStatementExport() {
      this.axios({
        method: "get", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Data/goodsStatementExport?start_time=${this.customerGroupForm.start_time}&end_time=${this.customerGroupForm.end_time}&category_uuid=${this.customerGroupForm.category_uuid}&min_price=${this.customerGroupForm.min_price}&max_price=${this.customerGroupForm.max_price}&keywords=${this.customerGroupForm.keywords}&sort_key=${this.customerGroupForm.sort_key}&sort_value=${this.customerGroupForm.sort_value}`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          console.log(link);
          link.download = "商品报表导出数据.xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.el-table .el-image {
  border-radius: 0;
}
</style>
