<template>
  <div class="login-box">
     <div class="login-text text-center mg-b-10">
        <div class="logo"></div>
        <p class="font-22"> 登 录</p>
        <span class="color-999 font-12">欢迎登录博雅时光机后台管理系统</span>
     </div>
     <el-form
        :model="loginForm"
        ref="loginFormRef"
        :rules="rules"
     >
        <el-form-item prop="account">
           <el-input
           v-model="loginForm.account"
           prefix-icon="el-icon-s-custom"
           placeholder="请输入登录账户"
           @input="change($event)"
           ></el-input>
        </el-form-item>
        <el-form-item prop="password">
           <el-input
           v-model="loginForm.password"
           prefix-icon="el-icon-lock"
           placeholder="请输入密码"
           @input="change($event)"
           type="password"
           ></el-input>
        </el-form-item>
        <el-form-item prop="identifyCode" class="canvasBox">
           <el-input 
              type="text" 
              v-model="loginForm.identifyCode" 
              placeholder="请输入验证码" 
              autocomplete="off"
              prefix-icon="el-icon-lock">
           </el-input>
           <div class="canvas">
           <canvasCode :setCode="setCode" ></canvasCode>
           </div>
        </el-form-item>
        <!-- <el-checkbox v-model="loginForm.remeberStatus" class="checkRemeber">记住登录状态</el-checkbox> -->
        <el-form-item class="btns">
           <el-button
           type="primary"
           @click="toLogin"
           >登录</el-button>
        </el-form-item>
        <p class="font-12 color-999 text-center">忘记密码请联系管理员</p>
     </el-form>
   </div>
</template>
<script>
import canvasCode from './components/canvasCode.vue'
export default {
  name: 'login',
  components: {
   canvasCode
 },
 created () {
 },
  data () {
     var validateCode = (rule, value, callback) => {
       if (value === '') {
         callback(new Error('请输入验证码'));
       } else {
         if (this.loginForm.identifyCode.toLowerCase() == this.canvasCode.toLowerCase()) {
           this.$refs.loginFormRef.validateField();
         } else {
           callback(new Error('验证码错误，请重新输入'));
         }
         callback();
       }
     };
     return {
        loginForm: {
           account: '',
           password: '',
           identifyCode: ''
        },
        url: '',
        rules: {
           account: [
              { required: true, message: '账号不能为空', trigger: 'blur' },
           ],
           password: [
              { required: true, message: '密码不能为空', trigger: 'blur' },
           ],
           identifyCode: [
              { validator: validateCode, trigger: 'blur' }
           ]
        },
        // 输入的图形验证码
        identifyCode: '',
     }
  },
  methods: {
     setCode(){
        const words = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz'
        let code = ''
        for(let i=0; i<4; i++){ 
        code += words[Math.floor( Math.random()*52)]
        }
        this.canvasCode = code
        return code           
     },
     change () {},
     toLogin () {
        this.$refs['loginFormRef'].validate((valid) => {
         if (valid) {
           this.$store.dispatch('auth/doLogin',{
              account: this.loginForm.account,
              password: this.loginForm.password
           }).then(()=>{
              console.log(this.storage.get('__token'))
              this.axios({
                 method: 'get',//请求方法
                 headers: {
                    "Content-Type": "text/html;charset=UTF-8",
                    "X-Access-Token": this.storage.get('__token'),
                    "Authorization": `Bearer ${this.storage.get('__token')}`
                 },
                 url: `https://api.boyalife.net/v1/cms/AdminAuthMenu`,
                 }).then(res => {
                    this.storage.set('__menu',)
                    this.url =res.data.result[0].menu_url
                    this.$message.success('登录成功！',0.7)

                 }).then(()=>{
                    setTimeout(() => {
                       this.$router.replace({name: this.url.split('/')[1] })
                    }, 500)
                 }).catch(err=> {
                    this.$message.error(err.response.data.error.message)

                  })

                 })
         } else {
           console.log('error submit!!');
           return false;
         }
       });



        
  
     },
     loginFormReset () {}
  }
}
</script>
<style scoped>
.login-box {
  width: 300px;
  padding: 20px 50px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0,0,0,.1);
  border-radius: 8px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.logo {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0,0,0,.2);
  margin: 10px auto;
  background: url(../../../static/images/logo.jpg) no-repeat center;
  background-size: 50px;
}
.canvasBox {
  position: relative;
}
.canvas {
  position: absolute;
  right: 0px;
  top: 1px;
}

.btns  {
  margin-top: 10px;
}
.btns button {
  display: block;
  width: 100%;
  box-shadow: 5px 10px 10px rgba(37,97,239, .3);
}
.checkRemeber {
  font-size: 12px!important;
}
</style>
