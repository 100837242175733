<template>
  <div class="addCoupon">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/seckill' }"
        >秒杀活动</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ pageType }}秒杀活动</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :model="form" label-width="150px" ref="form" :rules="rules">

    <div class="boxLayout">
      <h3 class="title-t mg-b-20">基础信息</h3>
        <el-form-item label="活动名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="活动时间" prop="start_time">
          <el-date-picker
                v-model="dataTime"
                type="datetimerange"
                align="right"
                unlink-panels
                @change="getTimes"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
        </el-form-item>
        <el-form-item label="活动状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio label="0">下线</el-radio>
            <el-radio label="1">上线</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="活动图片" prop="cover_image">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest"
          >
          <img
              v-if="form.cover_image != ''"
              :src="form.cover_image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseBanner">从素材库选择</el-button>
          <p class="font-12 color-999">建议尺寸：750*600像素</p>
        </el-form-item>
        <!-- <el-form-item label="活动图片" prop="cover_image">
          <el-button class="avatar-uploader" @click="chooseBanner">
            <img
              v-if="form.cover_image !== ''"
              :src="form.cover_image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <i
              v-if="form.cover_image !== ''"
              class="el-icon-delete"
              @click.stop="form.image = ''"
            ></i>
          </el-button>
        </el-form-item> -->
    </div>
    <div class="boxLayout mg-t-20">
      <h3 class="title-t mg-b-20">商品设置</h3>
      <el-form-item label="秒杀商品" prop="goods">
        <el-button type="primary" @click="showCommodity">选择商品</el-button>
      </el-form-item>
      <el-form-item label="选择的商品" v-if="form.goods.length > 0">
          <!-- <el-table :data="commidityList" border>
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="name" label="商品名"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="commidityList.splice(scope.$index, 1)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table> -->
          <el-table :data="form.goods" border>
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="name" label="商品名称"></el-table-column>
            <el-table-column prop="spec_name" label="规格"></el-table-column>
            <el-table-column prop="price" label="售价（元）"></el-table-column>


            <el-table-column prop="name" label="优惠方式" width="250px">
              <template slot-scope="scope">
                <div class="flex">
                  <el-radio-group v-model="scope.row.type" style="flex: 1;">
                    <el-radio label="0">折扣</el-radio>
                    <el-radio label="1">减价</el-radio>
                  </el-radio-group>
                  <div>
                    <p><el-input style="width: 100px;margin: 0 10px;" v-model="scope.row.discount"></el-input>
                    <span v-if="scope.row.type == 0">折</span><span v-if="scope.row.type == 1">元</span></p>

                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="可售数量">
              <template slot-scope="scope">
                <el-input style="width: 100px;margin: 0 10px;" v-model="scope.row.number"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="form.goods.splice(scope.$index, 1)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
    </div>
    <div class="boxLayout mg-t-20">
      <h3 class="title-t mg-b-20">其他设置</h3>
      <el-form-item label="每人限购">
        <el-input
          v-model="form.buy_limit"
          class="ipt mg-l-20"
          type="number"
          placeholder="请输入"
          ><template slot="prepend">件</template></el-input
        >
        <span style="font-size: 12px;color: #666;">*为空默认不限制，每人每种商品前X件享受折扣，超出后无法通过活动购买</span>
      </el-form-item>
      <el-form-item label="下单同享受">
        <el-radio-group v-model="form.use_other">
            <el-radio label="0">不允许叠加优惠</el-radio>
            <el-radio label="1">允许叠加积分抵现</el-radio>
            <el-radio label="2">允许叠加优惠券</el-radio>
          </el-radio-group>
      </el-form-item>
      <el-form-item label="预热" prop="preheat_num">
        <el-checkbox v-model="form.preheat"
          >提前</el-checkbox
        >
        <el-input
          v-model="form.preheat_num"
          class="ipt mg-l-20"
          placeholder="请输入"
          ><template slot="prepend">小时</template></el-input
        >
      </el-form-item>
    </div>
  </el-form>

    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <!-- <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary :type="imgType" @selectImg="selectImg" />
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog> -->
    <selectCommdity
      ref="freeCommodity"
      :dialogVisible="selectCommdityDialogVisible"
      @confirmCommidity="confirmCommidity"
      @closeDialog="closeDialog"
      :goods="commidityList"
    />
    <el-dialog title="选择规格" :visible.sync="specsDialog">
      <el-table :data="exampleList" border>
        <el-table-column
          v-for="(item, i) in headerList"
          :key="i"
          :prop="item"
          :label="item"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" :align="'center'">
          <template slot-scope="scope">
            <el-button type="text" @click="choose(scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog
      :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog"
      />
      </el-dialog>
  </div>
</template>
<script>
import selectCommdity from "../../../components/selectCommdity.vue";
import pagination from "../../../components/pagination.vue";
import imageDialog from '../../../components/imageDialog.vue'
export default {
  name: "addCoupon",
  components: { selectCommdity, pagination , imageDialog},
  data() {
    return {
      pageType: "",
      specsDialog: false,
      exampleList: [],
      headerList: [],
      dialogVisible: false,
      labelDialogVisible: false,
      selectCommdityDialogVisible: false,
      dataTime: "",
      imgType: "",
      form: {
        name: "",
        start_time: "",
        end_time: "",
        status: "0",
        buy_limit: "",
        use_other: "2",
        goods: [],
        preheat: "",
        preheat_num: "",
        cover_image: ""
      },
      tips: false,
      rules: {
        name: { required: true, message: "请输入", trigger: "blur" },
        status: { required: true, message: "请选择", trigger: "change" },
        rules: { required: true, message: "请选择", trigger: "change" },
        start_time: { required: true, message: "请选择", trigger: "change" },
        cover_image: { required: true, message: "请选择", trigger: "change" }, 
        goods: { required: true, message: "请选择", trigger: "change" },
        preheat_num: { required: true, message: "请填写", trigger: "blur" },
      },
      commidityList: [],
      tags: [],
      formData: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: [],
    };
  },
  created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      this.getInfo();
    } else {
      this.pageType = "新增";
    }
  },
  methods: {
    showCommodity () {
      // this.$refs.freeCommodity.$refs.multipleTable.clearSelection()
    this.exampleList = []
      // if (!this.tips) {
      //   this.$alert('因为要选择商品规格，所以一次选一个商品就好', '提示', {
      //     confirmButtonText: '了解',
      //     callback: action => {
      //       taht.selectCommdityDialogVisible = true 
      //       this.tips = true

      //     }
      //   })
      // // } else {
        this.selectCommdityDialogVisible = true 
      // }
      
    },
    closeImageDialog () {
      this.dialogVisible = false
    },
     // 选择封面
     confirmImageDialog(item) {
      this.form.cover_image = item[0].url;
      this.dialogVisible = false;
    },
     // 上传图片
     httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.form.cover_image =  res[0].url;
          this.dialogVisible = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    beforeAvatarUpload(file) {
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    // 选择banner
    chooseBanner() {
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
    },
    // 选择图片
    selectImg(item) {
      this.form.cover_image = item.url;
      this.dialogVisible = false;
    },
    changeCondition (v) {
      if (v == 1) {
        this.form.condition_num = ''
      } else if (v == 3) {
        this.form.condition_money = ''
      } else {
        this.form.condition_num = ''
        this.form.condition_money = ''
      }
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Miaosha/${this.$route.query.uuid}`,
      })
        .then((res) => {
          let params = res.data.result;
          for (let i in params) {
            this.form[i] = JSON.parse(JSON.stringify(params[i]));
          }
    
          this.form.preheat = this.form.preheat == 1 ? true : false
          this.dataTime = [this.form.start_time, this.form.end_time]

          this.form.goods.map(item => {
            for (let i in item.spec) {
              item.spec_name = item.spec[i]
            }
          })

        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 选择时间
    getTimes(v) {
      this.form.start_time = v[0];
      this.form.end_time = v[1];
    },

    confirmCommidity(v) {
   
      this.commidityList = []
      v[0].specs.map((item) => {
        let obj = {};
        for (let i in item.specs) {
          obj[i] = item.specs[i];
        }
        obj.stock = item.stock;
        obj.uuid = item.uuid;
        this.exampleList.push(obj);
      });
      this.headerList = v[0].attrkey.map((item) => item.name);

      v.map((item,index) => {
        let obj = {}
        obj.goods_uuid = item.uuid
        obj.name = item.name
        obj.price = item.price
        obj.type = '0'
        this.form.goods.push(obj)
      })
     this.$refs.freeCommodity.$refs.multipleTable.clearSelection()
      this.selectCommdityDialogVisible = false;
      this.specsDialog = true
    },
    choose(row) {
      console.log(row)
      let obj = {}
      obj.spec_uuid = row.uuid
      obj.spec_name = row['样本量']
      
      this.$set(this.form.goods[this.form.goods.length - 1], 'spec_uuid', row.uuid)
      this.$set(this.form.goods[this.form.goods.length - 1], 'spec_name',  row['样本量'])
      this.specsDialog = false

    },
    closeDialog(v) {
      this.selectCommdityDialogVisible = v;
    },

    submit(formName) {
      let isCross = false
      console.log(this.form.goods)
      if(this.form.goods.length == 0) {
        this.$message.warning('请选择商品')
        return false
      }
      this.form.goods.map(item => {
        delete item.name
        delete item.spec_name
        delete item.price
        delete item.spec
        if (item.discount == null || item.number ==null ) {
          console.log(33333)
          this.$message.warning('请输入秒杀商品的折扣和数量')
          return false
        } else {
          isCross = true
        }
    

      })
      if (isCross) {
        this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.preheat = this.form.preheat == true ? 1 : 0
          this.form.preheat = this.form.preheat == true ? 1 : 0

          if (Object.keys(this.$route.query).length > 0) {
            delete this.form.create_time;
            delete this.form.delete_time;
            delete this.form.update_time;
            delete this.form.uuid;

            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.form,
              url: ` https://api.boyalife.net/v1/cms/Miaosha/${this.$route.query.uuid}`,
            })
              .then((res) => {
                this.$message.success("保存成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.form,
              url: ` https://api.boyalife.net/v1/cms/Miaosha`,
            })
              .then((res) => {
                this.$message.success("保存成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
      }
      
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.addCoupon {
  margin-bottom: 80px;
}
>>> .ipt {
  width: 200px;
  margin: 0 10px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-tag {
  margin-right: 5px;
  margin-bottom: 5px;
}
.el-tabel .el-radio {
  margin-right: 0;
  flex: 1;
}
.oneline {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
