<template>
  <div class="couponData" style="background: #f0f2f5">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item>活动</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/couponList' }"
        >优惠券列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>优惠券数据</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 商城订单 项目订单 快捷操作 会员数据概况 -->
    <div class="topSquare boxLayout" v-if="Object.keys(countInfo.length > 0)">
      <h3 class="title-t mg-b-30">使用数据</h3>
      <div class="analysis flex space-around">
        <div class="text-center">
          <h6 class="font-12 color-999">可用总数量</h6>
          <p class="font-30">{{ countInfo.usable }}</p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">领取总数量</h6>
          <p class="font-30">{{ countInfo.total }}</p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">领取人数</h6>
          <p class="font-30">{{ countInfo.user }}</p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">转赠数量</h6>
          <p class="font-30">{{ countInfo.gived }}</p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">使用总数量</h6>
          <p class="font-30">{{ countInfo.used }}</p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">过期总数量</h6>
          <p class="font-30">{{ countInfo.expired }}</p>
        </div>
      </div>

      <div class="dataTable">
        <el-table :data="tableData.data" style="width: 100%" border="">
          <el-table-column
            prop="count_date"
            label="日期"
            sortable
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="useable"
            label="优惠券可用数量"
            sortable
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="added"
            label="领取数量"
            sortable
            align="center"
          >
          </el-table-column>
          <el-table-column prop="user" label="领取人数" sortable align="center">
          </el-table-column>
          <el-table-column
            prop="gived"
            label="转赠数量"
            sortable
            align="center"
          >
          </el-table-column>
          <el-table-column prop="used" label="使用数量" sortable align="center">
          </el-table-column>
          <el-table-column prop="expired" label="过期数量" align="center">
          </el-table-column>
        </el-table>
        <pagination
          :total="tableData.total"
          :per_page="tableData.per_page"
          :current_page="tableData.current_page"
          @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";

export default {
  name: "couponData",
  components: {
    pagination,
  },
  data() {
    return {
      tabs: ["报名审核", "签到记录"],
      currentClass: 0,
      countInfo: {},
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getCount();
    this.getList();
  },
  methods: {
    addClass(index) {
      this.currentClass = index;
    },
    getCount() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Coupon/datacount/${this.$route.query.uuid}`,
      })
        .then((res) => {
          console.log(res);
          this.countInfo = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Coupon/datalist/${this.$route.query.uuid}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    pass() {
      this.$confirm("确定审核通过该用户的实名认证信息吗?", "审核通过确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    refuse() {
      this.$prompt("确定不通过该用户的报名信息吗？", "审核不通过确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        inputPlaceholder: "请输入不通过理由",
        inputType: "textarea",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "理由不能为空",
      })
        .then(({ value }) => {
          this.$message({
            type: "success",
            message: "你拒绝通过的理由是: " + value,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    checkInfo() {
      let html = `
        <h5 class="mg-l-30 mg-b-10">操作人: jason</h5>
        <h5 class="mg-l-30 mg-b-10">操作时间：2023/11/11 10:00:00</h5>
        <h5 class="mg-l-30 mg-b-10">税号: 999999999213123</h5>
        <h5 class="mg-l-30 mg-b-10">审核结果：审核不通过</h5>
        <h5 class="mg-l-30 mg-b-10">不通过原因：不符合报名条件</h5>
      `;
      this.$confirm(html, "查看审核记录", {
        confirmButtonText: "确认",
        showCancelButton: false,
        dangerouslyUseHTMLString: true,
      })
        .then(() => {})
        .catch(() => {});
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getCouponList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getCouponList();
    },
  },
};
</script>
<style scoped>
.dataTable {
  box-shadow: none;
  border-radius: 0;
  margin-top: 50px;
}
.dataTable .tabs {
  position: static;
}
.dataTable .tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.dataTable .tabs p:after {
  content: "";
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.dataTable .tabs p.on {
  color: #409eff;
}
.dataTable .tabs p.on:after {
  background: #409eff;
}
</style>
