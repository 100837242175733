<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/departmentManagement' }"
        >部门管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ pageType }}部门</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="部门名称" prop="name">
          <el-input
            v-model="basicInfoForm.name"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="上级部门">
          <el-select
            v-model="basicInfoForm.fid"
            placeholder="请选择"
            :no-data-text="'暂无上级部门'"
          >
            <el-option
              v-for="item in list"
              :key="item.uuid"
              :label="item.name"
              :value="item.uuid"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submitForm('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "addDepartment",
  data() {
    return {
      pageType: "新增",
      isChild: false,
      basicInfoForm: {
        name: "",
        fid: "",
      },
      rules: {
        name: [{ required: true, message: "部门名称必填", trigger: "blur" }],
      },
      list: [],
    };
  },
  created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      let params = this.$route.query;
      for (let i in params) {
        this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
      }
      if (this.$route.query.type != "child") {
        this.basicInfoForm.fid = "";
        this.isChild = false;
      } else {
        this.isChild = true;
        this.basicInfoForm.fid = this.basicInfoForm.fid;
      }
      console.log(this.isChild);
    }
    this.getDepartmentList();
  },
  methods: {
    // 部门列表
    getDepartmentList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Department`,
      })
        .then((res) => {
          console.log(res);
          this.list = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.basicInfoForm.fid =
            this.basicInfoForm.fid == "" ? 0 : this.basicInfoForm.fid;
          console.log(this.basicInfoForm);
          if (Object.keys(this.$route.query).length === 0) {
            this.axios({
              method: "POST", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://api.boyalife.net/v1/cms/Department`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "PUT", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://api.boyalife.net/v1/cms/Department/${this.basicInfoForm.uuid}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.el-form {
  width: 400px;
}
</style>
