<template>
  <div class="virtualOrderInfo">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/commodityOrder'">订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="stepList boxLayout" v-if="Object.keys(orderInfo).length > 0">
      <div class="top">
        <template v-if="orderInfo.status == 1">
          <h2 class="color-333">商品已拍下，等待买家付款</h2>
          <p class="font-12 mg-t-10 mg-b-20">
            如买家未在<span style="color: rgb(234, 79, 79)">12分34秒</span
            >内付款，订单将按自动关闭。
          </p>
        </template>
        <template v-if="orderInfo.status == 2">
          <h2 class="color-333">商品已付款，等待卖家发货</h2>
          <p class="font-12 mg-t-10 mg-b-20">买家已付款，请尽快安排发货。</p>
        </template>
        <template v-if="orderInfo.status == 3">
          <h2 class="color-333">卖家已发货，等待买家确认收货</h2>
          <p class="font-12 mg-t-10 mg-b-20">
            如买家未在<span style="color: rgb(234, 79, 79)"
              >6天23小时59分钟22秒</span
            >没有申请退款，交易将自动完成。
          </p>
        </template>
        <template v-if="orderInfo.status == 5">
          <h2 class="color-333">交易成功</h2>
          <p class="font-12 mg-t-10 mg-b-20">买家已确认收货，交易已完成。</p>
        </template>
        <template v-if="orderInfo.status === 7">
          <h2 class="color-333">交易关闭</h2>
          <p class="font-12 mg-t-10 mg-b-20">支付超时自动关闭。</p>
        </template>
        <el-steps
          :space="'50%'"
          :active="active"
          simple
          finish-status="success"
          align-center
          v-if="Object.keys(orderInfo).length > 0"
        >
          <el-step title="提交订单"></el-step>
          <el-step title="买家付款"></el-step>
          <el-step title="完成订单"></el-step>
        </el-steps>
        <div class="flex mg-t-20">
          <p>买家备注：</p>
          <p v-if="orderInfo.buyer_remark != ''">
            {{ orderInfo.buyer_remark }}
          </p>
          <p v-else>暂无</p>
        </div>
        <div class="flex remark">
          <p>卖家备注：</p>
          <p v-if="orderInfo.seller_remark != null">
            {{ orderInfo.seller_remark }}
          </p>
          <el-button type="text" class="font-14" @click="addRemark">{{
            orderInfo.seller_remark == null || orderInfo.seller_remark == ""
              ? "添加备注"
              : "编辑备注"
          }}</el-button>
        </div>
      </div>
    </div>
    <div class="info boxLayout mg-t-20">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <div class="flex">
        <div class="mg-r-50 mg-l-20">
          <h4 class="mg-b-10 font-16">买家信息</h4>
          <!-- <el-button type="text" @click="showDialog('editInfo')">编辑</el-button> -->
          <p>客户昵称: {{ orderInfo.name }}</p>
          <p>联系电话: {{ orderInfo.mobile }}</p>
        </div>
        <div class="mg-r-50 mg-l-20">
          <h4 class="mg-b-10 font-16">推广信息</h4>
          <p v-if="info.fxy1 != null">一级推广人：{{ info.fxy1 }}</p>
          <p v-if="info.fxy2 != null">二级推广人：{{ info.fxy2 }}</p>
        </div>
        <div class="mg-r-50 mg-l-20">
          <h4 class="mg-b-10 font-16">订单信息</h4>
          <p>
            订单编号: {{ orderInfo.order_sn }}
            <el-button type="text" @click="onCopy()">复制</el-button>
          </p>
          <p>
            下单账号: {{ orderInfo.user_uuid }}
            <el-button
              type="text"
              @click="$router.push('/customerInfo?uuid=' + orderInfo.user_uuid)"
              >客户详情</el-button
            >
          </p>
          <p>下单时间: {{ orderInfo.create_time }}</p>
          <p v-if="orderInfo.status == 1">订单状态：等待买家付款</p>
          <p v-else-if="orderInfo.status == 2">订单状态：等待卖家发货</p>
          <p v-else-if="orderInfo.status == 3">订单状态：已发货</p>
          <p v-else-if="orderInfo.status == 4" style="color: red">
            订单状态：退款售后
          </p>
          <p v-else-if="orderInfo.status == 5" style="color: green">
            订单状态：已完成
          </p>
          <p v-else>订单状态：已关闭</p>
          <template
            v-if="
              orderInfo.status == 2 ||
              orderInfo.status == 3 ||
              orderInfo.status == 5
            "
          >
            <p>付款时间: {{ orderInfo.pay_time }}</p>
            <p>付款金额: ￥{{ orderInfo.price }}</p>
            <p>支付方式: 微信支付</p>
          </template>
        </div>
      </div>
    </div>

    <div class="contractInfo boxLayout mg-t-20">
      <h3 class="title-t mg-b-20">商品</h3>
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="商品信息" :align="'center'" width="300">
          <template slot-scope="scope">
            <div class="product flex space-between">
              <div class="flex space-around" style="width: 100%">
                <el-image :src="scope.row.image"></el-image>
                <div style="width: 70%" class="text-left">
                  <p class="ellipsis">
                    {{ scope.row.name }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="retail_price" label="单价" :align="'center'">
          <template slot-scope="scope">
            ￥{{ scope.row.retail_price }}
          </template>
        </el-table-column>
        <el-table-column prop="number" label="数量" :align="'center'">
        </el-table-column>

        <el-table-column prop="coupon_price" label="优惠" :align="'center'">
          <template slot-scope="scope">
            ￥{{
              Number(scope.row.retail_price) * Number(scope.row.number) -
              Number(scope.row.price_real)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="price_real" label="小计" :align="'center'">
          <template slot-scope="scope"> ￥{{ scope.row.price_real }} </template>
        </el-table-column>
      </el-table>
      <div class="text-right mg-t-20">
        <p>
          商品总价：￥{{
            Number(tableData[0].retail_price) *
            Number(tableData[0].retail_price)
          }}
        </p>
        <p>
          优惠：￥{{
            Number(tableData[0].retail_price) *
              Number(tableData[0].retail_price) -
            Number(tableData[0].price_real)
          }}
        </p>
        <p class="font-18">
          应付款：<span style="color: tomato"
            >￥{{ tableData[0].price_real }}</span
          >
        </p>
      </div>
    </div>
    <div class="operateBtn">
      <el-button size="mini" v-if="active == 0">关闭订单</el-button>
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <checkInfoDialog
      :cancelBtnText="'取消'"
      :confirmBtnText="confirmBtnText"
      :visible="checkInfoDialogVisible"
      :title="title"
      :showCancelBtn="true"
      :showConfirmBtn="true"
      @closeDialog="closeDialog"
    >
      <el-form :model="infoForm" label-width="100px">
        <div class="editInfo mg-t-20" v-if="dialogType === 'editInfo'">
          <el-form-item label="收货人姓名">
            <el-input v-model="infoForm.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input v-model="infoForm.name"></el-input>
          </el-form-item>
        </div>
        <div class="sign mg-t-20" v-if="dialogType === 'sign'">
          <el-form-item label="提醒内容">
            <p>您预约的服务还没有签约，超时订单将自动关闭，请及时签约哦～</p>
          </el-form-item>
          <el-form-item label="发送方式">
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="system">系统消息</el-checkbox>
              <el-checkbox label="chat">聊天</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </el-form>
    </checkInfoDialog>
  </div>
</template>
<script>
import checkInfoDialog from "../../../components/checkInfoDialog.vue";
export default {
  name: "virtualOrderInfo",
  components: {
    checkInfoDialog,
  },
  data() {
    return {
      active: 0,
      checkList: [],
      orderInfo: {},
      checkInfoDialogVisible: false,
      dialogType: "",
      confirmBtnText: "确定",
      title: "编辑合同信息",
      infoForm: {
        checked: true,
      },
      tableData: [
        {
          id: 9999999,
          avatar:
            "https://axure-file.lanhuapp.com/06b9f5b8-0544-41f9-bee3-1322615ce006__b91b040875a6a0217563d85598fdfc7e.png",
          nickName:
            "索尼（SONY）Alpha 7 III(7M3K)全画幅微单数码索尼（SONY）Alpha 7 III(7M3K)全画幅微单数码",
          phone: "1",
          level: "7099",
          label: "20.00",
          times: "0.5%",
          money: "推广中",
          hasMoney: "2022/08/29 11:20:33",
          number: "SF435235453454t4",
          company: " 顺丰",
          status: "待付款",
          time: "-",
          send: true,
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      gridData: [
        {
          id: 9999999,
          avatar:
            "https://axure-file.lanhuapp.com/06b9f5b8-0544-41f9-bee3-1322615ce006__b91b040875a6a0217563d85598fdfc7e.png",
          nickName:
            "索尼（SONY）Alpha 7 III(7M3K)全画幅微单数码索尼（SONY）Alpha 7 III(7M3K)全画幅微单数码",
          phone: "1",
          level: "7099",
          label: "20.00",
          times: "0.5%",
          style: "快递物流",
          money: "推广中",
          hasMoney: "2022/08/29 11:20:33",
          number: "SF435235453454t4",
          company: " 顺丰",
          status: "待付款",
          time: "-",
          send: true,
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 9999999,
          avatar:
            "https://axure-file.lanhuapp.com/06b9f5b8-0544-41f9-bee3-1322615ce006__b91b040875a6a0217563d85598fdfc7e.png",
          nickName:
            "索尼（SONY）Alpha 7 III(7M3K)全画幅微单数码索尼（SONY）Alpha 7 III(7M3K)全画幅微单数码",
          phone: "1",
          level: "7099",
          label: "20.00",
          times: "0.5%",
          send: false,
          style: "快递物流",
          money: "推广中",
          hasMoney: "2022/08/29 11:20:33",
          number: "-",
          company: "-",
          status: "待发货",
          time: "-",
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      activities: [
        {
          content: "快件已离开北京市丰台区",
          timestamp: "2018-04-12 20:46",
          size: "large",
          type: "primary",
          icon: "",
          status: "运输中",
        },
        {
          content: "在北京市丰台区转运",
          timestamp: "2018-04-12 20:46",
          size: "large",
          type: "primary",
          icon: "",
          status: "",
        },
        {
          content: "在北京市进行下级地点扫描",
          timestamp: "2018-04-12 20:46",
          size: "large",
          type: "primary",
          icon: "",
          status: "",
        },
        {
          content: "包裹正在揽收",
          timestamp: "2018-04-03 20:46",
          color: "",
          status: "已揽件",
        },
        {
          content: "卖家已发货",
          timestamp: "2018-04-03 20:46",
          size: "large",
          status: "已发货",
        },
        {
          content: "您的订单开始处理",
          timestamp: "2018-04-03 20:46",
          status: "",
        },
      ],
    };
  },
  created() {
    this.getInfo(this.$route.query.uuid);
  },
  methods: {
    getInfo(uuid) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/GoodsOrder/${uuid}`,
      })
        .then((res) => {
          this.orderInfo = res.data.result;
          this.tableData = res.data.result.goods;
          this.remark = this.orderInfo.seller_remark;
          if (this.orderInfo.status == 1) {
            this.active = 0;
          } else if (this.orderInfo.status == 5) {
            this.active = 2;
          }
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    showDialog(type) {
      this.dialogType = type;
      this.checkInfoDialogVisible = true;
      if (type === "editInfo") {
        this.confirmBtnText = "确定";
        this.title = "编辑客户信息";
      } else if (type === "sign") {
        this.confirmBtnText = "确定发送";
        this.title = "提醒买家付款";
      } else if (type === "send") {
        this.confirmBtnText = "确定";
        this.title = "订单发货";
      } else if (type === "logistics") {
        this.confirmBtnText = "确定";
        this.title = "物流信息";
      }
    },
    closeDialog(v) {
      this.checkInfoDialogVisible = v;
    },
    onCopy() {
      this.$copyText("3333").then(
        (e) => {
          this.$message.success("复制成功!");
        },
        (e) => {
          this.$message.success("复制失败!");
        }
      );
    },
    //  添加备注
    //  添加备注
    addRemark() {
      const msg = this.remark !== "" ? "编辑" : "添加";
      this.$prompt("备注信息", msg + "备注", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPlaceholder: this.remark,
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入备注",
      }).then(({ value }) => {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            uuids: this.$route.query.uuid,
            remark: value,
          },
          url: ` https://api.boyalife.net/v1/cms/GoodsOrder/remark`,
        })
          .then((res) => {
            this.$message.success("操作成功！");
            this.remark = value;
            this.getInfo();
          })
          .catch((err) => {
            this.$message.erroe(err);
          });
      });
    },
  },
};
</script>
<style scoped>
.virtualOrderInfo {
  margin-bottom: 80px;
}
.top .remark .el-button {
  padding: 0 10px;
}
>>> .el-dialog {
  width: 800px !important;
}
>>> .el-step__title.is-process {
  color: #409eff;
}
>>> .el-step.is-simple .el-step__icon {
  border-color: #409eff;
}
>>> .el-step__head.is-wait .el-step__icon {
  border-color: #c0c4cc;
}
>>> .el-step__head.is-success .el-step__icon {
  border-color: #67c23a;
}
>>> .el-button--text {
  padding: 5px 0px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
>>> .el-timeline-item {
  margin-left: 200px;
  padding-top: 20px;
}
>>> .el-timeline-item__timestamp.is-bottom {
  width: 120px;
  position: absolute;
  right: 100%;
  bottom: 3px;
}
>>> .el-timeline-item__node--large {
  width: 25px;
  height: 25px;
  left: -7px;
}
>>> .el-timeline-item__node--large .el-timeline-item__icon {
  font-size: 17px;
}
.logisticsInfo {
  max-height: 400px;
  overflow: auto;
}
</style>
