<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>消息配置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <el-table
        :data="tableData.data"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column label="ID" prop="uuid" align="center">
        </el-table-column>
        <el-table-column label="通知类型" prop="category_desc" align="center">
        </el-table-column>
        <el-table-column
          prop="desc"
          label="通知场景说明"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="station_status" label="站内信" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.station_status"
              active-text="启用"
              active-color="#13ce66"
              inactive-color="gray"
              :active-value="1"
              :inactive-value="2"
              @change="handleStatusChange(scope.row, 'station_status')"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="minigram_status" label="小程序" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.minigram_status"
              active-text="启用"
              active-color="#13ce66"
              inactive-color="gray"
              :active-value="1"
              :inactive-value="2"
              @change="handleStatusChange(scope.row, 'minigram_status')"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="sms_status" label="短信" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.sms_status"
              active-text="启用"
              active-color="#13ce66"
              inactive-color="gray"
              :active-value="1"
              :inactive-value="2"
              @change="handleStatusChange(scope.row, 'sms_status')"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="update_time"
          width="160"
          label="最近编辑时间"
        >
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button @click="toEdit(scope.row)" type="text" size="small"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "messageConfiguration",
  components: {
    pagination,
  },
  data() {
    return {
      customerGroupForm: {
        station_status: "",
        minigram_status: "",
        sms_status: "",
      },
      uuid: "",
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getMessageList();
  },
  methods: {
    // 编辑跳转
    toEdit(row) {
      this.$router.push({
        name: "addMessageConfig",
        query: { ...row },
      });
    },
    handleStatusChange(row, type) {
      this.uuid = row.uuid;
      this.customerGroupForm = row;
      this.editMessageConfig();
    },
    // 编辑消息
    editMessageConfig() {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.customerGroupForm,
        url: ` https://api.boyalife.net/v1/cms/MessageSetting/${this.uuid}`,
      })
        .then((res) => {
          this.$message.success("编辑成功！状态改变");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 消息列表
    getMessageList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/MessageSetting?page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    deleted() {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getMessageList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getMessageList();
    },
  },
};
</script>
<style></style>
