<template>
  <div class="storeList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>门店</el-breadcrumb-item>
      <el-breadcrumb-item>门店管理</el-breadcrumb-item>
      <el-breadcrumb-item>店铺列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="创建日期">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="店铺区域">
          <el-cascader
            v-model="value"
            :options="cityList"
            @change="getCityValue"
            :props="props"
            collapse-tags
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="店铺状态">
          <el-select
            v-model="customerGroupForm.search_status"
            placeholder="请选择"
          >
            <el-option label="启用" :value="1"></el-option>
            <el-option label="禁用" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入店铺名称"
            v-model="customerGroupForm.search_keyword"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getStoreList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="$router.push({ name: 'addStore' })"
          >添加店铺</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          label="店铺名称"
          :align="'center'"
          prop="name"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column prop="province" label="所在区域" :align="'center'">
          <template slot-scope="scope">
            <span
              >{{ scope.row.province.area_name }}{{ scope.row.city.area_name
              }}{{ scope.row.area.area_name }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="order_number"
          label="关联订单数量"
          :align="'center'"
        >
        </el-table-column>

        <el-table-column prop="status" label="状态" :align="'center'">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.status == 1"
              >启用</span
            >
            <span v-else>禁用</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" :align="'center'">
        </el-table-column>
        <el-table-column label="操作" :align="'center'" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="toEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="deleteSupplier(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "storeList",
  components: {
    pagination,
  },
  data() {
    return {
      customerGroupForm: {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
        search_status: "",
        search_province: "",
        search_city: "",
        search_area: "",
      },
      value: "",
      cityList: [],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      props: {
        label: "area_name",
        value: "area_id",
      },
    };
  },
  created() {
    this.getStoreList();
    this.getCity();
  },
  methods: {
    addClass(index) {
      this.currentClass = index;
    },
    clear() {
      this.value = "";
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getStoreList();
    },
    getCityValue(v) {
      this.customerGroupForm.search_province = v[0];
      this.customerGroupForm.search_city = v[1];
      this.customerGroupForm.search_area = v[2];
    },
    // 地区
    getCity() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Region`,
      })
        .then((res) => {
          this.cityList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 编辑跳转
    toEdit(row) {
      row.province = JSON.stringify(row.province);
      row.city = JSON.stringify(row.city);
      row.area = JSON.stringify(row.area);
      console.log(row);
      this.$router.push({
        name: "addStore",
        query: { ...row },
      });
    },
    getData(methods, data, queryStry, mark, tableData, msg = "") {
      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data,
        url: ` https://api.boyalife.net/v1/cms/Shop${mark}${queryStry}`,
      })
        .then((res) => {
          console.log(res);
          if (msg !== "") {
            this.$message.success(msg);
          }
          if (tableData !== null) {
            this.tableData = res.data.result;
          }
          if (methods === "delete") {
            this.getStoreList();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 列表
    getStoreList() {
      let data = {};
      let queryStry = `search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&search_status=${this.customerGroupForm.search_status}&search_province=${this.customerGroupForm.search_province}&search_city=${this.customerGroupForm.search_city}&search_area=${this.customerGroupForm.search_area}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}`;
      this.getData("get", data, queryStry, "?", this.tableData);
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    deleteSupplier(row) {
      this.$confirm("确定删除该店铺吗?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let data = {};
        let queryStry = `${row.uuid}`;
        this.getData("delete", data, queryStry, "/", null, "删除成功！");
      });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getStoreList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getStoreList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.storeList .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
.dataTable {
  position: relative;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
.layout .bg {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 7;
}
.layout .el-icon-circle-close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
.matreial {
  width: 1050px;
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
  background: #fff;
  padding: 30px;
  border-radius: 4px;
}
</style>
