<template>
  <div class="informationList"  >
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容</el-breadcrumb-item>
      <el-breadcrumb-item>资讯</el-breadcrumb-item>
      <el-breadcrumb-item>资讯内容管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="资讯分类" prop="information_category">
          <el-cascader
            v-model="value"
            :show-all-levels="false"
            :emitPath="false"
            @change="getCheckedNodes"
            :options="options"
            :props="props"
            filterable
            collapse-tags
          ></el-cascader>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="customerGroupForm.search_status"
            placeholder="请选择"
          >
            <el-option label="显示" :value="1"></el-option>
            <el-option label="不显示" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布日期">
          <el-date-picker
            v-model="customerGroupForm.consumDate"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            style="width: 300px; margin-left: 20px"
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getInformationList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="copyUrl">转载公众号文章</el-button>
        <el-button type="primary" @click="setDeclir">免责声明</el-button>
        <el-button type="primary" @click="$router.push('/addInformation')"
          >添加资讯</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="转载中，请稍后..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="资讯标题" :align="'center'" width="300">
          <template slot-scope="scope">
            <div class="product flex">
              <el-image
                fit="cover"
                v-if="scope.row.cover_image.includes(',')"
                :src="scope.row.cover_image.split(',')[0]"
              ></el-image>
              <el-image
                fit="cover"
                v-else
                :src="scope.row.cover_image"
              ></el-image>

              <div style="width: 190px" class="text-left">
                <el-tooltip
                  :content="scope.row.information_title"
                  placement="top"
                >
                  <p class="ellipsis">
                    {{ scope.row.information_title }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="source_text"
          label="来源渠道"
          :align="'center'"
          width="200"
        >
          <template slot-scope="scope">
            <div class="flex align-center">
              <el-image
                :src="scope.row.source_image"
                v-if="scope.row.source_image !== ''"
              ></el-image>
              <span style="flex: 1">{{ scope.row.source_text }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_num"
          label="关联商品数量"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column prop="read_num" label="阅读量" :align="'center'">
        </el-table-column>
        <el-table-column prop="forwarding_num" label="转发量" :align="'center'">
        </el-table-column>
        <el-table-column prop="collect_num" label="收藏量" :align="'center'">
        </el-table-column>
        <el-table-column prop="status" label="状态" :align="'center'">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status == 1">显示</el-tag>
            <el-tag type="primary" v-else>不显示</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="发布时间" :align="'center'">
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          width="160"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
            @click="$router.push(`/addInformation?uuid=${scope.row.uuid}`)"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button
              v-if="scope.row.is_top == 2"
              @click="toTop(scope.row)"
              type="text"
              size="small"
              >置顶</el-button
            >
            <el-button
              v-if="scope.row.is_top == 1"
              @click="toTop(scope.row)"
              type="text"
              size="small"
              >取消置顶</el-button
            >

            <el-button
              type="text"
              @click="$router.push('/information?uuid=' + scope.row.uuid)"
              >资讯详情</el-button
            >
            <el-button
              type="text"
              @click="$router.push(`/informationData?uuid=${scope.row.uuid}`)"
              >数据</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.is_hot == 2"
              @click="setHot(scope.row)"
              >设为热门</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.is_hot == 1"
              @click="setHot(scope.row)"
              >取消热门</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status == 1"
              @click="showOrHide(scope.row)"
              >隐藏</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status == 2"
              @click="showOrHide(scope.row)"
              >显示</el-button
            >
            <el-button type="text" @click="delInfomation(scope.row.uuid)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog
      title="设置免责声明"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <p class="mg-b-10">
        <i class="el-icon-warning" style="color: #ffc71c; font-size: 16px"></i>
        &nbsp;免责声明会在小程序资讯文章底部显示
      </p>
      <el-input
        type="textarea"
        show-word-limit
        :rows="5"
        v-model="disclaimers"
        maxlength="200"
        placeholder="请输入，限制0～200字"
        resize="none"
      ></el-input>
      <div class="flex align-center mg-t-20">
        <p class="mg-r-10">是否显示</p>
        <el-switch
          v-model="isShow"
          :active-value="1"
          :inactive-value="2"
          active-color="#13ce66"
          inactive-color="gray"
        >
        </el-switch>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitDis">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "informationList",
  components: {
    pagination,
  },
  data() {
    return {
      loading: false,
      consumDate: "",
      dialogVisible: false,
      isShow: 1,
      options: [],
      // 免责声明
      disclaimers: "",
      customerGroupForm: {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
        search_status: "",
        search_information_category: "",
      },
      value: "",
      props: {
        label: "category_name",
        value: "uuid",
        children: "children",
        checkStrictly: true,
      },
      labelOptions: [
        {
          label: "显示",
          value: 1,
        },
        {
          label: "不显示",
          value: 2,
        },
      ],
      keywordList: [
        {
          label: "种草官姓名",
          value: 1,
        },
        {
          label: "种草官昵称",
          value: 2,
        },
        {
          label: "种草官手机号码",
          value: 3,
        },
        {
          label: "种草标题/内容",
          value: 4,
        },
        {
          label: "商品编码",
          value: 5,
        },
        {
          label: "商品名称",
          value: 6,
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getInformationList();
    this.getInformationCategoryList();
  },
  methods: {
    copyUrl () {
      this.$prompt('请输入公众号文章地址', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern:/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
          inputErrorMessage: '请输入正确的地址格式'
        }).then(({ value }) => {
          this.loading = true
          this.axios({
            method: "get", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://api.boyalife.net/v1/cms/Information/readurl?url=${value}`,
          })
            .then((res) => {
              this.loading = false

              this.$message.success('转载成功！正在跳转...')
              let result = res.data.result;
              this.$router.push(`/addInformation?cover_image=${result.cover_image}&title=${result.title}&summary=${result.summary}&content=${encodeURIComponent(result.content)}`);
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
    },
    getCheckedNodes(v) {
      if (v.length > 1) {
        this.customerGroupForm.search_information_category = v[1];
      } else {
        this.customerGroupForm.search_information_category = v[0];
      }
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.value = "";
      this.getInformationList();
    },
    setDeclir() {
      this.dialogVisible = true;
      this.getDisclaimer();
    },
    // 获取免责声明
    getDisclaimer() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Disclaimer/1`,
      })
        .then((res) => {
          this.disclaimers = res.data.result.content;
          this.isShow = Number(res.data.result.status);
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },

    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    getInformationList() {
      let queryStr = `search_status=${this.customerGroupForm.search_status}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_information_category=${this.customerGroupForm.search_information_category}
      `;
      this.getData("get", {}, queryStr, "?", this.tableData, "");
    },
    // 置顶
    toTop(row) {
      let isTop = row.is_top == 1 ? 2 : 1;
      let queryStr = `${row.uuid}`;
      let data = {
        is_top: isTop,
      };
      if (row.is_top == 2 && row.is_hot == 1) {
        this.$confirm("选择资讯置顶, 则取消热门推荐", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.getData("put", data, queryStr, "/top/", null, "操作成功！");
          this.getData(
            "put",
            {
              is_hot: 2,
            },
            queryStr,
            "/hot/",
            null,
            ""
          );
        });
      } else {
        this.getData("put", data, queryStr, "/top/", null, "操作成功！");
      }
    },
    setHot(row) {
      let isHot = row.is_hot == 1 ? 2 : 1;
      let queryStr = `${row.uuid}`;
      let data = {
        is_hot: isHot,
      };
      if (row.is_hot == 2 && row.is_top == 1) {
        this.$confirm("选择热门推荐, 则取消资讯置顶", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.getData("put", data, queryStr, "/hot/", null, "操作成功！");
          this.getData(
            "put",
            {
              is_top: 2,
            },
            queryStr,
            "/top/",
            null,
            ""
          );
        });
      } else {
        this.getData("put", data, queryStr, "/hot/", null, "操作成功！");
      }
    },
    showOrHide(row) {
      let queryStr = `${row.uuid}`;
      let data = {
        status: row.status == 1 ? 2 : 1,
      };
      this.getData("put", data, queryStr, "/status/", null, "操作成功！");
    },
    getData(methods, data, queryStry, mark, tableData, msg) {
      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data,
        url: ` https://api.boyalife.net/v1/cms/Information${mark}${queryStry}`,
      })
        .then((res) => {
          if (msg !== "") {
            this.$message.success(msg);
          }
          if (tableData !== null) {
            this.tableData = res.data.result;
          }
          if (methods == "delete" || methods == "post" || methods == "put") {
            this.getInformationList();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 提交免责声明
    submitDis() {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          content: this.disclaimers,
          status: this.isShow,
        },
        url: ` https://api.boyalife.net/v1/cms/Disclaimer/1`,
      })
        .then((res) => {
          this.dialogVisible = false;
          this.$message.success("设置成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    delInfomation(uuid) {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.getData("delete", {}, `${uuid}`, "/", null, "删除成功");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    // 资讯分类
    getInformationCategoryList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/InformationCategorySelect`,
      })
        .then((res) => {
          this.options = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getInformationList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getInformationList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.informationList .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
>>> .el-image__inner {
  height: 100%;
}
</style>
