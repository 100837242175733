<template>
  <div class="addLaboratory">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>实验室</el-breadcrumb-item>
      <el-breadcrumb-item>实验室管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/laboratoryList' }"
        >实验室列表</el-breadcrumb-item
      >
      <el-breadcrumb-item
        >{{ uuid ? "编辑" : "添加" }}实验室</el-breadcrumb-item
      >
    </el-breadcrumb>
    <div class="container">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-row>
        <el-col :span="16">
          <el-form ref="form" :model="labForm" label-width="120px">
            <el-form-item
              label="实验室名称"
              prop="name"
              :rules="{
                required: true,
                message: '实验室名称不能为空。',
                trigger: 'blur',
              }"
            >
              <el-input v-model="labForm.name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item
              label="实验室地址"
              prop="address"
              :rules="{
                required: true,
                message: '请选择实验室地址。',
                trigger: 'blur',
              }"
            >
              <p class="address" @click="selectMapControl">
                <span class="font-12 color-999" v-if="labForm.address == ''"
                  >点击启用地图控件</span
                >
                <span v-else
                  >{{ labForm.address
                  }}<span class="font-12 color-999 mg-l-20"
                    >(点击可修改)</span
                  ></span
                >
              </p>
            </el-form-item>
            <el-form-item
              label="实验室类型"
              prop="type"
              :rules="{
                required: true,
                message: '实验室类型不能为空。',
                trigger: 'blur',
              }"
            >
              <el-select v-model="labForm.type" placeholder="请选择">
                <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.val"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="实验室ID">
              <el-input
                v-model="labForm.ext_id"
                placeholder="实验室ID作为同步数据的唯一标识"
              ></el-input>
            </el-form-item>
            <el-form-item label="温度">
              <el-input
                v-model="labForm.temperature"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="湿度">
              <el-input
                v-model="labForm.humidity"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="实验室视频">
              <el-button class="avatar-uploader video" @click="chooseVideo">
                <video v-if="labForm.video_src !== ''" width="178px" controls>
                  <source :src="labForm.video_src" />
                </video>
                <i
                  class="el-icon-delete"
                  v-if="labForm.video_src !== ''"
                  @click.stop="labForm.video_src = ''"
                ></i>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-button>
              <p class="font-12 color-999">建议尺寸：1080P，500m以内</p>
            </el-form-item>
            <el-form-item label="实验室封面">
              <el-button class="avatar-uploader" @click="chooseImg">
                <img
                  v-if="labForm.image_src !== ''"
                  :src="labForm.image_src"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <i
                  v-if="labForm.image_src !== ''"
                  class="el-icon-delete"
                  @click.stop="labForm.image_src = ''"
                ></i>
              </el-button>
              <p class="font-12 color-999">支持1张，建议尺寸：750*1624像素</p>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
    </div>
    <el-dialog
      title="选择地址"
      :visible.sync="mapDialog"
      width="80%"
      :close-on-click-modal="false"
    >
      <MapView
        :uuid="uuid"
        :mapForm="mapForm"
        @confirmDialog="confirmMap"
        @closeDialog="mapDialog = false"
      />
    </el-dialog>
    <el-dialog
      :title="getUrlType == 'image' ? '选择封面' : '选择视频'"
      :visible.sync="showMatreial"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary
        :type="imgType"
        @selectImg="selectImg"
        @insertVideoHandler="insertVideoHandler"
      />
    </el-dialog>
  </div>
</template>
<script>
import MapView from "./mapView.vue";
import MaterialLibrary from "../../components/materialLibraryDialog/index";

export default {
  name: "addLaboratory",
  data() {
    return {
      uuid: this.$route.query.uuid || "",
      labForm: {
        name: "",
        ext_id: "",
        temperature: "",
        humidity: "",
        type: "",
        longitude: "",
        latitude: "",
        province_uuid: "",
        city_uuid: "",
        area_uuid: "",
        address: "",
        video_src: "",
        image_src: "",
      },
      mapForm: {
        longitude: "",
        latitude: "",
        province: "",
        city: "",
        area: "",
        address: "",
      },
      typeList: [
        { id: "1", val: "样品处理间" },
        { id: "2", val: "样品存储间" },
      ],
      //图片、视频
      imgType: "",
      showMatreial: false,
      getUrlType: "",
      //地址
      mapDialog: false,
    };
  },
  components: { MapView, MaterialLibrary },
  created() {
    if (this.uuid.length > 0) {
      this.getDetail();
    }
  },
  methods: {
    //详情
    getDetail() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Lab/${this.uuid}`,
      })
        .then((res) => {
          this.labForm = Object.assign(this.labForm, res.data.result);
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    //保存
    submit(form) {
      const formData = {
        name: this.labForm.name,
        ext_id: this.labForm.ext_id,
        temperature: this.labForm.temperature,
        humidity: this.labForm.humidity,
        type: this.labForm.type,
        longitude: this.labForm.longitude,
        latitude: this.labForm.latitude,
        province_uuid: this.labForm.province_uuid,
        city_uuid: this.labForm.city_uuid,
        area_uuid: this.labForm.area_uuid,
        address: this.labForm.address,
        video_src: this.labForm.video_src,
        image_src: this.labForm.image_src,
      };
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.uuid.length !== 0) {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: formData,
              url: ` https://api.boyalife.net/v1/cms/Lab/${this.uuid}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.success(err);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: formData,
              url: ` https://api.boyalife.net/v1/cms/Lab`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.success(err);
              });
          }
        } else {
          return false;
        }
      });
    },
    //视频
    chooseVideo() {
      this.imgType = "chooseVideo";
      this.getUrlType = "video";
      this.showMatreial = true;
    },
    insertVideoHandler(url) {
      this.labForm.video_src = url;
      this.showMatreial = false;
    },
    //封面
    chooseImg() {
      if (this.labForm.image_src.length > 0) {
        //仅支持一张
        return false;
      }
      this.imgType = "chooseCover_image";
      this.getUrlType = "image";
      this.showMatreial = true;
    },
    selectImg(item) {
      this.labForm.image_src = item.url;
      this.showMatreial = false;
    },
    //地址
    selectMapControl() {
      this.mapForm.longitude = this.labForm.longitude;
      this.mapForm.latitude = this.labForm.latitude;
      this.mapForm.province = this.labForm.province;
      this.mapForm.city = this.labForm.city;
      this.mapForm.area = this.labForm.area;
      this.mapForm.address = this.labForm.address;
      this.mapDialog = true;
    },
    confirmMap(form) {
      this.labForm.longitude = form.longitude;
      this.labForm.latitude = form.latitude;
      this.labForm.address = form.address;
      this.labForm.province_uuid = form.provinceValue.area_id;
      this.labForm.city_uuid = form.cityValue.area_id;
      this.labForm.area_uuid = form.districtValue.area_id;
      this.mapDialog = false;
    },
  },
};
</script>
<style scoped>
.addLaboratory {
  background-color: #f2f2f2;
}

.container {
  padding: 20px 20px 70px 20px;
  background: #fff;
  border-radius: 8px;
}

.el-select {
  width: 100%;
}

.address {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  cursor: pointer;
}

.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
