<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>协议管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <el-table
        :data="tableData.data"
        style="width: 100%; margin-bottom: 20px"
        border
      >
        <el-table-column prop="name" label="标题" align="center">
        </el-table-column>
        <el-table-column align="center" prop="update_time" label="更新时间">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="toEdit(scope.row)" type="text" size="small"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "protocolManagement",
  components: {
    pagination,
  },
  data() {
    return {
      customerGroupForm: {},
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getProtocolList();
  },
  methods: {
    // 编辑跳转
    toEdit(row) {
      this.$router.push({
        name: "editProtocol",
        query: { uuid: row.uuid, content: row.content, name: row.name },
      });
    },
    // 协议列表
    // 消息列表
    getProtocolList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Agreement?page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 编辑协议
    // 删除协议
    deleted() {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getProtocolList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getProtocolList();
    },
  },
};
</script>
<style></style>
