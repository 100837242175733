import { render, staticRenderFns } from "./addAccount.vue?vue&type=template&id=21f6a50c&scoped=true&"
import script from "./addAccount.vue?vue&type=script&lang=js&"
export * from "./addAccount.vue?vue&type=script&lang=js&"
import style0 from "./addAccount.vue?vue&type=style&index=0&id=21f6a50c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21f6a50c",
  null
  
)

export default component.exports