<template>
  <div class="afterSalesReason">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单</el-breadcrumb-item>
      <el-breadcrumb-item>订单设置</el-breadcrumb-item>
      <el-breadcrumb-item>售后原因</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form :model="customerGroupForm" inline>
        <el-form-item label="创建日期">
          <el-date-picker
            class="mg-l-10"
            v-model="customerGroupForm.dataTime"
            :clearable="false"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_status"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="启用状态">
          <el-select
            v-model="customerGroupForm.search_status"
            placeholder="请选择"
          >
            <el-option label="启用" :value="1"></el-option>
            <el-option label="禁用" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="售后类型">
          <el-select
            v-model="customerGroupForm.search_type"
            placeholder="请选择"
          >
            <el-option label="仅退款" :value="1"></el-option>
            <el-option label="退货退款" :value="2"></el-option>
            <el-option label="换货" :value="3"></el-option>
            <el-option label="拒绝退货" :value="4"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="showDialog('add')"
          >添加售后原因</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="type" label="售后类型" :align="'center'">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">仅退款</span>
            <span v-else-if="scope.row.type == 2">退货退款</span>
            <span v-else-if="scope.row.type == 3">换货</span>
            <span v-else>拒绝退货</span>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="内容" :align="'center'">
        </el-table-column>
        <el-table-column prop="status" label="状态" :align="'center'">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.status == 1"
              >启用</span
            >
            <span v-else>禁用</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" :align="'center'">
        </el-table-column>
        <el-table-column label="操作" :align="'center'">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="showDialog('edit', scope.row)"
              >编辑</el-button
            >
            <el-button
              v-if="scope.row.status == 1"
              size="mini"
              type="text"
              @click="changeStatus(scope.row)"
              >禁用</el-button
            >
            <el-button
              v-else
              size="mini"
              type="text"
              @click="changeStatus(scope.row)"
              >启用</el-button
            >
            <el-button size="mini" type="text" @click="deleted(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog :title="title + '售后原因'" :visible.sync="dialogFormVisible">
      <el-form :model="form" label-width="120px" class="mg-t-20" ref="form">
        <el-form-item label="类型">
          <el-select v-model="form.type" placeholder="请选择">
            <el-option label="仅退款" :value="1"></el-option>
            <el-option label="退货退款" :value="2"></el-option>
            <el-option label="换货" :value="3"></el-option>
            <el-option label="拒绝退货" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原因">
          <el-input v-model="form.content" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="是否启用" prop="status">
          <el-switch
            :active-value="1"
            :inactive-value="2"
            v-model="form.status"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "afterSalesReason",
  components: {
    pagination,
  },
  data() {
    return {
      customerGroupForm: {
        search_start_time: "",
        search_end_time: "",
        search_status: "",
        search_keyword: "",
        search_type: "",
      },
      uuid: "",
      form: {
        type: "",
        content: "",
        status: 2,
      },
      title: "添加",
      dialogFormVisible: false,
      isShow: true,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    showDialog(str, row) {
      this.dialogFormVisible = true;
      if (str == "add") {
        this.title = "新增";
      } else {
        this.title = "编辑";
        this.uuid = row.uuid;
        this.form = {
          type: Number(row.type),
          content: row.content,
          status: Number(row.status),
        };
      }
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    changeStatus(row) {
      let status = row.status == 1 ? 2 : 1;
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          status: status,
        },
        url: ` https://api.boyalife.net/v1/cms/AfterReason/status/${row.uuid}`,
      })
        .then((res) => {
          this.$message.success("操作成功！");
          this.getList();
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/AfterReason?search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&search_status=${this.customerGroupForm.search_status}&search_type=${this.customerGroupForm.search_type}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
          console.log(res);
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.title == "新增") {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.form,
              url: ` https://api.boyalife.net/v1/cms/AfterReason`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.dialogFormVisible = false;
                this.getList();
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.form,
              url: ` https://api.boyalife.net/v1/cms/AfterReason/${this.uuid}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.dialogFormVisible = false;
                this.getList();
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
    deleted(row) {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.axios({
          method: "delete", //请求方法
          headers: {
            "Content-Type": "text/html;charset=UTF-8",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          url: ` https://api.boyalife.net/v1/cms/AfterReason/${row.uuid}`,
        })
          .then((res) => {
            this.$message.success("删除成功！");
            this.getList();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
</style>
