<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>推广</el-breadcrumb-item>
      <el-breadcrumb-item>推广员管理</el-breadcrumb-item>
      <el-breadcrumb-item>全部推广员</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询 -->
    <div class="search-container">
      <div class="search-box">
        <el-form :inline="true" :model="customerForm" class="demo-form-inline">
          <el-form-item label="推广员等级">
            <el-select v-model="customerForm.search_level" placeholder="请选择">
              <el-option label="Lv1" :value="1"></el-option>
              <el-option label="Lv2" :value="2"></el-option>
              <el-option label="Lv3" :value="3"></el-option>
              <el-option label="Lv4" :value="4"></el-option>
              <el-option label="Lv5" :value="5"></el-option>
              <el-option label="Lv6" :value="6"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="推广员类型">
            <el-select v-model="customerForm.search_type" placeholder="请选择">
              <el-option label="内部" :value="1"></el-option>
              <el-option label="外部" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="累计推广金额">
            <rangeInput
              @input="rangeNumber"
              :placeholderMin="'最低金额'"
              :placeholderMax="'最高金额'"
            />
          </el-form-item>
          <el-form-item label="累计推广订单">
            <rangeInput
              @input="orderNumber"
              :placeholderMin="'最低次数'"
              :placeholderMax="'最高次数'"
            />
          </el-form-item>
          <el-form-item label="注册日期">
            <el-date-picker
              v-model="dataTime"
              type="daterange"
              align="right"
              unlink-panels
              @change="getTimes"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="最近推广日期">
            <el-date-picker
              v-model="dataTime2"
              type="daterange"
              align="right"
              unlink-panels
              @change="getTimes2"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="关键字搜索" class="search_keyword">
            <el-input
              placeholder="请输入客户昵称/手机号码/姓名"
              v-model="customerForm.search_keyword"
              class="input-with-select"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium" @click="exportExcel">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="text-right mg-b-20">
        <el-button type="primary" :disabled="selectNum == 0" @click="pass"
          >设置内部推广员</el-button
        >
        <el-button @click="refuse" :disabled="selectNum == 0"
          >拉黑推广员</el-button
        >
        <el-button @click="showIntegralVisible" :disabled="selectNum == 0"
          >批量增减积分</el-button
        >
        <el-button @click="$router.push('/promoterLevelSet')"
          >设置等级</el-button
        >
      </div>

      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" :align="'center'" width="55">
        </el-table-column>
        <el-table-column
          prop="user"
          label="推广员头像/昵称"
          :align="'center'"
          width="160"
        >
          <template slot-scope="scope">
            <router-link :to="'/promoterInfo?uuid=' + scope.row.uuid">
              <div class="nickAndAvatar">
                <el-image
                  v-if="scope.row.user != null && scope.row.user.avatar != ''"
                  :src="scope.row.user.avatar"
                ></el-image>
                <p>
                  <span
                    v-if="
                      scope.row.user != null && scope.row.user.nick_name != ''
                    "
                    >{{ scope.row.user.nick_name }}</span
                  >
                  <br />
                </p>
              </div>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="注册手机号码" :align="'center'">
        </el-table-column>
        <el-table-column prop="level" label="推广员等级" :align="'center'">
          <template slot-scope="scope">
            <span>LV{{ scope.row.level }}推广员</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="label"
          label="推广员标签"
          :align="'center'"
          show-overflow-tooltip
        >
          <template
            slot-scope="scope"
            v-if="scope.row.user != null && scope.row.user.label.length > 0"
          >
            <el-tag
              class="mg-r-10"
              type="success"
              v-for="item in scope.row.user.label"
              :key="item.uuid"
              >{{ item.name }}&nbsp;/&nbsp;</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="extend_order"
          label="累计推广次数"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column
          prop="extend_money"
          label="累计推广金额"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column prop="commission" label="佣金" :align="'center'">
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="注册时间"
          :align="'center'"
          width="160"
        >
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="160px">
          <template slot-scope="scope">
            <el-button
              @click="$router.push('/promoterInfo?uuid=' + scope.row.uuid)"
              type="text"
              size="small"
              >查看详情</el-button
            >
            <el-button type="text" size="small" @click="updateLevel(scope.row)"
              >修改等级</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status == 1"
              size="small"
              @click="refuse(scope.row)"
              >拉黑</el-button
            >
            <el-button
              type="text"
              v-else
              size="small"
              @click="refuse(scope.row)"
              >解除拉黑</el-button
            >

            <el-button
              type="text"
              size="small"
              @click="$router.push('/promoterData?uuid=' + scope.row.uuid)"
              >推广数据</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <!-- <selectCustomer :dialogVisible="dialogVisible" ref="promoter" :source="'promoter'"  @confirmCustomer="confirmCustomer" @cancel="closeSelectCustomer"/> -->
    <el-dialog
      title="批量增减积分"
      :visible.sync="dialogIntegralVisible"
      width="30%"
    >
      <el-form
        ref="Integral"
        :model="ingerForm"
        class="mg-t-20"
        :rules="ingerFormRules"
      >
        <el-form-item prop="type">
          <el-radio v-model="ingerForm.type" :label="1" border>增加</el-radio>
          <el-radio v-model="ingerForm.type" :label="2" border>减少</el-radio>
        </el-form-item>
        <el-form-item prop="score" label="积分">
          <el-input
            v-model="ingerForm.score"
            autocomplete="off"
            placeholder="请输入积分数值"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="ingerForm.remark"
            autocomplete="off"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogIntegralVisible = false">取 消</el-button>
        <el-button type="primary" @click="setIntegral('Integral')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog :title="levelTitle" :visible.sync="dialogLevelVisible" inline>
      <el-form :model="customerForm" class="mg-t-20">
        <el-form-item label="推广员等级">
          <el-select v-model="level" placeholder="请选择">
            <el-option label="Lv1推广员" value="1"></el-option>
            <el-option label="Lv2推广员" value="2"></el-option>
            <el-option label="Lv3推广员" value="3"></el-option>
            <el-option label="Lv4推广员" value="4"></el-option>
            <el-option label="Lv5推广员" value="5"></el-option>
            <el-option label="Lv6推广员" value="6"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogLevelVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmUpdateLevel">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import rangeInput from "../../../components/rangeInput.vue";
import selectCustomer from "../../../components/selectCustomer.vue";
import pagination from "../../../components/pagination.vue";
export default {
  name: "customerList",
  components: { rangeInput, selectCustomer, pagination },
  data() {
    return {
      dialogVisible: false,
      dialogTableVisible: false,
      dialogIntegralVisible: false,
      dialogLevelVisible: false,
      user_uuids: [],
      uuid: "",
      level: "",
      dataTime: "",
      dataTime2: "",
      levelTitle: "设置等级",
      customerForm: {
        search_level: "",
        search_type: "",
        search_start_money: "",
        search_end_money: "",
        search_start_order: "",
        search_end_order: "",
        search_start_time: "",
        search_end_time: "",
        search_start_recently: "",
        search_end_recently: "",
        search_keyword: "",
      },
      selectNum: 0,
      props: { multiple: true },
      ingerForm: {
        type: 1,
        score: "",
        remark: "",
        uuids: [],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      ingerFormRules: {
        score: { required: true, message: "请输入积分", trigger: "blur" },
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
    this.getLevel();
  },
  methods: {
    // 导出
    exportExcel() {
      this.axios({
        method: "post", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Extend/export?search_level=${this.customerForm.search_level}&search_type=${this.customerForm.search_type}&search_start_money=${this.customerForm.search_start_money}&search_end_money=${this.customerForm.search_end_money}&search_start_order=${this.customerForm.search_start_order}&search_end_order=${this.customerForm.search_end_order}&search_start_time=${this.customerForm.search_start_time}&search_end_time=${this.customerForm.search_end_time}&search_start_recently=${this.customerForm.search_start_recently}&search_end_recently=${this.customerForm.search_end_recently}&search_keyword=${this.customerForm.search_keyword}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let currentTime = new Date();
          let year = currentTime.getFullYear();
          let month = currentTime.getMonth() + 1;
          let day = currentTime.getDate();
          let formattedDate = `${year}/${month
            .toString()
            .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

          link.download = `推广员列表 - ${formattedDate}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    pass() {
      this.$confirm("确定设置为内部推广员吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: {
              uuidList: this.user_uuids,
              type: 1,
            },
            url: ` https://api.boyalife.net/v1/cms/Extend/updateType`,
          })
            .then((res) => {
              this.$message.success("操作成功！");
              this.getList();
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消标记",
          });
        });
    },
    showIntegralVisible() {
      this.dialogIntegralVisible = true;
      this.ingerForm.score = "";
      this.ingerForm.remark = "";
    },
    updateLevel(row) {
      this.dialogLevelVisible = true;
      this.uuid = row.uuid;
      this.level = row.level;
    },
    confirmUpdateLevel() {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          uuid: this.uuid,
          level: this.level,
        },
        url: ` https://api.boyalife.net/v1/cms/Extend/updateLevel`,
      })
        .then((res) => {
          this.$message.success("操作成功！");
          this.dialogLevelVisible = false;
          this.getList();
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 增减积分
    setIntegral(Integral) {
      this.$refs[Integral].validate((valid) => {
        if (valid) {
          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.ingerForm,
            url: ` https://api.boyalife.net/v1/cms/User/score_branch`,
          })
            .then((res) => {
              this.$message.success("操作成功！");
              this.dialogIntegralVisible = false;
              this.getList();
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
    handleSelectionChange(rows) {
      this.selectNum = rows.length;
      this.user_uuids = rows.map((item) => {
        return item.user_uuid;
      });
      this.ingerForm.uuids = this.user_uuids;
    },
    clear() {
      this.dataTime = "";
      this.dataTime2 = "";

      for (let i in this.customerForm) {
        this.customerForm[i] = "";
      }
      this.getList();
    },
    // 选择时间
    getTimes(v) {
      this.customerForm.search_start_time = v[0] + " 00:00:00";
      this.customerForm.search_end_time = v[1] + " 23:59:59";
    },
    // 选择时间
    getTimes2(v) {
      this.customerForm.search_start_time = v[0] + " 00:00:00";
      this.customerForm.search_end_time = v[1] + " 23:59:59";
    },
    // closeSelectCustomer (v) {
    //   this.dialogVisible = v
    // },
    // 获取等级
    getLevel() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },

        url: ` https://api.boyalife.net/v1/cms/ExtendLevelSetting/1`,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    confirmCustomer(v) {
      if (v.length < 1) {
        this.$message.warning("请至少选择一个客户");
        return false;
      } else {
        this.dialogVisible = false;
        this.user_uuids = v.map((item) => item.uuid);
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            user_uuid: this.user_uuids,
          },
          url: ` https://api.boyalife.net/v1/cms/Extend`,
        })
          .then((res) => {
            this.$message.success("添加成功！");
            this.getList();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      }
    },
    // 列表
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Extend?search_level=${this.customerForm.search_level}&search_type=${this.customerForm.search_type}&search_start_money=${this.customerForm.search_start_money}&search_end_money=${this.customerForm.search_end_money}&search_start_order=${this.customerForm.search_start_order}&search_end_order=${this.customerForm.search_end_order}&search_start_time=${this.customerForm.search_start_time}&search_end_time=${this.customerForm.search_end_time}&search_start_recently=${this.customerForm.search_start_recently}&search_end_recently=${this.customerForm.search_end_recently}&search_keyword=${this.customerForm.search_keyword}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}`,
      })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    refuse(row) {
      let sta = "";
      let html = "";
      if (row.status == 1) {
        sta = 2;
        html = "拉黑后客户将无法购买商品，确定要拉黑此客户吗?";
      } else {
        sta = 1;
        html = "确认解除该用户的拉黑状态吗？";
      }
      this.$confirm(html, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            uuid: row.uuid,
            status: sta,
          },
          url: ` https://api.boyalife.net/v1/cms/Extend/updateStatus/${row.uuid}`,
        })
          .then((res) => {
            this.$message.success("操作成功！");
            this.getList();
          })
          .catch((err) => {
            this.$message.success(err);
          });
      });
    },
    rangeNumber(val) {
      this.customerForm.search_start_money = val[0];
      this.customerForm.search_end_money = val[1];
    },
    orderNumber(val) {
      this.customerForm.search_start_order = val[0];
      this.customerForm.search_end_order = val[1];
    },
    // 表格颜色设置
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      } else {
        return "";
      }
    },
    closeDialog(v) {
      this.dialogTableVisible = v;
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
.search-container .el-input-group__append {
  background: none;
  cursor: pointer;
}
.search-container .keyword .el-input__inner {
  border-right: none !important;
}
.search-container .el-form--inline .el-form-item__label {
  color: #999;
}

.el-table .warning-row {
  background: #f4fcff;
}
.el-table .el-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 5px;
}
.el-table .nickAndAvatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
}
</style>
