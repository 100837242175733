<template>
  <div class="addDatum">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>实验室</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/laboratoryList' }"
        >实验室列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ uuid ? "编辑" : "添加" }}资料</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="container">
      <el-row>
        <el-col :span="16">
          <el-form ref="form" :model="datumForm" label-width="120px">
            <el-form-item
              label="资料标题"
              prop="name"
              :rules="{
                required: true,
                message: '请输入资料标题',
                trigger: 'blur',
              }"
            >
              <el-input
                v-model="datumForm.name"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="资料封面">
              <el-button class="avatar-uploader" @click="chooseImg">
                <img
                  v-if="datumForm.image_src !== ''"
                  :src="datumForm.image_src"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <i
                  v-if="datumForm.image_src !== ''"
                  class="el-icon-delete"
                  @click.stop="datumForm.image_src = ''"
                ></i>
              </el-button>
              <p class="font-12 color-999">建议尺寸为640*384像素，大小小于2M</p>
            </el-form-item>
            <el-form-item label="上架时间">
              <el-radio-group v-model="datumForm.status">
                <el-radio :label="'1'">立即上架</el-radio>
                <el-radio :label="'2'"
                  >定时上架
                  <el-date-picker
                    v-model="timeValue"
                    type="daterange"
                    align="right"
                    unlink-panels
                    @change="getTimes"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >确定</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">取消</el-button>
    </div>
    <el-dialog
      title="选择封面"
      :visible.sync="showMatreial"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary :type="imgType" @selectImg="selectImg" />
    </el-dialog>
  </div>
</template>
<script>
import MaterialLibrary from "../../components/materialLibraryDialog/index";

export default {
  name: "addDatum",
  data() {
    return {
      uuid: this.$route.query.uuid || "",
      type: this.$route.query.type || "add",
      datumForm: {
        name: "",
        image_src: "",
        status: "",
        date_start: "",
        date_end: "",
      },
      showMatreial: false,
      imgType: "chooseCover_image",
      timeValue: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    if (this.type == "edit") {
      this.getDetail();
    }
  },
  components: {
    MaterialLibrary,
  },
  methods: {
    submit(form) {
      if (this.datumForm.status == "2") {
        if (!this.datumForm.date_start || !this.datumForm.date_end) {
          this.$message.error("请选择定时上架日期");
          return false;
        }
      }
      const formData = {
        name: this.datumForm.name,
        image_src: this.datumForm.image_src,
        status: this.datumForm.status,
        date_start: this.datumForm.date_start,
        date_end: this.datumForm.date_end,
      };
      if (this.type == "add") {
        formData.lab_id = this.uuid;
      }
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.type == "edit") {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: formData,
              url: ` https://api.boyalife.net/v1/cms/LabStuff/${this.uuid}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.success(err);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: formData,
              url: ` https://api.boyalife.net/v1/cms/LabStuff`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.success(err);
              });
          }
        } else {
          return false;
        }
      });
    },
    //封面
    chooseImg() {
      if (this.datumForm.image_src.length > 0) {
        //仅支持一张
        return false;
      }
      this.showMatreial = true;
    },
    selectImg(item) {
      this.datumForm.image_src = item.url;
      this.showMatreial = false;
    },
    // 选择时间
    getTimes(v) {
      this.datumForm.date_start = v[0];
      this.datumForm.date_end = v[1];
    },
    //详情
    getDetail() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/LabStuff/${this.uuid}`,
      })
        .then((res) => {
          this.datumForm = Object.assign(this.datumForm, res.data.result);
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
  },
};
</script>
<style scoped>
.addDatum {
  background-color: #f2f2f2;
}

.container {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}

.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
