<template>
  <div>
    <div class="flex space-between">
      <div class="tabs flex">
        <p
          v-for="(item, index) in tabs"
          :key="index"
          @click="addClass(index)"
          :class="{ on: currentClass === index }"
        >
          {{ item }}
        </p>
      </div>
      <el-form :model="form">
        <el-form-item>
          <el-input placeholder="请输入要搜索的内容" v-model="search_keyword">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div style="overflow: hidden">
      <div class="scrollBody flex" id="myScrollBody">
        <div class="menu" id="menu">
          <ul>
            <li
              v-for="(item, index) in labelData"
              :class="{ on: activeIndex === index }"
              :key="item.id"
              @click="jump(index)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="label" id="myLabelBox">
          <div
            :data="index"
            class="d_jump"
            v-for="(item, index) in labelData"
            :key="item.id"
          >
            <h4 class="mg-b-20">{{ item.name }}</h4>
            <div class="flex">
              <el-checkbox-group v-model="newLabelData">
                <el-checkbox
                  :key="jtem.uuid"
                  :label="jtem.uuid"
                  border
                  size="medium"
                  v-for="(jtem, Jndex) in item.label"
                  @change="chooseTag(index, Jndex, jtem)"
                  >{{ jtem.name }}</el-checkbox
                >
              </el-checkbox-group>
              <!-- <span :class="{'on': States[index]==Jndex}" v-for="(jtem,Jndex) in item.children" :key="jtem.value" @click="chooseTag(index,Jndex,jtem)">{{jtem.label}}</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="showChooseLabel">
      <div class="flex">
        <el-tag
          :key="tag.uuid"
          v-for="(tag, index) in dynamicTags"
          closable
          :disable-transitions="false"
          @close="handleClose(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <div class="flex space-between">
        <div>
          <span
            >已选择
            <span style="color: orange">{{ dynamicTags.length }} </span>
            个标签</span
          >
          <el-button class="mg-l-20" type="text" @click="clearTag"
            >清空</el-button
          >
          <el-button type="text" @click="$router.push('/addLabel')"
            >添加标签</el-button
          >
        </div>
        <div>
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="submitTag">确 定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    tags: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectNum: {
      type: Number,
      default: 1,
    },
    uuid: {
      type: String,
      default: "",
    },
    uuids: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      States: {},
      search_keyword: "",
      newLabelData: [],
      currentClass: 0,
      activeIndex: 0, //锚点按钮
      num: 0,
      labelIndex: 0,
      tabs: ["平台标签"],
      contentDiv: null, //锚点区域
      form: {},
      dynamicTags: [],
      scrollTop: 0,
      offsetArr: [],
      labelData: [],
    };
  },
  created() {
    if (this.tags.length !== 0) {
      this.dynamicTags = JSON.parse(JSON.stringify(this.tags));
      this.newLabelData = this.tags.map((item) => item.uuid);
    } else {
      this.dynamicTags = [];
      this.newLabelData = [];
    }
    this.getList();
  },
  watch: {
    tags(n) {
      if (n.length !== 0) {
        this.dynamicTags = JSON.parse(JSON.stringify(n));
        this.newLabelData = n.map((item) => item.uuid);
      } else {
        this.dynamicTags = [];
        this.newLabelData = [];
      }
    },
  },
  mounted() {
    document
      .getElementById("myLabelBox")
      .addEventListener("scroll", this.handleScroll);
    document
      .getElementById("menu")
      .addEventListener("mouseover", this.handleScroll);
  },
  beforeDestroy() {
    document
      .getElementById("myLabelBox")
      .removeEventListener("scroll", this.handleScroll);
    document
      .getElementById("menu")
      .removeEventListener("mouseover", this.handleScroll);
  },
  methods: {
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/UserLabelGroupTree?search_keyword=${this.search_keyword}`,
      })
        .then((res) => {
          this.labelData = res.data.result;
          console.log(res);
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    addClass(index) {
      this.currentClass = index;
    },
    chooseTag(index, Jndex, jtem) {
      this.States[index] = Jndex;
      if (this.newLabelData.indexOf(jtem.uuid) !== -1) {
        this.dynamicTags.push(this.labelData[index].label[Jndex]);
      } else {
        this.dynamicTags = this.dynamicTags.filter(
          (item) => item.uuid !== jtem.uuid
        );
      }
      console.log(this.dynamicTags);
    },
    clearTag() {
      this.dynamicTags = [];
      this.newLabelData = [];
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    cancel() {
      if (this.type != "addCustomer") {
        this.dynamicTags = [];
        this.newLabelData = [];
        this.activeIndex = 0;
        let myLabelBox2 = document.getElementById("myLabelBox");
        myLabelBox2.scrollTo({
          top: 1,
          left: 20,
          behavior: "smooth",
        });
      }
      this.$emit("closeDialog", this.dynamicTags);
    },
    submitTag() {
      // if (this.dynamicTags.length < 1) {
      //   this.$message.warning('请至少选择一个标签')
      //   return false
      // }
      this.selectNum = this.selectNum == 0 ? 1 : this.selectNum;
      let len =
        this.dynamicTags.length >= 2 ? `, "${this.dynamicTags[1].name}"` : "";
      let uids = this.dynamicTags.map((item) => item.uuid) || [];
      if (this.type !== "addCustomer") {
        let html = "";
        if (this.dynamicTags.length == 0) {
          html = "您未选择任何标签，若客户之前有标签，此操作将清除标签";
        } else {
          html = `
          本次将给选择的<span style="color: orange"> ${this.selectNum} </span>位客户打上
          "${this.dynamicTags[0].name}"${len} 等<span style="color: orange"> ${this.dynamicTags.length} </span>个标签,请确认是否操作
        `;
        }

        this.$confirm(html, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          dangerouslyUseHTMLString: true,
        }).then(() => {
          if (this.uuids.length == 0) {
            this.axios({
              method: "PUT", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: {
                user_label: uids,
              },
              url: ` https://api.boyalife.net/v1/cms/User/label/${this.uuid}`,
            })
              .then((res) => {
                this.$message.success("操作成功！");
                this.$emit("closeDialog", this.dynamicTags);
                this.dynamicTags = [];
                this.newLabelData = [];
                this.activeIndex = 0;
                let myLabelBox2 = document.getElementById("myLabelBox");
                myLabelBox2.scrollTo({
                  top: 1,
                  left: 20,
                  behavior: "smooth",
                });
              })
              .catch((err) => {
                this.$message.error(err);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: {
                uuids: this.uuids,
                user_label: uids,
              },
              url: ` https://api.boyalife.net/v1/cms/User/label_branch`,
            })
              .then((res) => {
                this.$message.success("操作成功！");
                this.$emit("closeDialog", this.dynamicTags);
                this.dynamicTags = [];
                this.newLabelData = [];
                this.activeIndex = 0;
                let myLabelBox2 = document.getElementById("myLabelBox");
                myLabelBox2.scrollTo({
                  top: 1,
                  left: 20,
                  behavior: "smooth",
                });
              })
              .catch((err) => {
                this.$message.error(err);
              });
          }
        });
      } else {
        this.$emit("closeDialog", this.dynamicTags);
      }
    },
    jump(index) {
      this.activeIndex = index;
      const myLabelBox = document.getElementById("myLabelBox");
      myLabelBox.scrollTo({
        top: this.offsetArr[index] + 1,
        left: 20,
        behavior: "smooth",
      });
      console.log(myLabelBox.offsetTop);
    },
    // 滚动监听器
    handleScroll(i) {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(".d_jump");
      const myLabelBox = document.getElementById("myLabelBox");
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop);
      });
      offsetTopArr.forEach((item, index) => {
        let scrollTop = myLabelBox.scrollTop;
        // 获取当前文档流的 scrollTop
        if (scrollTop >= item) {
          this.activeIndex = index;
        }
      });
      this.offsetArr = offsetTopArr;
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0 !important;
}
>>> .el-form-item {
  margin-bottom: 0;
}
.tabs {
  width: 70%;
}
.tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.tabs p:after {
  content: "";
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.tabs p.on:after {
  background: #409eff;
}
.scrollBody {
  height: 40vh;
  border-top: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
  overflow: scroll;
}
.scrollBody .menu {
  width: 25%;
  overflow: auto;
  border: 1px solid rgb(230, 230, 230);
  border-top: none;
  border-bottom: none;
  font-weight: bold;
  padding-top: 10px;
}
.scrollBody .label {
  width: 75%;
  padding: 20px;
  overflow: auto;
  position: relative;
}
.scrollBody .label > div {
  margin-bottom: 20px;
}
.scrollBody .label .flex {
  flex-wrap: wrap;
}
.scrollBody .label h4 {
  font-size: 12px;
  font-weight: bold;
}
.scrollBody .label .flex span {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid rgb(233, 233, 233);
  margin-right: 15px;
  margin-bottom: 5px;
  font-size: 12px;
  cursor: pointer;
}

.scrollBody .menu ul li {
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
}
.scrollBody .menu ul li:hover,
.scrollBody .label .flex span:hover,
.scrollBody .menu ul li.on,
.scrollBody .label .flex span.on {
  background: rgb(95, 201, 254);
  border-color: rgb(95, 201, 254);
  color: #fff;
}
.showChooseLabel {
  height: 15vh;
  overflow: auto;
  padding-top: 20px;
}
.showChooseLabel .flex {
  flex-wrap: wrap;
}
.el-tag {
  margin-right: 15px;
  margin-bottom: 10px;
}
>>> .el-checkbox.is-bordered.el-checkbox--medium .el-checkbox__inner {
  display: none;
}
>>> .el-checkbox.is-bordered.el-checkbox--medium {
  height: 32px;
}
>>> .el-checkbox {
  margin-right: 0;
  margin-bottom: 10px;
}
</style>
