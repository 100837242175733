<template>
  <div class="invoicingList" style="background: #f0f2f5">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>财务</el-breadcrumb-item>
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>发票管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <div class="title">
        <el-form
          :inline="true"
          :model="customerGroupForm"
          class="demo-form-inline"
        >
          <el-form-item label="发票状态">
            <el-select
              multiple
              v-model="customerGroupForm.search_status"
              placeholder="请选择"
            >
              <el-option label="待处理" :value="1"></el-option>
              <el-option label="已开票" :value="2"></el-option>
              <el-option label="已拒绝" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="申请日期">
            <el-date-picker
              v-model="customerGroupForm.dataTime"
              type="daterange"
              align="right"
              unlink-panels
              @change="getTimes"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="关键字" class="keyword">
            <el-input
              placeholder="请输入内容"
              v-model="customerGroupForm.search_keyword"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="btn mg-t-10">
          <el-button type="primary" size="medium" @click="getList"
            >查询</el-button
          >
          <el-button size="medium" @click="clear">清空</el-button>
          <el-button size="medium">导出</el-button>
        </div>
        <el-table
          class="mg-t-20"
          ref="multipleTable"
          :data="tableData.data"
          border
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column type="selection" :align="'center'">
          </el-table-column>
          <el-table-column
            label="交易时间"
            prop="create_time"
            :align="'center'"
            width="160"
          >
          </el-table-column>
          <el-table-column
            prop="avatar"
            label="申请人账号"
            :align="'center'"
            width="170"
          >
            <template slot-scope="scope">
              <router-link :to="'/customerInfoZ?uuid=' + scope.row.user.uuid">
                <div style="margin: 0 auto; width: 150px">
                  <div class="flex align-center">
                    <el-image
                      fit="cover"
                      :src="scope.row.user.image"
                      v-if="
                        scope.row.user !== null && scope.row.user.image !== null
                      "
                    >
                    </el-image>
                    <div class="text-left" v-if="scope.row.user !== null">
                      <p>{{ scope.row.user.nick_name }}</p>
                      <p>{{ scope.row.user.mobile }}</p>
                    </div>
                  </div>
                </div>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="发票类型" :align="'center'" prop="status">
          </el-table-column>
          <el-table-column label="开票方式" :align="'center'" prop="type">
            <template slot-scope="scope">
              <span v-if="scope.row.type == 1">普票</span>
              <span v-else>专票</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="money"
            label="发票金额"
            :align="'center'"
            width="150"
            sortable
          >
            <template slot-scope="scope">
              <span>¥{{ scope.row.money }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="发票状态"
            :align="'center'"
            width="120"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">待处理</span>
              <span style="color: #2ed477" v-else-if="scope.row.status == 2"
                >已开票</span
              >
              <span style="color: red" v-else>已拒绝</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="order_sn"
            label="开票单号"
            :align="'center'"
            width="200"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            :align="'center'"
            width="200"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button @click="pass(scope.row)" type="text" size="small"
                >确认开票</el-button
              >
              <el-button @click="refuse(scope.row)" type="text" size="small"
                >拒绝开票</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <pagination
          :total="tableData.total"
          :per_page="tableData.per_page"
          :current_page="tableData.current_page"
          @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "invoicingList",
  components: { pagination },
  data() {
    return {
      customerGroupForm: {
        search_keyword: "",
        search_end_time: "",
        search_start_time: "",
        search_status: "",
      },
      labelOptions: [
        {
          label: "启用",
          value: "ok",
        },
        {
          label: "禁用",
          value: "no",
        },
      ],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Invoice?search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_status=${this.customerGroupForm.search_status}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}`,
      })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    pass(row) {
      let type = row.type == 1 ? "普票" : "专票";
      let title_type = row.title_type == 1 ? "个人" : "企业单位";
      let invoice_type = row.invoice_type == 1 ? "电子发票" : "纸质发票";
      let name = row.user == null ? "" : row.user.nick_name;
      let mobile = row.user == null ? "" : row.user.mobile;

      let html = `
        <h5 class="mg-l-30 mg-b-10">发票类型: ${type}</h5>
        <h5 class="mg-l-30 mg-b-10">抬头类型：${title_type}</h5>
        <h5 class="mg-l-30 mg-b-10">税号: ${row.duty_number}</h5>
        <h5 class="mg-l-30 mg-b-10">发票内容：${row.content}</h5>
        <h5 class="mg-l-30 mg-b-10">发票金额：${row.money}</h5>
        <h5 class="mg-l-30 mg-b-10">发票类型：${invoice_type}</h5>
        <h5 class="mg-l-30 mg-b-10">收件人：${name}</h5>
        <h5 class="mg-l-30 mg-b-10">手机号：${mobile}</h5>
        <h5 class="mg-l-30 mg-b-10">收货地址：${row.address}</h5>
        <h5 class="mg-l-30 mg-b-10">电子邮箱：${row.email}</h5>
      `;
      this.$confirm(html, "确认开票", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.axios({
          method: "get", //请求方法
          headers: {
            "Content-Type": "text/html;charset=UTF-8",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          url: ` https://api.boyalife.net/v1/cms/Invoice/confirm/${row.uuid}`,
        })
          .then((res) => {
            this.$message.success("操作成功");
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    refuse(row) {
      this.$prompt("请输入拒绝的理由", "拒绝开票", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        inputPlaceholder: "请输入拒绝的理由",
        inputType: "textarea",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "理由不能为空",
      })
        .then(({ value }) => {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "text/html;charset=UTF-8",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: {
              cause: value,
            },
            url: ` https://api.boyalife.net/v1/cms/Invoice/refuse/${row.uuid}`,
          })
            .then((res) => {
              this.$message.success("操作成功");
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
