import { render, staticRenderFns } from "./addNewActivity.vue?vue&type=template&id=80ec391c&scoped=true&"
import script from "./addNewActivity.vue?vue&type=script&lang=js&"
export * from "./addNewActivity.vue?vue&type=script&lang=js&"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./addNewActivity.vue?vue&type=style&index=1&id=80ec391c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80ec391c",
  null
  
)

export default component.exports