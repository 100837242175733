<template>
  <div class="dialog-container" v-show="visible">
    <div class="bg-layout"></div>
    <div class="dialog">
      <h2>选择项目</h2>
      <div class="search-container flex space-between mg-t-20">
        <el-form
          :inline="true"
          :model="customerGroupForm"
          class="demo-form-inline"
        >
          <el-form-item label="" class="keyword">
            <div class="flex">
              <el-input
                placeholder="请输入商品名称/ID"
                v-model="customerGroupForm.search_keyword"
                clearable
              >
              </el-input>
              <el-button
                class="mg-l-10"
                type="primary"
                size="medium"
                @click="getList"
                >查询</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
      <h3 class="mg-l-10 mg-b-10">
        已选 <span style="color: orange">{{ selectNum }} </span>个
      </h3>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        style="width: 100%"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        :row-key="getRowKeys"
      >
        <el-table-column type="selection" width="55"  :reserve-selection="true"> </el-table-column>
        <el-table-column
          label="项目描述"
          :align="'center'"
          prop="nickName"
          width="300"
        >
          <template slot-scope="scope">
            <div class="nickAndAvatar">
              <el-image
                v-if="scope.row.image.length > 0"
                :src="scope.row.image[0].image"
              ></el-image>
              <div style="width: 200px">
                <el-tooltip :content="scope.row.name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="项目编码" :align="'center'">
        </el-table-column>
        <el-table-column prop="price" label="价格" :align="'center'" sortable>
          <template slot-scope="scope"> ￥{{ scope.row.price }} </template>
        </el-table-column>
        <el-table-column
          prop="sales_number"
          label="库存"
          :align="'center'"
          sortable
        >
        </el-table-column>
       
        <el-table-column prop="status" label="状态" :align="'center'">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.status == 1"
              >推广中</span
            >
            <span v-if="scope.row.status == 3">已售完</span>
            <span v-if="scope.row.status == 2">已下架</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="添加推广时间"
          :align="'center'"
          width="160"
        >
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
      <div class="dialog-footer mg-t-20 text-right">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "./pagination.vue";
export default {
  name: "",
  components: {
    pagination,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    isSingle: {
      type: Boolean,
      default: false,
    },
    project: [],
  },
  watch: {
    dialogVisible(n) {
      console.log(n)
      this.visible = n;
    },
    project(n) {
        this.getList();
    },
    isSingle(n) {
      console.log(n);
    },
  },
  data() {
    return {
      visible: false,
      selectNum: 0,
      selectRows: [],
      customerGroupForm: {
        search_keyword: "",
      },
      tableData: {
        data: [],
        per_page: 9999,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Item?search_keyword=${this.customerGroupForm.search_keyword}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_status=1`,
      })
        .then((res) => {
          this.tableData = res.data.result;
          if (this.project && this.project.length > 0) {
            this.selectRows = this.project;
            let uuids = this.selectRows.map((i) => i.uuid);
            this.tableData.data.forEach((row) => {
              if (uuids.includes(row.uuid)) {
                this.$nextTick(() => {
                  this.$refs.multipleTable.toggleRowSelection(row, true);
                });
              }
            });
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    confirm() {
      this.$emit("confirmProject", this.selectRows);
    },
    handleClose() {
      this.visible = false;
      this.$emit("closeDialog", this.visible);
    },
    handleSelectionChange(rows) {
      this.selectNum = rows.length;
      if (this.isSingle == true) {
        if (rows.length > 1) {
          this.$refs.multipleTable.clearSelection();
          this.$refs.multipleTable.toggleRowSelection(rows[rows.length - 1]);
        }
        this.selectRows = rows[rows.length - 1];
        if (!this.selectRows) {
          this.selectRows = {};
        }
      } else {
        this.selectRows = rows;
      }
    },
    getRowKeys(row) {
      return row.uuid;
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.el-table .product >>> .el-image {
  border-radius: 0;
}
</style>
