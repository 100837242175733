<template>
  <div class="smsBlackList">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>短信黑名单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="关键字" class="search_keyword">
          <el-input
            placeholder="请输入用户ID/名称/手机/邮箱"
            v-model="customerGroupForm.search_keyword"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="会员等级">
          <el-select
            class="mg-l-20"
            v-model="customerGroupForm.search_level"
            placeholder="请选择"
          >
            <el-option
              v-for="(jtem, i) in levelList"
              :key="i"
              :label="jtem"
              :value="i"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getSmsTemplate"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium">导出</el-button>
      </div>
    </div>
    <div class="boxLayout mg-t-20">
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="dialogVisible = true"
          >添加客户</el-button
        >
        <el-upload
          class="upload-demo"
          action="#"
          accept=".xlsx"
          :show-file-list="false"
          :http-request="httpRequest"
          :before-upload="beforeUpload"
        >
          <el-button type="primary">导入</el-button>
        </el-upload>
        <a
          href="https://api.boyalife.net/excel/%E7%9F%AD%E4%BF%A1%E9%BB%91%E5%90%8D%E5%8D%95.xlsx"
          style="color: #409eff; font-size: 12px"
          class="mg-l-20"
          >下载导入模板</a
        >
      </div>
      <el-table
        class="mg-t-20"
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="selection" :align="'center'"> </el-table-column>
        <el-table-column label="会员ID" prop="user.uuid" :align="'center'">
        </el-table-column>
        <el-table-column label="用户名" prop="user.nick_name" :align="'center'">
        </el-table-column>
        <el-table-column label="手机" prop="user.mobile" :align="'center'">
        </el-table-column>
        <el-table-column label="禁用时间" prop="ban_time" :align="'center'">
        </el-table-column>
        <el-table-column
          prop="relieve_time"
          label="自动解禁时间"
          :align="'center'"
          sortable
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          width="150"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              @click="$router.push('/customerInfo')"
              type="text"
              size="small"
              >查看</el-button
            >
            <el-button
              v-if="scope.row.status == 1"
              @click="disabled(scope.row)"
              type="text"
              size="small"
              >解禁</el-button
            >
            <el-button
              v-else
              @click="disabled(scope.row)"
              type="text"
              size="small"
              >封禁</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <selectCustomer
      :dialogVisible="dialogVisible"
      @confirmCustomer="confirmCustomer"
      @cancel="closeSelectCustomer"
    />
    <el-dialog
      title="选择禁用时间"
      :visible.sync="timeDialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" class="mg-t-20" :rules="rules" inline>
        <el-form-item prop="search_end_time">
          <el-date-picker
            v-model="dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes2"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="timeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit('form')">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
import selectCustomer from "../../../components/selectCustomer.vue";
export default {
  name: "smsBlackList",
  components: {
    selectCustomer,
    pagination,
  },
  data() {
    return {
      timeDialogVisible: false,
      dialogVisible: false,
      dataTime: "",
      levelList: [],
      user_uuids: [],
      form: {
        search_start_time: "",
        search_end_time: "",
      },
      rules: {
        search_end_time: {
          required: true,
          message: "请选择封禁时间",
          trigger: "change",
        },
      },
      customerGroupForm: {
        search_keyword: "",
        search_level: "",
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
      },
    };
  },
  created() {
    this.getSmsTemplate();
    this.getLevelList();
  },
  methods: {
    beforeUpload(file) {
      console.log(file);
      this.load = true;
      this.$message.info("稍等片刻，正在上传...");
      if (file.name.split(".")[1].toLowerCase() != "xlsx") {
        this.$message.error("文件格式错误~");
        return false; // 终止上传
      }
      this.formData = new FormData();
      this.formData.append("file", file);
    },
    httpRequest(data) {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.formData,
        url: ` https://api.boyalife.net/v1/cms/CommonProblem/import`,
      })
        .then((res) => {
          this.$message.success("保存成功！");
          this.getCommonProblem();
          this.load = false;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getLevelList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/UserLevelSelect`,
      })
        .then((res) => {
          this.levelList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 选择时间
    getTimes2(v) {
      this.form.search_start_time = v[0] + " 00:00:00";
      this.form.search_end_time = v[1] + " 23:59:59";
    },
    closeSelectCustomer(v) {
      this.dialogVisible = v;
    },
    confirmCustomer(v) {
      if (v.length < 1) {
        this.$message.warning("请至少选择一个客户");
        return false;
      } else {
        this.dialogVisible = false;
        this.user_uuids = v.map((item) => item.uuid);
        this.timeDialogVisible = true;
      }
    },
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: {
              user_uuids: this.user_uuids,
              ban_time: this.form.search_start_time,
              relieve_time: this.form.search_end_time,
            },
            url: ` https://api.boyalife.net/v1/cms/SmsBlacklist`,
          })
            .then((res) => {
              this.$message.success("添加成功！");
              this.timeDialogVisible = false;
              this.getSmsTemplate();
              this.user_uuids = [];
              this.form.search_start_time = "";
              this.form.search_end_time = "";
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getSmsTemplate();
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    getData(methods, data, queryStry, mark, tableData, msg = "") {
      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data,
        url: ` https://api.boyalife.net/v1/cms/SmsBlacklist${mark}${queryStry}`,
      })
        .then((res) => {
          console.log(res);
          if (msg !== "") {
            this.$message.success(msg);
          }
          if (tableData !== null) {
            this.tableData = res.data.result;
          }
          if (methods === "delete") {
            this.getSmsTemplate();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 列表
    getSmsTemplate() {
      let data = {};
      let queryStry = `search_level=${this.customerGroupForm.search_level}&search_keyword=${this.customerGroupForm.search_keyword}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}`;
      this.getData("get", data, queryStry, "?", this.tableData);
    },
    disabled(row) {
      let status = row.status == 1 ? 2 : 1;
      this.$confirm("解禁确认", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.axios({
          method: "put", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            status,
          },
          url: ` https://api.boyalife.net/v1/cms/SmsBlacklist/status/${row.uuid}`,
        })
          .then((res) => {
            this.$message.success("操作成功!");
            this.getSmsTemplate();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    closeDialog(v) {
      this.dialogVisible = v;
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getSmsTemplate();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getSmsTemplate();
    },
  },
};
</script>
<style scoped>
>>> .el-table .el-button {
  width: 40px;
}
.upload-demo {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 20px;
}
</style>
