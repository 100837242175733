<template>
  <div class="addInformation">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>博雅简介</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="info boxLayout mg-t-20">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <div class="flex align-center mg-b-20">
        <el-button type="primary" class="mg-r-10" size="mini" style="margin-left: 100px;" @click="addProduce">添加简介</el-button>
        <span class="font-12 color-999">(最多添加5个)</span>
      </div>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="100px"
        :rules="rules"
        class="forms"
      >
      <div v-for="(item,index) in basicInfoForm.field" 
        :key="index">
        <el-form-item :label="'简介'+title[index]" :prop="basicInfoForm.field[index][title]" :rules="rules.title[index]">
          <el-input v-model="item.title"></el-input>
        </el-form-item>
        
        <el-form-item label="简介视频" prop="cover_image" @click.stop="getIndex(index)">
          <span slot="label">简介视频</span>
          
          <el-button class="avatar-uploader video" @click="chooseVideo(index)">
            <video v-if="item.video !== ''" width="178px" controls>
              <source :src="item.video" />
            </video>
            <i
              class="el-icon-delete"
              v-if="item.video !== ''"
              @click.stop="item.video = ''"
            ></i>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-button>
          <p class="font-12 color-999">建议尺寸：1080P，500M以内</p>
        </el-form-item>
        <div class="info mg-t-20" style="margin-bottom: 50px;">
          <h3 class="font-14 mg-b-30">简介详情</h3>
          <wangEditorTool :ref="'editors'+index" :content="item.desc" />
        </div>
        <el-divider v-if="basicInfoForm.field.length > 1"></el-divider>
      </div>
       
      </el-form>
     
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submitForm">保存</el-button>
      <!-- <el-button size="mini" @click="$router.go(-1)">返回</el-button> -->
    </div>
    <el-dialog
      title="选择视频"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary
        :type="imgType"
        @insertVideoHandler="insertVideoHandler"
      />
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import MaterialLibrary from "../../../components/materialLibraryDialog/index";
import wangEditorTool from "../../../components/wangEditorTool.vue";
export default {
  name: "addInformation",
  components: { MaterialLibrary, wangEditorTool },
  data() {
    return {
      title: ['一', '二', '三', '四', '五'],
      pageType: "新增",
      imgType: "",
      labelDialogVisible: false,
      currentIndex: '',
      tags: [],
      active: 0,
      dialogVisible: false,
      uuid: "",
      options: [],
      props: {
        label: "category_name",
        value: "uuid",
        children: "children",
      },
      value2: "",
      value3: "",
      currentVideo: '',
      basicInfoForm: {
        field: [
          {
            title: "",
            video: "",
            desc: "",
          }
        ]
      },
      formData: null,
      loading: false,
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
      },
    };
  },
  created() {
      this.getInfo();

  },
  methods: {
    httpRequest() {
      this.$api.file
        .uploadVideoFile(this.formData)
        .then((res) => {
          this.loading = false
          this.$set(this.basicInfoForm.field[this.currentIndex], 'video', '')

          this.$set(this.basicInfoForm.field[this.currentIndex], 'video', res)
          console.log(this.basicInfoForm)
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    beforeAvatarUpload(file) {
      this.loading = true
      let size = file.size / 1024 / 1024 < 50;
      if (!size) {
        this.$message.error("上传视频大小不能超过 50MB!");
        return false
      }
      this.formData = new FormData();
      this.formData.append("file", file);
    },
    dynamSuccess (object,res,file) {
      this.currentIndex = object.index
    },
    addProduce () {
      let obj = {
        title: "",
        video: "",
        desc: "",
      }
      if (this.basicInfoForm.field.length > 5 ) {
        this.$message.warning('最多只能添加5个简介')
        return false
      } else {
        this.basicInfoForm.field.push(obj)
      }
    },
    chooseVideo(index) {
      this.currentVideo = index
      this.imgType = "chooseVideo";
      this.dialogVisible = true;
    },
    insertVideoHandler(url) {
      this.dialogVisible = false;
      this.basicInfoForm.field[this.currentVideo].video = url;
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/About`,
      }).then((res) => {
        this.basicInfoForm.field = res.data.result;
      })
    },
    submitForm() {
      this.$refs['form'].validate((valid) => {
          if (valid) {
          this.basicInfoForm.field.map((item,index) => {
            item.desc = this.$refs["editors" + index][0].html;
            if (item.title == '') {
              this.$message.error(`标题${this.title[index]}不能为空`)
              return false
            }
          })
          console.log('走i？')
          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.basicInfoForm.field,
            url: ` https://api.boyalife.net/v1/cms/About`,
          }).then((res) => {
            this.$message.success("保存成功！");
          });
        }
      })
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.addInformation {
  margin-bottom: 80px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar-uploader {
  position: relative;
}
.avatar-uploader .el-icon-delete {
  font-size: 20px;
  position: absolute;
  right: -40px;
  bottom: 0px;
}
.cover_image_list {
  flex-wrap: wrap;
  margin-top: 20px;
}
.cover_image_list .img {
  position: relative;
  border: 1px solid #dcdcdc;
  width: 220px;
  height: 220px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.cover_image_list .img .el-icon-delete {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  z-index: 2;
  cursor: pointer;
}
.cover_image_list .img .el-image {
  width: 100%;
  height: 100%;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.forms >>> .el-form .el-input {
  width: 400px;
}
.insertImg {
  width: 52px;
  height: 40px;
  position: absolute;
  right: 159px;
  top: 0;
  z-index: 2;
  cursor: pointer;
}
.insertVideo {
  width: 52px;
  height: 40px;
  position: absolute;
  right: 107px;
  top: 0;
  z-index: 2;
  cursor: pointer;
}
.el-tag {
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>
