<template>
  <div class="morningPaperList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容</el-breadcrumb-item>
      <el-breadcrumb-item>早报</el-breadcrumb-item>
      <el-breadcrumb-item>早报内容管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="发布时间">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button
          type="primary"
          size="medium"
          @click="getMorningPaperList(customerGroupForm.status || 1)"
          >查询</el-button
        >
        <el-button size="medium" @click="clearForm">清空</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="tabs flex">
        <p
          v-for="(item, index) in tabs"
          :key="index"
          @click="addClass(index)"
          :class="{ on: currentClass == index }"
        >
          {{ item }}
        </p>
      </div>
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="$router.push('/addMorningPaper')"
          >添加早报</el-button
        >
      </div>

      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          label="早报名称"
          :align="'center'"
          prop="name"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          prop="content"
          label="内容数"
          :align="'center'"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" :align="'center'">
        </el-table-column>
        <el-table-column label="操作" :align="'center'" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="$router.push('/addMorningPaper?uuid=' + scope.row.uuid)"
              >编辑</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status == 1"
              @click="changeStatus(scope.row, 'down')"
              >下架</el-button
            >
            <el-button type="text" v-else @click="changeStatus(scope.row, 'up')"
              >上架</el-button
            >
            <el-button type="text" @click="deletePaper(scope.row.uuid)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "morningPaperList",
  components: {
    pagination,
  },
  data() {
    return {
      customerGroupForm: {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
        status: 1,
      },

      tabs: ["上架", "下架"],
      currentClass: 0,
      labelOptions: [
        {
          label: "启用",
          value: "ok",
        },
        {
          label: "禁用",
          value: "no",
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
      },
    };
  },
  created() {
    this.getMorningPaperList();
  },
  methods: {
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    clearForm() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.customerGroupForm.status = 1;
      this.getMorningPaperList();
    },
    addClass(index) {
      this.currentClass = index;
      this.customerGroupForm.status = index + 1;
      this.getMorningPaperList();
    },
    // 编辑跳转
    toEdit(row) {
      row.information = JSON.stringify(row.information);
      this.$router.push({
        name: "addMorningPaper",
        query: { ...row },
      });
    },
    getMorningPaperList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/MorningPaper?page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&status=${this.customerGroupForm.status}&search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    // 上下架
    changeStatus(row, str) {
      let status = str === "down" ? 2 : 1;
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          status,
        },
        url: ` https://api.boyalife.net/v1/cms/MorningPaper/status/${row.uuid}`,
      })
        .then((res) => {
          let msg = str === "down" ? "下架成功" : "上架成功";
          this.$message.success(msg);
          let status2 = str === "down" ? 1 : 2;

          this.getMorningPaperList(status2);
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    // 删除早报
    deletePaper(uuid) {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://api.boyalife.net/v1/cms/MorningPaper/${uuid}`,
          })
            .then((res) => {
              this.$message.success("删除成功！");
              this.getMorningPaperList(this.currentClass + 1);
            })
            .catch((err) => {
              this.$message.success(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getMorningPaperList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getMorningPaperList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.morningPaperList .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
.dataTable {
  position: relative;
}
.dataTable .tabs {
  position: absolute;
  top: 35px;
  left: 20px;
}
.dataTable .tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.dataTable .tabs p:after {
  content: "";
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.dataTable .tabs p.on:after {
  background: #409eff;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
</style>
