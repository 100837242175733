<template>
  <div class="addAdvertise">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/advertisementManageList'"
        >广告列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ pageType }}广告</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="info boxLayout mg-t-20">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="140px"
        :rules="rules"
      >
        <el-form-item label="广告标题" prop="name">
          <el-input v-model="basicInfoForm.name"></el-input>
        </el-form-item>
        <el-form-item label="是否启用" placeholder="请输入">
          <el-switch
            v-model="basicInfoForm.status"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="广告图" prop="image">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest"
          >
          <img
              v-if="basicInfoForm.image != ''"
              :src="basicInfoForm.image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseBanner">从素材库选择</el-button>
          <p class="font-12 color-999">建议尺寸：640*384像素，大小2M以内</p>
        </el-form-item>
        <el-form-item label="权重" prop="sort">
          <el-input v-model="basicInfoForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="跳转类型" prop="url_type">
          <!-- <el-radio v-model="basicInfoForm.url_type" :label="1">链接</el-radio> -->
          <el-radio v-model="basicInfoForm.url_type" :label="2"
            >富文本</el-radio
          >
          <el-radio v-model="basicInfoForm.url_type" :label="3"
            >小程序</el-radio
          >
          <el-radio v-model="basicInfoForm.url_type" :label="5">商品</el-radio>
          <el-radio v-model="basicInfoForm.url_type" :label="6">项目</el-radio>
        </el-form-item>
        <!-- <el-form-item label="链接地址" prop="url"  v-if="basicInfoForm.url_type == 1">
          <el-input  v-model="basicInfoForm.url"></el-input>
        </el-form-item> -->
        <el-form-item label="富文本内容" v-if="basicInfoForm.url_type == 2">
          <wangEditorTool ref="editors" :content="basicInfoForm.text" />
        </el-form-item>
        <el-form-item
          label="页面地址"
          v-if="basicInfoForm.url_type == '3'"
          :prop="basicInfoForm.url_type == '1' ? 'mini_page' : ''"
        >
          <el-select v-model="basicInfoForm.mini_page" placeholder="请选择">
            <el-option
              v-for="jtem in urlList"
              :key="jtem.value"
              :label="jtem.label"
              :value="jtem.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="页面地址" v-if="basicInfoForm.url_type == '3'">
          <el-input  v-model="basicInfoForm.mini_page"></el-input>
        </el-form-item> -->
        <el-form-item label="选择商品" v-if="basicInfoForm.url_type == '5'">
          <el-button
            v-if="commidityList.length == 0"
            @click="selectCommdityDialogVisible = true"
            class="mg-l-20"
            type="text"
          >
            + 选择商品</el-button
          >
          <el-button
            v-else
            @click="selectCommdityDialogVisible = true"
            class="mg-l-20"
            type="text"
          >
            + 修改商品</el-button
          >

          <el-table
            :data="commidityList"
            border
            v-if="commidityList.length > 0"
          >
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="name" label="商品名"></el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="选择项目" v-if="basicInfoForm.url_type == '6'">
          <el-button
            v-if="projectList.length == 0"
            @click="showProjectDialog"
            class="mg-l-20"
            type="text"
          >
            + 选择项目</el-button
          >
          <el-button
            v-else
            @click="showProjectDialog"
            class="mg-l-20"
            type="text"
            >修改项目</el-button
          >

          <el-table :data="projectList" border v-if="projectList.length > 0">
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="name" label="项目名"></el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item
          label="上架时间"
          :prop="basicInfoForm.shelf_type == 2 ? 'shelf_time' : ''"
        >
          <el-radio v-model="basicInfoForm.shelf_type" :label="1"
            >立即上架</el-radio
          >
          <el-radio v-model="basicInfoForm.shelf_type" :label="2"
            >定时上架</el-radio
          >
          <el-date-picker
            v-if="basicInfoForm.shelf_type == 2"
            v-model="basicInfoForm.shelf_time"
            type="datetimerange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="投放人群" prop="group_uuid">
          <el-select
            multiple
            collapse-tags
            v-model="basicInfoForm.group_uuid"
            placeholder="请选择"
          >
            <el-option
              v-for="(jtem, i) in userGroupList"
              :key="i"
              :label="jtem"
              :value="i"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button type="primary" size="mini" @click="sumbitForm('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      title="选择项目"
      :visible.sync="dialogProjectVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <!-- <div class="mg-t-10 mg-b-10">
        <el-input v-model="search_keyword" @input="getItemList"></el-input>
      </div> -->
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          label="商品描述"
          :align="'center'"
          prop="nickName"
          width="300"
        >
          <template slot-scope="scope">
            <div class="nickAndAvatar">
              <el-image
                v-if="scope.row.image.length > 0"
                :src="scope.row.image[0].image"
              ></el-image>
              <div style="width: 200px">
                <el-tooltip :content="scope.row.name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="项目编码" :align="'center'">
        </el-table-column>
        <el-table-column prop="price" label="价格" :align="'center'" sortable>
          <template slot-scope="scope"> ￥{{ scope.row.price }} </template>
        </el-table-column>
        <el-table-column
          prop="sales_number"
          label="销量"
          :align="'center'"
          sortable
        >
        </el-table-column>
        <el-table-column prop="category.name" label="分类" :align="'center'">
        </el-table-column>
        <el-table-column prop="status" label="状态" :align="'center'">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.status == 1"
              >出售中</span
            >
            <span v-if="scope.row.status == 3">已售完</span>
            <span v-if="scope.row.status == 2">已下架</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          :align="'center'"
          width="160"
        >
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogProjectVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmProject">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog"/>
    </el-dialog>
    <selectCommdity
      :dialogVisible="selectCommdityDialogVisible"
      @confirmCommidity="confirmCommidity"
      :isSingle="true"
    />
  </div>
</template>
<script>
import wangEditorTool from "../../../components/wangEditorTool.vue";
import pagination from "../../../components/pagination.vue";
import imageDialog from "../../../components/imageDialog.vue";
import selectCommdity from "../../../components/selectCommdity";
export default {
  name: "addAdvertise",
  components: {
    imageDialog,
    wangEditorTool,
    selectCommdity,
    pagination,
  },
  data() {
    return {
      urlList: [
        {
          label: "会员中心",
          value: "/myPage/vip/benefit/benefit",
        },
        {
          label: "签到",
          value: "/myPage/vip/sign/sign",
        },
        {
          label: "项目咨询（项目列表）",
          value: "/pages/mall/mall?current=0",
        },
        {
          label: "积分商城",
          value: "/pages/mall/mall?current=1",
        },
        {
          label: "我的卡券",
          value: "/myPage/wallet/coupons/coupons",
        },
        {
          label: "任务中心",
          value: "/vipPage/meberTask/meberTask",
        },
        {
          label: "关于博雅",
          value: "/vipPage/about/about",
        },
        {
          label: "首页",
          value: "/pages/index/index",
        },
        {
          label: "资讯",
          value: "/pages/information/information",
        },
        {
          label: "我的",
          value: "/pages/my/my",
        },
      ],
      pageType: "",
      isShow: "",
      search_keyword: "",
      imgType: "",
      dialogVisible: false,
      selectCommdityDialogVisible: false,
      dialogProjectVisible: false,
      userGroupList: [],
      selectRows: [],
      basicInfoForm: {
        name: "",
        radio: "1",
        image: "",
        status: 1,
        url_type: 1,
        shelf_type: 1,
        shelf_time: "",
        text: "",
        mini_page: "",
        group_uuid: [],
        goods_uuid: "",
        item_uuid: "",
      },
      commidityList: [],
      projectList: [],
      options: [
        {
          label: "一级分类A",
          options: [
            {
              value: "Shanghai",
              label: "二级分类A",
            },
            {
              value: "Beijing",
              label: "二级分类B",
            },
          ],
        },
        {
          label: "一级分类B",
          options: [
            {
              value: "Chengdu",
              label: "二级分类C",
            },
          ],
        },
      ],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      rules: {
        name: [{ required: true, message: "请填写广告标题", trigger: "blur" }],
        image: [{ required: true, message: "请选择广告图", trigger: "blur" }],
        shelf_time: [
          { required: true, message: "请选择上架时间", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getUserGroup();
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      this.getInfo();
    } else {
      this.pageType = "新增";
    }
  },
  methods: {
    closeImageDialog () {
      this.dialogVisible = false
    },
     // 上传图片
     httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.image =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Advertising/${this.$route.query.uuid}`,
      })
        .then((res) => {
          let params = res.data.result;
          for (let i in params) {
            this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
          }
          this.basicInfoForm.shelf_type = Number(this.basicInfoForm.shelf_type);
          this.basicInfoForm.url_type = Number(this.basicInfoForm.url_type);
          this.basicInfoForm.status = Number(this.basicInfoForm.status);
          if (this.basicInfoForm.item != null) {
            this.projectList = [];
            this.projectList.push(this.basicInfoForm.item);
          }
          if (this.basicInfoForm.goods != null) {
            this.commidityList = [];
            this.commidityList.push(this.basicInfoForm.goods);
          }
          if (this.basicInfoForm.group.length > 0) {
            this.basicInfoForm.group_uuid = this.basicInfoForm.group.map(
              (item) => {
                return item.uuid;
              }
            );
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    showProjectDialog() {
      this.dialogProjectVisible = true;
      this.getItemList();
    },
    confirmCommidity(v) {
      this.commidityList = [];
      this.selectCommdityDialogVisible = false;
      this.commidityList.push(v);
      this.basicInfoForm.goods_uuid = v.uuid;
    },
    confirmProject() {
      this.dialogProjectVisible = false;
      this.projectList = [];
      this.projectList.push(this.selectRows);
      this.basicInfoForm.item_uuid = this.selectRows.uuid;
    },
    getUserGroup() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/UserGroupSelect`,
      })
        .then((res) => {
          this.userGroupList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 选择banner
    chooseBanner() {
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
    },
    // 富文本插入图片
    insertPic() {
      this.imgType = "insert_image";
      this.dialogVisible = true;
    },
    // 选择图片
    confirmImageDialog(item) {
      this.basicInfoForm.image = item[0].url;
      this.dialogVisible = false;
    },
    // 提交表单
    sumbitForm(form) {
      delete this.basicInfoForm.group;
      delete this.basicInfoForm.create_time;
      delete this.basicInfoForm.delete_time;
      delete this.basicInfoForm.update_time;
      delete this.basicInfoForm.uuid;
      delete this.basicInfoForm.shelf_type_desc;

      this.basicInfoForm.shelf_start_time = this.basicInfoForm.shelf_time[0];

      this.basicInfoForm.shelf_end_time = this.basicInfoForm.shelf_time[1];
      if (this.basicInfoForm.url_type == 2) {
        this.basicInfoForm.text = this.$refs.editors.html;
      }
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (Object.keys(this.$route.query).length !== 0) {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://api.boyalife.net/v1/cms/Advertising/${this.$route.query.uuid}`,
            }).then((res) => {
              this.$message.success("编辑成功！");
              this.$router.go(-1);
            });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://api.boyalife.net/v1/cms/Advertising`,
            }).then((res) => {
              this.$message.success("新增成功！");
              this.$router.go(-1);
            });
          }
        } else {
          return false;
        }
      });
    },
    handleSelectionChange(rows) {
      if (rows.length > 1) {
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(rows[rows.length - 1]);
      }
      this.selectRows = rows[rows.length - 1];
      if (!this.selectRows) {
        this.selectRows = {};
      }
    },
    // 获取项目列表
    getItemList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/Item?page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}&search_keyword=${this.search_keyword}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getItemList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getItemList();
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.addAdvertise {
  margin-bottom: 80px;
}
.addAdvertise .el-form {
  width: 800px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 16px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar-uploader {
  position: relative;
}
.avatar-uploader .el-icon-delete {
  font-size: 20px;
  position: absolute;
  right: -40px;
  bottom: 0px;
}
.insertImg {
  width: 52px;
  height: 40px;
  position: absolute;
  right: 18px;
  top: 0;
  z-index: 2;
  cursor: pointer;
}
.insertVideo {
  width: 52px;
  height: 42px;
  position: absolute;
  left: 18px;
  top: 39px;
  z-index: 2;
  cursor: pointer;
}

>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
.upload-demo{
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
</style>
