<template>
  <div class="userFeedbackList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>荣誉与认证</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      > 
        <el-form-item label="创建日期">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getHonorList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="tabs flex space-between">
        <div class="flex">
          <p
            v-for="(item, index) in tabs"
            :key="index"
            @click="addClass(index)"
            :class="{ on: currentClass === index }"
          >
            {{ item }}
          </p>
        </div>
        <el-button class="mg-b-20" type="primary" @click="$router.push('/addHonors')">新增荣誉证书</el-button>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        @sort-change="sortChange"
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          label="资料标题"
          :align="'center'"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="图片" :align="'center'">
          <template slot-scope="scope">
            <div class="product" >
              <el-image
                :src="scope.row.image_src"
                @click.self="showBigImage(item.image_src)"
              ></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="user_name"
          label="操作人"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="更新日期"
          :align="'center'"
          sortable
          width="180 "
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          width="100"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
            @click="$router.push('/addHonors?uuid='+scope.row.id)"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button
              v-if="scope.row.status == 1"
              @click="updateStatus(scope.row)"
              type="text"
              size="small"
              >下线</el-button
            >
            <el-button
              v-if="scope.row.status == 0"
              @click="updateStatus(scope.row)"
              type="text"
              size="small"
              >上线</el-button
            >
            <el-button
              @click="pass(scope.row)"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <BigImg
      :visible="photoVisible"
      :url="bigImgUrl"
      @closeClick="
        () => {
          photoVisible = false;
        }
      "
    ></BigImg>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
import BigImg from "@/components/previwerImg.vue";
export default {
  name: "userFeedbackList",
  components: {
    pagination,
    BigImg,
  },
  data() {
    return {
      tabs: ["上线", "下线"],
      status: 1,
      currentClass: 0,
      photoVisible: false,
      bigImgUrl: "",
      // 免责声明
      disclaimers: "",

      customerGroupForm: {
        search_keyword: "",
        search_start_time: '',
        search_end_time: ''
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      options: [],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
      },
    };
  },
  created() {
    this.getHonorList(this.status);
    this.getCategory();
  },
  methods: {
    sortChange(column) {
      if (column.order == "descending") {
        this.customerGroupForm.search_order = "create_time DESC";
      } else {
        this.customerGroupForm.search_order = "create_time ASC";
      }
      this.getHonorList();
    },
    showBigImage(url) {
      //点击图片函数，点击后，把photoVisible设置成true
      if (url != "") {
        this.photoVisible = true;
        this.bigImgUrl = url;
      }
    },
    addClass(index) {
      this.currentClass = index;
      if (index === 0) {
        this.status = 1;
      } else {
        this.status = 0;
      }
      this.getHonorList(this.status);
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getHonorList();
    },
    // 列表
    getHonorList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/AboutStuff?search_status=${this.status}&search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getCategory() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://api.boyalife.net/v1/cms/FeedbackCategorySelect`,
      })
        .then((res) => {
          this.options = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    // 上下架
    updateStatus(row, str) {
      this.status = row.status == "0" ? '1' : '0';
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          status: this.status,
        },
        url: ` https://api.boyalife.net/v1/cms/AboutStuff/status/${row.id}`,
      })
        .then((res) => {
          this.$message.success('操作成功！');
          this.getHonorList(this.currentClass);
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    // 编辑跳转
    toEdit(row) {
      console.log(row)
      row = JSON.stringify(row);
      this.$router.push({
        name: "addHonors",
        query: { row },
      });
    },
    pass(row) {
      this.$confirm("确定已经处理完毕吗?", "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: {
              status: 1,
            },
            url: ` https://api.boyalife.net/v1/cms/AboutStuff/${row.id}`,
          })
            .then((res) => {
              this.$message.success("操作成功！");
              this.getHonorList(this.currentClass);
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消标记",
          });
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getHonorList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getHonorList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.el-table .el-image {
  width: auto;
  height: auto;
}
.userFeedbackList .el-table .product .el-image {
  border-radius: 0 !important;
  width: 80px;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
>>> .el-table .el-button {
  width: 40px;
}
.dataTable {
  position: relative;
}
.dataTable .tabs {
  position: static;
}
.dataTable .tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.dataTable .tabs p:after {
  content: "";
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.dataTable .tabs p.on:after {
  background: #409eff;
}
.el-table .el-button {
  width: auto;
}
</style>
